import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler'
import { Box } from '@mui/material'

const FormatedDateBox = (props: {
  startDate: SchedulerDateTime
  endDate?: SchedulerDateTime | undefined
}) => {
  const { startDate, endDate } = props
  const since: Date = new Date(startDate)
  const to: Date = endDate ? new Date(endDate) : since
  return (
    <Box className="flex  min-w-[150px]  flex-row items-center justify-start mt-3">
      <AccessTimeIcon />

      <p className="font-montserrat ml-3 mr-1 text-[14px] font-normal tracking-normal text-left capitalize">
        {format(since, 'EEEE, ', { locale: es })}
      </p>
      <p className="font-montserrat text-[14px] font-normal tracking-normal text-left">
        {format(since, "d 'de' MMMM", { locale: es })}
      </p>
      <p
        className={`font-montserrat ${
          to ? 'ml-3' : 'ml-1'
        } text-[14px] font-normal tracking-normal text-left`}
      >
        {to ? 'Desde: ' : ' - '}
        {format(since, ' HH:mm', { locale: es })}
      </p>
      {endDate && (
        <p className="font-montserrat ml-3 text-[14px] font-normal tracking-normal text-left">
          {format(to, "'Hasta:' HH:mm", { locale: es })}
        </p>
      )}
    </Box>
  )
}

export default FormatedDateBox
