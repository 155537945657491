import React from 'react'
import { Tooltip } from '@mui/material'

const TooltipIcon = ({ icon, label }: any) => {
  return (
    <Tooltip title={label} placement="right">
      {icon}
    </Tooltip>
  )
}

export default TooltipIcon