import { AxiosResponse } from 'axios';
import { EditAvailabilityDto, NewAvailabilityDto } from '../utils/constants';
import { http } from '../utils/http';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const addNewAvailability = async (
  data: NewAvailabilityDto,
  doctorId?: number,
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post(
      '/api/doctor/availability-slot',
      {
        ...data,
      },
      {
        headers: doctorId
          ? {
              'x-doctor-id': doctorId,
            }
          : {},
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const editAvailability = async (
  data: EditAvailabilityDto,
  doctorId?: number,
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.patch(
      '/api/doctor/availability-slot',
      {
        timezone: timeZone,
        ...data,
      },
      {
        headers: doctorId
          ? {
              'x-doctor-id': doctorId,
            }
          : {},
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAvailability = async (
  availabilitySlotsIds: Array<number>,
  doctorId?: number,
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.delete('/api/doctor/availability-slot', {
      data: { availabilitySlotsIds },
      headers: doctorId
        ? {
            'x-doctor-id': doctorId,
          }
        : {},
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const getWeeklyData = async (
  startDate: Date,
  endDate: Date,
  doctorId?: number,
) => {
  try {
    const response = await http.get(
      `/api/doctor/availability-slot/web/weekly?startDate=${startDate}&endDate=${endDate}&timezone=${timeZone}`,
      {
        headers: doctorId
          ? {
              'x-doctor-id': doctorId,
            }
          : {},
      },
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMonthlyData = async (
  startDate: Date,
  endDate: Date,
  doctorId?: number,
) => {
  try {
    const response = await http.get(
      `/api/doctor/availability-slot/web/daily?startDate=${startDate}&endDate=${endDate}&timezone=${timeZone}`,
      {
        headers: doctorId
          ? {
              'x-doctor-id': doctorId,
            }
          : {},
      },
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
