import { Typography } from '@mui/material';
import { Layout } from '../../components/Layout';
import CustomSearchBox from '../../components/CustomSearchBox';
import { useEffect, useState } from 'react';
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import UserAvatar from '../../components/UserAvatar';
import EmptyAvatar from '../../assets/avatar_circular.png';
import NothingToShow from '../../components/nothingToShow';
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png';
import {
  Doctor,
  RapportProps,
  doctorStatuses as userStatus,
} from '../../utils/constants';
import CustomDataGrid from '../../components/CustomDataGrid';
import {
  getSecretaries,
  linkSecretary,
  unlinkSecretary,
} from '../../services/secretary';
import TabsHeader, { tab } from './components/TabsHeader';
import { useAlertStore } from '../../store/alertStore';

const currentTabs: tab[] = [
  {
    id: 'unlinked',
    label: 'No Vinculados',
  },
  { id: 'linked', label: 'Vinculados' },
];

interface Secretary {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  type: 'SECRETARY' | 'DOCTOR';
  emailVerified: boolean;
  phone: string;
  profilePictureUrl: string;
  status: 'APPROVAL_PENDING' | 'APPROVED' | 'REJECTED';
  isActive: boolean;
  doctors?: Doctor[];
  linked: boolean;
}

const LinkSecretaries = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [secretaries, setSecretaries] = useState<Secretary[]>([]);
  const [selectedTab, setSelectedTab] = useState<tab>(currentTabs[0]);

  const { setAlert } = useAlertStore();

  const handlerLinkAction = async (
    secretaryId: number,
    linked: boolean,
    displayName: string,
  ) => {
    try {
      if (!linked) {
        await linkSecretary(secretaryId);
        setAlert({
          isOpen: true,
          message: `Te has vinculado con el asistente ${displayName} con éxito!`,
          severity: 'success',
        });
      } else {
        await unlinkSecretary(secretaryId);
        setAlert({
          isOpen: true,
          message: `Te has desvinculado del asistente ${displayName} con éxito!`,
          severity: 'error',
        });
      }
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      const response = await getSecretaries(
        userStatus.ENABLED,
        selectedTab.id === currentTabs[0].id ? false : true,
      );
      setSecretaries(response.items);
    };
    apiCall();
  }, [selectedTab.id, setSecretaries]);

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Asistente',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className='pl-4' fontWeight='bold'>
            Asistente
          </Typography>
        );
      },
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center pl-4'>
            <UserAvatar
              imageUrl={
                cellValues.row.profilePictureUrl !== undefined
                  ? cellValues.row.profilePictureUrl
                  : EmptyAvatar
              }
              size={44}
              altText={'IMG'}
            />
            <Typography className='pl-8'>
              {cellValues.row.firstName} {cellValues.row.lastName}
            </Typography>
          </div>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className='pl-4' fontWeight='bold'>
            Email
          </Typography>
        );
      },
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center'>
            <Typography className='pl-4'>{cellValues.row.email}</Typography>
          </div>
        );
      },
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'linked',
      headerName: '',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div
            className='flex w-full text-end justify-end pr-6'
            onClick={() => {
              console.log('row', cellValues.row);
              handlerLinkAction(
                cellValues.row.id,
                cellValues.row.linked,
                `${cellValues.row.firstName} ${cellValues.row.lastName}`,
              );
            }}
          >
            <Typography
              className='tracking-wider text-center text-sm text-aqua-intense underline cursor-pointer'
              fontWeight='bold'
            >
              {cellValues.row.linked ? 'Desvincular' : 'Vincular'}
            </Typography>
          </div>
        );
      },
      flex: 0.25,
      sortable: false,
    },
  ];

  const filterRow = (row: RapportProps) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase());

  return (
    <Layout>
      <div className='flex flex-col w-full h-full pb-8'>
        <div className='flex w-full items-center'>
          <div className='flex w-2/5 justify-start pl-2 pr-2'>
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight='bold'
            >
              Listado de Asistentes
            </Typography>
          </div>
          <div className='flex w-3/5 justify-end pl-2 pr-2'>
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div className='bg-white mx-14'>
          <div className='flex w-full py-6 pl-6'>
            <TabsHeader
              tabSelected={selectedTab.id}
              setTabSelected={setSelectedTab}
              tabs={currentTabs}
            />
          </div>
          <div
            className={`flex mr-4 ${
              secretaries.length !== 0 ? 'h-[495px]' : 'pb-12'
            }`}
          >
            {(searchText.length &&
              secretaries.filter(filterRow).length === 0) ||
            secretaries.length === 0 ? (
              <NothingToShow
                nothingImg={nothingToShowImg}
                altText={'sin data de asistentes'}
                nothingText={'No tenés nada que ver hasta el momento...'}
              />
            ) : (
              <CustomDataGrid
                rows={
                  searchText.length
                    ? secretaries.filter(filterRow)
                    : secretaries
                }
                rowHeight={64}
                columns={columns}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LinkSecretaries;
