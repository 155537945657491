import { useState } from 'react'
import {
  GridColDef,
  GridRowHeightParams
} from '@mui/x-data-grid'
import { format } from 'date-fns'
import { Button } from '@mui/material'
import { MoreOptions } from '../../components/MoreOptions'
import NothingToShow from '../../components/nothingToShow'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import NoteModal from './components/NotesModal'
import DeleteNoteModal from './components/DeleteNoteModal'
import { RapportDetails } from '../../utils/constants'
import CustomDataGrid from '../../components/CustomDataGrid'

const NoteDetails = (props: {
  row: any
  setExpandedRows: (data: number[]) => void
  expandedRows: number[]
}) => {
  const { row, setExpandedRows, expandedRows } = props
  const updated = new Date(row.updatedAt)
  const expandedClass: string = 'line-clamp-2'
  return (
    <div className="flex flex-col justify-between items-start mt-3  w-full h-full">
      <div>
        <p
          className={`${
            !expandedRows.includes(row.id) && expandedClass
          } font-montserrat text-base font-normal leading-27 tracking-normal text-left pr-6`}
        >
          {row.note}
        </p>

        <div className="mt-2 font-montserrat text-sm italic font-normal leading-27 tracking-normal text-left">
          Actualizado el {format(updated, 'dd/MM/yy')}
        </div>
      </div>
      <div className="mb-2">
        {expandedRows.includes(row.id) ? (
          <Button
            onClick={() => {
              const aux = expandedRows.filter((id) => id !== row.id)
              setExpandedRows([...aux])
            }}
          >
            Ver menos
          </Button>
        ) : (
          <Button onClick={() => setExpandedRows([...expandedRows, row.id])}>
            Ver más
          </Button>
        )}
      </div>
    </div>
  )
}

const RapportsMedicalNotes = ({
  rapportDetails,
  openModal,
  setOpenModal
}: {
  rapportDetails: RapportDetails
  openModal: boolean
  setOpenModal: (open: boolean) => void
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [previousNote, setPreviousNote] = useState<{
    note: string
    date: Date | null
    id?: number
  }>({ note: '', date: null })
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [toDeleteNote, setToDeleteNote] = useState<number>(0)

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      editable: false,
      flex: 1 / 4,
      renderCell: (cellValues) => {
        const { createdAt } = cellValues.row
        const date = new Date(createdAt)
        console.log(date)
        return (
          <div className="flex justify-start h-full w-full mt-6 ml-2 font-montserrat text-base font-normal leading-27 tracking-normal text-left">
            {format(date, 'dd/MM/yy')}
          </div>
        )
      }
    },
    {
      field: 'notes',
      headerName: 'Nota',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <NoteDetails
            row={cellValues.row}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
          />
        )
      }
    },
    {
      field: 'hypertext',
      headerName: '',
      editable: false,
      sortable: false,
      renderCell: (cellValues): any => {
        const { row } = cellValues
        const date = new Date(row.createdAt)
        return (
          <div className="flex justify-start h-full w-full mt-3 ml-2">
            <MoreOptions
              optionsData={[
                {
                  label: 'Editar nota',
                  action: () => {
                    setOpenModal(true)
                    setPreviousNote({ note: row.note, date, id: row.id })
                  }
                },
                {
                  label: 'Eliminar nota',
                  action: () => {
                    setOpenDeleteModal(true)
                    setToDeleteNote(row.id)
                  }
                }
              ]}
            />
          </div>
        )
      },
      flex: 1 / 16
    }
  ]

  const getRowHeight = (params: GridRowHeightParams) => {
    const { id } = params

    const rowHeight = 130
    if (expandedRows.includes(typeof id === 'string' ? parseInt(id) : id)) {
      const additionalRowHeight = params.model.note.length / 6 ?? 0
      return rowHeight + additionalRowHeight
    }
    return rowHeight
  }

  return (
    <div className="mt-3 h-[495px] w-full">
      {rapportDetails.notes.length > 0 ? (
        <CustomDataGrid
          columns={columns}
          rows={rapportDetails.notes}
          rowHeight={64}
          getRowHeight={getRowHeight}
        />
      ) : (
        <NothingToShow
          nothingImg={nothingToShowImg}
          altText={'sin historial clínico de paciente'}
          nothingText={'No tenés nada que ver hasta el momento...'}
        />
      )}
      <NoteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        previousNote={previousNote}
        setPreviousNote={setPreviousNote}
        rapportId={rapportDetails.id}
      />
      <DeleteNoteModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        toDeleteNote={toDeleteNote}
        setToDeleteNote={setToDeleteNote}
        rapportId={rapportDetails.id}
      />
    </div>
  )
}

export default RapportsMedicalNotes
