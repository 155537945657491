import { http } from '../utils/http'

export const getNotifications = async () => {
  try {
    const response = await http.get('/api/notifications')
    return response.data
  } catch (error) {
    return error
  }
}

export const markNotificationsAsRead = async (notificationsIds: number[]) => {
  try {
    const response = await http.patch('/api/notifications', {
      notificationsIds,
      read: true,
    })
    return response.data
  } catch (error) {
    return error
  }
}