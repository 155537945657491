import UserAvatar from './UserAvatar'
import { useRef } from 'react'
import { addProfilePicture } from '../services/user'
import { useAlertStore } from '../store/alertStore'
import { Badge, Box, IconButton } from '@mui/material'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import { useStore } from 'zustand'
import { authStore } from '../store/auth'
import { AvatarProps } from '../utils/interfaces/props'

const validFileTypes = ['image/jpeg', 'image/jpg', 'image/png']
const imageSizeLimit = process.env.REACT_APP_PROFILE_PICTURE_MAX_SIZE_MB
  ? Number(process.env.REACT_APP_PROFILE_PICTURE_MAX_SIZE_MB)
  : 5

const EditableAvatar = ({
  imageUrl,
  size,
  altText
}: AvatarProps) => {
  const fileInput: any = useRef()
  const { setAlert } = useAlertStore()
  const { setProfilePicture } = useStore(authStore)

  const handleInputClick = () =>
    fileInput.current ? fileInput.current.click() : null
  const pictureIsValid = (file: any) => {
    const imgObject = file?.['0']
    const size = imgObject?.size
    const type = imgObject?.type || ''
    const sizeOnMb = size / 1024 / 1024
    const isValidType = validFileTypes.includes(type)
    const isValidSize = sizeOnMb <= imageSizeLimit
    if (!isValidType) {
      setAlert({
        isOpen: true,
        message:
          'Formato de imagen inválido. Formatos aceptados PNG, JPG y JPEG ',
        severity: 'error'
      })
    } else if (!isValidSize) {
      setAlert({
        isOpen: true,
        message: `La imagen es demasiado grande, debe pesar menos de ${imageSizeLimit}mb`,
        severity: 'error'
      })
    }
    return isValidType && isValidSize
  }

  const uploadProfilePicture = async (file: File) => {
    const response = await addProfilePicture(file)
    fileInput.current.value = ''

    if (response.status === 201) {
      setProfilePicture(response.data.url)
      setAlert({
        isOpen: true,
        message: 'Imagen actualizada con éxito',
        severity: 'success'
      })
    }

    if (response.status === 400) {
      setAlert({
        isOpen: true,
        message:
          'Formato de imagen inválido. Formatos aceptados PNG, JPG y JPEG ',
        severity: 'error'
      })
    }

    if (response.status === 413) {
      setAlert({
        isOpen: true,
        message: `La imagen es demasiado grande, debe pesar menos de ${size}mb`,
        severity: 'error'
      })
    }
  }

  const fileOnChangeHandler = (value: any) => {
    const validPicture = pictureIsValid(value)
    if (validPicture) {
      uploadProfilePicture(value.length ? value[0] : null)
    }
  }

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      badgeContent={
        <Box className="flex justify-center items-center bg-yellow-custom border-2 border-solid border-white rounded-full absolute -left-3 -top-3 h-12 w-12">
          <IconButton aria-label="edit" onClick={() => handleInputClick()}>
            <BorderColorOutlinedIcon />
          </IconButton>
        </Box>
      }
    >
      <UserAvatar
        imageUrl={imageUrl}
        size={size}
        altText={altText}
      />
      <input
        ref={fileInput}
        type="file"
        style={{ display: 'none' }}
        accept="image/jpeg, image/jpg, image/png"
        onChange={(e) =>
          fileOnChangeHandler((e.target as HTMLInputElement).files)
        }
      />
    </Badge>
  )
}

export default EditableAvatar
