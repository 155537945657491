import { Theme } from '@mui/material/styles'
//
import Button from './Button'
import Input from './Input'
import Accordion from './Accordion'
import DatePicker from './DatePicker'
import Paper from './Paper'
import Dialog from './Dialog'
import Drawer from './Drawer'
import AppBar from './AppBar'
import DataGrid from './DataGrid'

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Button(theme),
    Input(theme),
    Accordion(),
    DatePicker(theme),
    Paper(),
    Dialog(theme),
    Drawer(),
    AppBar(theme),
    DataGrid()
  )
}
