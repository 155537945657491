import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ArrowDataProps } from '../utils/interfaces/props'

const ArrowGoPreviousPage = (props: ArrowDataProps) => {
  const { title, path, state } = props
  const navigate = useNavigate()
  return (
    <div className='w-full'>
      <div
        onClick={() => navigate(path, { replace: true, state: state })}
        className='flex flex-row items-center w-fit cursor-pointer'
      >
        <div className='mr-2'>
          <KeyboardArrowLeftIcon />
        </div>
        <Typography
          fontSize='small'
          fontWeight='bold'
          className='text-light-grey'
        >
          {title}
        </Typography>
      </div>
    </div>
  )
}

export default ArrowGoPreviousPage
