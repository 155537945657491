import React from 'react'
import { format } from 'date-fns'
import { Typography } from '@mui/material'
import { NotificationProps } from '../../utils/interfaces/props'

const NotificationItem = (props: NotificationProps) => {
  const { read, title, description, createdAt } = props
  const now = new Date()
  const hoursSinceNotificationWasCreated = Math.floor(
    (now.getTime() - createdAt) / 3600000
  )
  const dateOfNotification = new Date(createdAt)
  const dateString =
    hoursSinceNotificationWasCreated <= 24
      ? hoursSinceNotificationWasCreated.toString() + ' hs'
      : format(dateOfNotification, 'dd/MM')
  return (
    <div
      className={`flex flex-row w-full border-b border-solid border-secondary-light-grey ${read ? 'bg-white' : 'bg-aqua-transparent'}`}
    >
      <div className='flex w-full'>
        <div className={`flex w-1 ${read ? 'bg-white' : 'bg-aqua-intense'}`}></div>
        <div className='flex flex-col w-full p-10'>
          <div>
            <Typography
              className='tracking-wider text-pitch-black'
              fontWeight='bold'
              fontSize={14}
            >
              {title}
            </Typography>
          </div>
          <div className='mt-2'>
            <Typography
              className='tracking-wider text-light-grey'
              fontSize={14}
            >
              {description}
            </Typography>
          </div>
        </div>
        </div>
      <div className='flex justify-end pr-8 pt-10'>
        <Typography
          className='tracking-wider text-righttext-light-grey'
          fontSize={12}
        >
          {dateString}
        </Typography>
      </div>
    </div>
  )
}

export default NotificationItem
