import { Layout } from '../../components/Layout'
import Calendar from './components/Calendar'

const Availability = () => {
  return (
    <Layout>
      <div className="flex flex-col w-full h-full px-8 mt-6">
        <Calendar />
      </div>
    </Layout>
  )
}

export default Availability
