import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Typography } from '@mui/material'
import { MoreOptionsModalProps } from '../../utils/interfaces/props'

const MoreOptionsModal = ({
  options,
  open,
  handleClose,
  anchorEl,
}: MoreOptionsModalProps) => {
  return (
    <Menu
      id='basic-menu'
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        marginTop: 5,
        '& .MuiList-root': {
          backgroundColor: 'white',
          border: 'transparent 1px black',
        },
        '& .MuiMenu-paper': {
          backgroundColor: 'white',
          border: 'transparent 1px black',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.map((option) => {
        return (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleClose()
              option.action()
            }}
          >
            <Typography variant='inherit' className='text-black'>
              {option.label}
            </Typography>
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default MoreOptionsModal

