
export default function DataGrid() {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGrid-cell--withRenderer': {
            outline: 'none !important',
          },
          '.MuiDataGrid-footerContainer': {
            backgroundColor: '#E1E1E1'
          },
          '.MuiTablePagination-displayedRows': {
            fontWeight: 600
          },
          '.MuiDataGrid-cell': {
            whiteSpace: 'normal !important'
          }
        }
      }
    }
  }
}
