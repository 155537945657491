import React from 'react'
import MedappLogo from '../../assets/logo-completo.png'
import { Typography } from '@mui/material'
import CustomDataGrid from '../../components/CustomDataGrid'
import { GridColDef } from '@mui/x-data-grid'

const TableFees = () => {

  const rows = [
    {
      id: 1,
      concept: 'Reserva de turnos',
      value: '0%'
    },
    {
      id: 2,
      concept: 'Recordatorios',
      value: '0%'
    },
    {
      id: 3,
      concept: 'Canal de comunicación Rapport',
      value: '0%'
    }
    , {
      id: 4,
      concept: 'Consultas presenciales en consultorio',
      value: '0%'
    }
    , {
      id: 5,
      concept: 'Consultas presenciales a domicilio',
      value: '0%'
    }, {
      id: 6,
      concept: 'Teleconsultas Programadas',
      value: '5%'
    },
    {
      id: 7,
      concept: 'Teleconsultas Espontáneas',
      value: '5%'
    },
    {
      id: 8,
      concept: 'Historia clínica electrónica',
      value: '0%'
    },
    {
      id: 9,
      concept: 'Recetas electrónicas',
      value: '0%'
    },
    {
      id: 10,
      concept: 'Prescripciones digitales',
      value: '0%'
    }
  ]
  const columns: GridColDef[] = [
    {
      field: 'concept',
      headerName: 'Concepto',
      editable: false,
      flex: 1,
      sortable: false,
      renderCell: (cellValues): any => {
        return (<Typography>{cellValues.row.concept}</Typography>)
      },
    },
    {
      field: 'value',
      headerName: 'Valor',
      editable: false,
      flex: 0.5,
      sortable: false,
      renderCell: (cellValues): any => {
        return (<Typography>{cellValues.row.value}</Typography>)
      },
    }
  ]

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:px-16 px-4 md:py-6 mx-4 md:w-3/5 w-full rounded-xl md:max-h-[80vh] max-h-[90vh]'>
        <div className='flex md:pt-0 pt-6'>
          <img
            src={MedappLogo}
            alt='medapp login logo'
            className='mb-8 w-34 h-24'
          ></img>
        </div>
        <div className='flex w-full  py-6 flex-col overflow-auto'>
          <div className='font-montserrat font-normal text-xl tracking-wider text-pitch-black pb-4'>
            Tabla de comisiones
          </div>
          <div className='flex w-full h-[550px]'>
            <CustomDataGrid
              rows={rows}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableFees
