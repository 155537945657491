import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'zustand'
import { logout } from '../../services/auth'
import { authStore } from '../../store/auth'
import { useDoctorStore } from '../../store/doctor'

const Logout = () => {
  const { setLogout, user } = useStore(authStore)
  const { resetStore } = useDoctorStore()
  const navigate = useNavigate()

  useEffect(() => {
    const apiLogout = async () => {
      await logout()
    }

    if (user) {
      setLogout()
      resetStore()
      apiLogout()
    }

    navigate('/login', { replace: true })
  }, [navigate, setLogout, user, resetStore])

  return <></>
}

export default Logout
