import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import UserAvatar from '../../components/UserAvatar';
import ArrowGoPreviousPage from '../../components/arrowGoPreviousPage';
import CustomDataGrid from '../../components/CustomDataGrid';
import NothingToShow from '../../components/nothingToShow';
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png';
import { GridColDef } from '@mui/x-data-grid';
import { getSecretaryProfile } from '../../services/secretary';

const AdminSecretaryDetailsScreen = () => {
  const [rows, setRows] = useState<[]>([]);

  const location = useLocation();
  const { state }: { state: any } = location;

  useEffect(() => {
    const getData = async () => {
      const response = await getSecretaryProfile(state.secretaryData.id);
      setRows(response.doctors);
    };
    getData();
  }, [state.secretaryData.id]);

  if (!state) {
    return (
      <Layout>
        <></>
      </Layout>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Nombre',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center'>
            <UserAvatar
              imageUrl={cellValues.row.profilePictureUrl}
              size={44}
              altText={'IMG'}
            />
            <div className='pl-8'>{cellValues.row.firstName}</div>
          </div>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'licence',
      headerName: 'Matrícula',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      editable: false,
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <Layout>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col w-full justify-start pl-2 pr-2'>
          <div className='ml-12'>
            <ArrowGoPreviousPage
              path={'/admin/secretaries'}
              title={'Volver a asistentes'}
              state={{
                tabClicked: state.tabClicked,
              }}
            />
          </div>
          <div
            className={`font-montserrat pt-6 pb-12 pl-12 text-pitch-black font-bold text-2xl tracking-wider`}
          >
            Asistente
          </div>
          <div className='flex flex-col w-full h-full px-12'>
            <div className='flex flex-row w-full pt-6 bg-white'>
              <div className='w-2/5 h-full flex flex-col items-center justify-start p-6'>
                <div className='pb-6'>
                  <UserAvatar
                    imageUrl={state.secretaryData?.profilePictureUrl}
                    altText={state.secretaryData.lastName}
                    size={170}
                  />
                </div>
                <div className='font-montserrat font-normal text-xl text-pitch-black p-1'>
                  {state.secretaryData.firstName +
                    ' ' +
                    state.secretaryData.lastName}
                </div>
                <div className='font-montserrat font-normal text-xl text-pitch-black p-1'>
                  Tel: {state.secretaryData.phone}
                </div>
                <div className='font-montserrat font-normal text-pitch-black p-1 w-full'>
                  <p className='text-ellipsis overflow-hidden whitespace-nowrap text-center'>
                    Email: {state.secretaryData.email}
                  </p>
                </div>
              </div>
              <div className='w-3/5 flex flex-col pl-6'>
                <div className='w-full font-montserrat pt-10 text-pitch-black font-bold text-lg tracking-wider pb-4 pl-2'>
                  Médicos vinculados
                </div>
                <div
                  className={`flex mx-14 bg-white ${
                    rows.length !== 0 ? 'h-[500px]' : 'pb-12'
                  }`}
                >
                  {rows.length === 0 ? (
                    <NothingToShow
                      nothingImg={nothingToShowImg}
                      altText='pendiente de aprobación'
                      nothingText={'No posee profesionales vinculados...'}
                    />
                  ) : (
                    <CustomDataGrid
                      rows={rows}
                      rowHeight={64}
                      columns={columns}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminSecretaryDetailsScreen;
