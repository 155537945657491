import { Avatar } from '@mui/material'
import EmptyAvatar from '../assets/avatar_circular.png'
import { AvatarProps } from '../utils/interfaces/props'

const UserAvatar = ({ imageUrl, size, altText }: AvatarProps) => {
  return imageUrl ? (
    <Avatar alt={altText} src={imageUrl} sx={{ width: size, height: size }} />
  ) : (
    <Avatar alt={altText} src={EmptyAvatar} sx={{ width: size, height: size }} />
  )
}

export default UserAvatar
