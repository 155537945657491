import React from 'react'
import MedappLogo from '../../assets/logo-completo.png'
import Img1 from '../../assets/mobile-frontal.svg'
import Img2 from '../../assets/mobile-frontal2.svg'
import Img3 from '../../assets/mobile-frontal3.svg'

const DeleteUser = () => {

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:py-6 mx-4 md:w-3/5 rounded-xl md:max-h-[80vh] max-h-[90vh]'>
        <div className='flex md:pt-0 pt-6'>
          <img
            src={MedappLogo}
            alt='medapp login logo'
            className=' mb-8 w-34 h-24'
          ></img>
        </div>
        <div className='flex pt-6 flex-col overflow-auto w-full flex'>
          <div className='md:px-16 px-4 flex w-full flex-col' >

            <div className='font-montserrat font-bold font-normal text-xl tracking-wider text-pitch-black pb-4 '>
              Eliminación de Cuenta y Datos Personales
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              En MedApp, nos preocupamos por la privacidad y la seguridad de nuestros usuarios. Entendemos que en algunas ocasiones, es posible que desees eliminar tu cuenta y los datos relacionados con ella. Queremos asegurarte que respetamos tu decisión y te proporcionamos la opción de hacerlo de manera segura.
            </div>
            <div className='font-montserrat font-bold font-normal text-xl tracking-wider text-pitch-black pb-4 '>
              Proceso de Eliminación de Cuenta:
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              Cuando decides eliminar tu cuenta en MedApp, tené en cuenta lo siguiente:
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              1. Todos los datos asociados a tu cuenta serán permanentemente eliminados de nuestro sistema.
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              2. No podrás volver a acceder a tu cuenta ni a ningún contenido relacionado con ella.
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              3. La eliminación de la cuenta es irreversible. No podremos recuperar tus datos una vez completado el proceso.
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              Si deseas eliminar tu cuenta de Medapp y todos los datos correspondientes a la misma, seguí los siguientes pasos:
            </div>
            <div className='font-montserrat font-bold font-normal tracking-wider text-pitch-black pb-4 '>
              1. Accedé a la sección de perfil.
            </div>
            <div className='font-montserrat font-bold font-normal tracking-wider text-pitch-black pb-4 '>
              2. Seleccioná la ultima opción de “eliminar cuenta”.
            </div>
            <div className='font-montserrat font-bold font-normal tracking-wider text-pitch-black pb-4 '>
              3. Para confirmar la acción, ingresá la palabra “ELIMINAR” y continuar.
            </div>
            <div className='font-montserrat font-bold font-normal tracking-wider text-pitch-black pb-4 '>
              4. Por último, confirmá la eliminación de tu cuenta haciendo clic en el boton “eliminar cuenta”.
            </div>
            <div className='flex md:flex-row flex-col w-full'>
              <img src={Img1} alt='' />
              <img src={Img2} alt='' />
              <img src={Img3} alt='' />
            </div>
            <div className='font-montserrat font-bold font-normal text-xl tracking-wider text-pitch-black pb-4 '>
              Importante:
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>
              Una vez que hayas eliminado tu cuenta, no podrás recuperarla ni restaurar tus datos. Asegurate de guardar cualquier información importante antes de proceder.
              Si tenés alguna pregunta o necesitas asistencia durante el proceso de eliminación de cuenta, no dudes en contactarnos a través de <a href='https://medapp.com.ar/#contactanos' className='text-custom-blue'><strong>https://medapp.com.ar/#contactanos</strong></a>.
            </div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>Gracias por utilizar Medapp. Valoramos tu confianza y estamos acá para brindarte la mejor experiencia posible.</div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>Atentamente,</div>
            <div className='font-montserrat font-normal tracking-wider text-pitch-black pb-4 '>El Equipo de MedApp</div>
          </div>



        </div>
      </div>

    </div>
  )
}

export default DeleteUser
