import React from 'react'
import { Typography } from '@mui/material'
import { NothingToShowProps } from '../utils/interfaces/props'

const NothingToShow = ({
  nothingImg,
  altText,
  nothingText,
  nothingSubText,
}: NothingToShowProps) => {
  return (
    <div className='flex flex-col w-full pb-8'>
      <div className='full-width flex justify-center items-center pt-12'>
        <img src={nothingImg} alt={altText}></img>
      </div>
      <div className='flex w-full justify-center items-center font-montserrat font-bold text-2xl text-light-grey'>
        {nothingText}
      </div>
      {nothingSubText !== undefined && (
        <div className='flex w-full justify-center mt-2'>
          <Typography
            className={`text-light-grey`}
          >
            {nothingSubText}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default NothingToShow
