import React from 'react';

const MedappIsotipoIcon = ({ fill } : {fill?: string}) => {
  return (
    <svg width="30" height="26" viewBox="0 0 30 26" fill='none' xmlns="http://www.w3.org/2000/svg">
      <path d="M26.1135 18.1936C26.0641 18.1936 26.0167 18.2054 25.9674 18.2094L18.8127 5.39252C18.9864 4.96016 19.0831 4.49029 19.0831 3.99672C19.0831 1.91388 17.3892 0.219971 15.3084 0.219971C13.2275 0.219971 11.5336 1.91388 11.5336 3.99672C11.5336 4.87724 11.8396 5.68669 12.347 6.33029V18.1502L7.87529 10.9323C7.1152 9.70627 5.66807 9.14361 4.27622 9.53846C2.88437 9.93528 1.95055 11.1731 1.95055 12.6183V19.1057C1.32076 19.6486 0.914062 20.4423 0.914062 21.3366C0.914062 22.9673 2.24076 24.294 3.8715 24.294C5.50223 24.294 6.82893 22.9673 6.82893 21.3366C6.82893 20.2646 6.25048 19.3327 5.39365 18.8135V13.4613L9.86534 20.6812C10.4556 21.6327 11.4665 22.2013 12.572 22.2013C12.8721 22.2013 13.1722 22.1579 13.4644 22.075C14.8563 21.6801 15.7901 20.4403 15.7901 18.9951L15.7703 7.74386C15.8967 7.72807 16.023 7.70635 16.1454 7.67871L23.0435 20.0375C22.771 20.537 22.6013 21.0997 22.6013 21.7078C22.6013 23.6445 24.1767 25.22 26.1135 25.22C28.0502 25.22 29.6257 23.6445 29.6257 21.7078C29.6257 19.771 28.0502 18.1956 26.1135 18.1956V18.1936ZM24.5143 21.7058C24.5143 20.8233 25.231 20.1066 26.1135 20.1066C26.996 20.1066 27.7126 20.8233 27.7126 21.7058C27.7126 22.5883 26.996 23.3049 26.1135 23.3049C25.231 23.3049 24.5143 22.5883 24.5143 21.7058ZM17.0319 3.99475C17.0319 4.94634 16.258 5.72025 15.3064 5.72025C14.3548 5.72025 13.5809 4.94634 13.5809 3.99475C13.5809 3.04316 14.3548 2.26925 15.3064 2.26925C16.258 2.26925 17.0319 3.04316 17.0319 3.99475Z" fill={fill ? fill : '#242628'}/>
    </svg>
  )
}

export default MedappIsotipoIcon;