import { useEffect, useState } from 'react'
import { Track as TrackType, TrackPublication } from 'twilio-video'
import { Track } from './Track'
import { ParticipanProps } from '../../utils/interfaces/props'

export const Participant = (props: ParticipanProps) => {
  const { participant, localParticipant } = props
  const [tracks, setTracks] = useState<TrackType[]>([])

  useEffect(() => {
    const existingPublications: TrackPublication[] = Array.from(
      participant.tracks.values()
    )

    const existingTracks = existingPublications.map((publication: any) => {
      return publication.track
    })

    const nonNullTracks = existingTracks.filter((track) => track !== null)

    setTracks(nonNullTracks)
  }, [participant.tracks, setTracks])

  useEffect(() => {
    if (!localParticipant) {
      participant.on('trackSubscribed', (track: TrackType) => {
        setTracks((prev: TrackType[]) => {
          return [...prev, track]
        })
      })
    }
  }, [localParticipant, participant])

  return (
    <>
      {tracks.map((track) => {
        return <Track key={track.name} track={track} />
      })}
    </>
  )
}
