import { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { MoreOptions } from '../../components/MoreOptions';
import CustomModal from '../../components/customModal';
import { useNavigate } from 'react-router-dom';
import { DoctorData, doctorStatuses } from '../../utils/constants';
import ModalTick from '../../assets/modal-tick.png';
import { userActivation } from '../../services/user';

interface OptionsProps {
  label: string;
  action: () => void;
}

const AdminDashboardIcons = ({
  tabClicked,
  drData,
  onOpenModal,
  webDoctorGetter,
}: {
  tabClicked: string;
  drData: DoctorData;
  onOpenModal: Function;
  webDoctorGetter: Function;
}) => {
  const navigate = useNavigate();
  const [modalStatusIsOpen, setStatusIsOpen] = useState<boolean>(false);

  const switchDoctorStatus = async (id: number) => {
    const response: any = await userActivation(id, !drData.isActive);
    if (response.status === 200) {
      webDoctorGetter();
    }
  };

  const notPendingOptionsData: OptionsProps[] = [
    {
      label:
        tabClicked === doctorStatuses.ENABLED
          ? 'Ver perfil'
          : 'Aprobar usuario',
      action:
        tabClicked === doctorStatuses.ENABLED
          ? () =>
              navigate(`/admin/doctors/${drData.id}`, {
                replace: true,
                state: {
                  drData,
                  tabClicked,
                },
              })
          : () =>
              onOpenModal(doctorStatuses.ENABLED, drData.id, drData.licence),
    },
    {
      label: drData.isActive ? 'Bloquear usuario' : 'Desbloquear usuario',
      action: () => setStatusIsOpen(true),
    },
  ];

  return (
    <div
      className={`flex flex-row w-full ${
        tabClicked === doctorStatuses.APPROVAL_PENDING
          ? 'justify-between'
          : 'justify-end'
      }`}
    >
      {tabClicked === doctorStatuses.APPROVAL_PENDING && (
        <>
          <div className='flex w-3/4 items-center'>
            <div>
              <button
                className='pr-1'
                onClick={() => {
                  onOpenModal(doctorStatuses.ENABLED, drData.id);
                }}
              >
                <DoneIcon
                  color='secondary'
                  className='bg-green-light rounded-full p-1 w-3 h-3'
                />
              </button>
              <button
                className='pl-1'
                onClick={() => {
                  onOpenModal(doctorStatuses.REJECTED, drData.id);
                }}
              >
                <CloseIcon
                  color='secondary'
                  className='bg-red-error rounded-full p-1 w-3 h-3'
                />
              </button>
            </div>
          </div>
          <div
            onClick={() =>
              navigate(`/admin/doctors/${drData.id}`, {
                replace: true,
                state: {
                  drData,
                  tabClicked,
                },
              })
            }
            className='underline text-aqua-intense font-bold pr-4 cursor-pointer'
          >
            Ver perfil
          </div>
        </>
      )}
      {tabClicked !== doctorStatuses.APPROVAL_PENDING && (
        <MoreOptions optionsData={notPendingOptionsData} />
      )}
      <CustomModal
        openModal={modalStatusIsOpen}
        handleClose={() => setStatusIsOpen(false)}
        imgSrc={ModalTick}
        title={`¿Estás seguro de ${
          drData.isActive ? 'bloquear' : 'desbloquear'
        } este perfil?`}
        description={
          drData.isActive
            ? 'Una vez bloqueado se cancelarán los turnos que tenga activos este profesional y se realizará el reembolso de los mismos.'
            : 'Luego de esto, el usuario volverá a tener acceso al sistema.'
        }
        onSubmit={() => {
          switchDoctorStatus(drData.id);
          setStatusIsOpen(false);
        }}
        submitLabel='Aceptar'
        showCancelButton
        child={null}
        isSubmitDisable={null}
      />
    </div>
  );
};

export default AdminDashboardIcons;
