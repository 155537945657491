import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { es } from 'date-fns/locale'

const TextFieldForDatePicker = (
  props: JSX.IntrinsicAttributes & TextFieldProps
) => <TextField {...props} variant="outlined" className={props.className} />

export const CustomDatePicker = (
  props: {
    onChange: (value: Date) => void
    className: any
    value: Date
  } & any
) => {
  const { onChange, className } = props

  return (
    <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={(params) => (
          <TextFieldForDatePicker
            {...params}
            size="small"
            className={className}
          />
        )}
        onChange={(date: Date | null) =>
          onChange(new Date(date!.setHours(0, 0, 0, 0)))
        }
        {...props}
      />
    </LocalizationProvider>
  )
}
