import { Theme } from '@mui/material/styles'

export default function Drawer(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: () => ({
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          boxShadow: 'none !important'
        }),
      },
    },
  }
}
