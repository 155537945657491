import React, { useState } from 'react'
import NothingToShow from '../../components/nothingToShow'
import { RapportDetails } from '../../utils/constants'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import {
  GridColDef,
  GridRowHeightParams,
} from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import { format } from 'date-fns'
import CustomDataGrid from '../../components/CustomDataGrid'

const MedicalRecordsDetails = (props: {
  row: any
  setExpandedRows: (data: number[]) => void
  expandedRows: number[]
}) => {
  const { row, setExpandedRows, expandedRows } = props
  const updated = new Date(row.updatedAt)
  const expandedClass: string = 'line-clamp-2'

  return (
    <div className='flex flex-col justify-between items-start mt-3  w-full h-full'>
      <div className={`${!expandedRows.includes(row.id) ? expandedClass : ''} mb-4`}>
        <Typography fontWeight='bold'>Descripción</Typography>
        <Typography>
          {row.description}
        </Typography>
        {row.pathology && (
          <div
            className={`flex flex-col mt-2`}
          >
            <Typography fontWeight='bold'>{row.pathology.title}</Typography>
          </div>
        )}
        {row.dose &&  (
          <div
            className={`flex flex-col mt-2`}
          >
            <Typography fontWeight='bold'>Dosis</Typography>
            <Typography>{row.dose}</Typography>

            {row.frequency && (
              <div className='mt-2'>
                <Typography fontWeight='bold'>
                  Frecuencia del medicamento
                </Typography>
                <Typography>{row.frequency}</Typography>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='text-light-grey'>
        <Typography fontStyle='italic' fontSize='small'>Actualizado el {format(updated, 'dd/MM/yy')}</Typography>
      </div>
      <div className='mb-2'>
        {expandedRows.includes(row.id) ? (
          <Button
            onClick={() => {
              const aux = expandedRows.filter((id) => id !== row.id)
              setExpandedRows([...aux])
            }}
          >
            Ver menos
          </Button>
        ) : (
          <Button onClick={() => setExpandedRows([...expandedRows, row.id])}>
            Ver más
          </Button>
        )}
      </div>
    </div>
  )
}

const MedicalRecords = ({
  rapportDetails,
}: {
  rapportDetails: RapportDetails
}) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const columns: GridColDef[] = [
    {
      field: 'recordType',
      headerName: 'Tipo de antecedente',
      editable: false,
      flex: 0.5,
      renderCell: (cellValues) => {
        return <div>{cellValues.row.antecedentType.title}</div>
      },
      sortable: false,
    },
    {
      field: 'detail',
      headerName: 'Detalle',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <MedicalRecordsDetails
            row={cellValues.row}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
          />
        )
      },
      sortable: false,
    },
  ]

  const rows = rapportDetails.medicalRecords.map((mR: any) => {
    return {
      ...mR,
      id: mR.id,
    }
  })

  const getRowHeight = (params: GridRowHeightParams) => {
    const { id } = params
    const rowHeight = 126
    if (expandedRows.includes(typeof id === 'string' ? parseInt(id) : id)) {
      const additionalHeight =
        (params.model.dose ? 56 : 0) +
        (params.model.frequency ? 56 : 0) +
        (params.model.pathology ? 40 : 0)
      return rowHeight + additionalHeight
    }
    return rowHeight
  }

  return (
    <div className='mt-3 h-[495px] w-full'>
      {rows.length > 0 ? (
        <CustomDataGrid
          rows={rows}
          columns={columns}
          getRowHeight={getRowHeight}
        />
      ) : (
        <NothingToShow
          nothingImg={nothingToShowImg}
          altText={'sin historial clínico de paciente'}
          nothingText={'No tenés nada que ver hasta el momento...'}
        />
      )}
    </div>
  )
}

export default MedicalRecords
