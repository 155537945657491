import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { DoctorState } from '../utils/interfaces/stores'

export const useDoctorStore = create<DoctorState>()(
  devtools(persist((set) => ({
    paymentsFee: {
      presentialFee: 0,
      videocallFee: 0
    },
    setPaymentsFee: (data: any) =>
      set(() => ({
        paymentsFee: {
          ...data
        }
      })),
    medicalProfile: {
      trajectory: '',
      healthCoveragesIds: [],
      specialtiesIds: []
    },
    setMedicalProfile: (data: any) =>
      set(() => ({
        medicalProfile: {
          trajectory: data.trajectory,
          healthCoveragesIds: data.doctorHealthCoverages.map(
            (h: any) => h.healthCoverageId
          ),
          specialtiesIds: data.doctorSpecialties.map((s: any) => s.specialtyId)
        }
      })),
    availability: {
      presentialAppointments: false,
      homeAppointments: false,
      videocallAppointments: false
    },
    setAvailability: (data: any) =>
      set(() => ({
        availability: {
          ...data
        }
      })),
    resetStore: () =>
      set(() => ({
        medicalProfile: {
          trajectory: '',
          healthCoveragesIds: [],
          specialtiesIds: []
        },
        availability: {
          presentialAppointments: false,
          homeAppointments: false,
          videocallAppointments: false,
          doctorAddress: {
            street: '',
            streetNumber: '',
            floorApartment: '',
            province: { id: "", tag: '', title: '' },
            city: { id: "", tag: '', title: '' }
          }
        }
      }))
  }),
    { name: 'DoctorStore' }))
)
