const SelectBox = (props: {
  selected: boolean
  id: string
  title: string
  onClick: () => void
  icon?: JSX.Element
  disabled?: boolean
}) => {
  const { onClick, selected, id, icon, title, disabled } = props
  const baseStyle =
    'flex flex-row items-center space-x-2 cursor-pointer rounded py-1 px-3 border'

  return (
    <div
      className={`${baseStyle} ${selected ? 'bg-yellow-custom' : ''} ${disabled ? 'bg-soft-grey cursor-default transititext-primary text-primary transition duration-150 ease-in-out' : ''}`}
      onClick={!disabled ? onClick : () => {}}
      key={id}
      title="Para seleccionar este tipo de atención, cambie la configuración de su perfil."
    >
      {icon && <div>{icon}</div>}
      <p>{title}</p>
    </div>
  )
}

export default SelectBox
