import { Appointments } from '@devexpress/dx-react-scheduler-material-ui'
import { styled } from '@mui/system'

const PREFIX = 'calendar'

export const classes = {
  weekdayCell: `${PREFIX}-weekdayCell`,
  weekendCell: `${PREFIX}-weekendCell`,
  weekday: `${PREFIX}-weekday`,
  weekend: `${PREFIX}-weekend`,
  appointment: `${PREFIX}-appointment`,
  disabledAppointment: `${PREFIX}-disabledAppointment`,
  selectedAppointment: `${PREFIX}-selecctedAppointment`,
  selectedAvailability: `${PREFIX}-selecctedAvailability`,
  layoutContainer: `${PREFIX}-layout`
}

export const StyledAppointment = styled(Appointments.Appointment)(() => ({
  [`&.${classes.appointment}`]: {
    width: '106%',
    height: '102%',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.6
    }
  },
  [`&.${classes.selectedAvailability}`]: {
    width: '106%',
    height: '102%',
    backgroundColor: '#FFFFFF',
    border: '2px solid #C2D400',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.6
    }
  },
  [`&.${classes.selectedAppointment}`]: {
    width: '106%',
    height: '102%',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFA800',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.6
    }
  },
  [`&.${classes.disabledAppointment}`]: {
    width: '106%',
    height: '102%',
    backgroundColor: '#BEBEBE',
    '&:hover': {
      backgroundColor: '#BEBEBE',
      cursor: 'default'
    }
  }
}))
