import { http } from '../utils/http'
import { AxiosResponse } from 'axios'

interface NewAdminValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

export const getAdministrators = async (isActive?: boolean) => {
  try {
    const response = await http.get('/api/admin', {
      params: {
        isActive,
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}

export const createNewAdmin = async (
  data: NewAdminValues
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/admin', {
      ...data,
    })

    return response
  } catch (error) {
    return error
  }
}

export const patchUserActivation = async (id: number, isActive: boolean) => {
  try {
    const response = await http.patch(`/api/admin/${id}/activation`, {
      isActive,
    })
    return response.data
  } catch (error) {
    return error
  }
}
