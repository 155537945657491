import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek
} from 'date-fns'
import { daysOfTheWeek, hours } from './constants'
import { RRule, Weekday } from 'rrule'
import { AvailabilitySlotType, NewAvailabilityDto } from '../../utils/constants'

export const nextId = (currentId: string): string => {
  return (parseInt(currentId) + 1).toString()
}

export const currentId = (): string => {
  const now = new Date()
  const currentHour = now.getHours() + 1
  const currentMinute = now.getMinutes()
  let currentId: string = ''

  hours.forEach((hour) => {
    const [hourNumber, minuteNumber] = hour.title.split(':')
    if (
      currentHour > parseInt(hourNumber) ||
      (currentHour === parseInt(hourNumber) &&
        currentMinute >= parseInt(minuteNumber))
    ) {
      currentId = hour.id
    }
  })

  return currentId
}

export const generateRRule = (
  startDate: Date,
  byweekday: Weekday[],
  untilDate?: Date
) => {
  const rule = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday,
    dtstart: startDate,
    until: untilDate
  })

  return rule.toString()
}

export const hourStringToDate = (hour: string, date: Date) => {
  const hourString = hours.find((h) => h.id === hour)
  const [hourNumber, minuteNumber] = hourString!.title.split(':')
  const newDate = new Date(date)
  newDate.setHours(parseInt(hourNumber), parseInt(minuteNumber), 0)

  return newDate
}

export const parseAvailabilityType = (
  remote: boolean,
  onSite: boolean
): AvailabilitySlotType => {
  let availabilitySlotType = AvailabilitySlotType.presentialVideocall

  if (remote && !onSite) {
    availabilitySlotType = AvailabilitySlotType.videocall
  }

  if (!remote && onSite) {
    availabilitySlotType = AvailabilitySlotType.presential
  }

  return availabilitySlotType
}

export const parseData = (data: any): NewAvailabilityDto => {
  const { date, startHour, endHour, remote, onSite, validity, selectedDays } =
    data

  let untilDate = date
  switch (validity) {
    case '1':
      untilDate = addDays(date, 6)
      break
    case '2':
      untilDate = addWeeks(date, 1)
      break
    case '3':
      untilDate = addWeeks(date, 2)
      break
    case '4':
      untilDate = addMonths(date, 1)
      break
    case '5':
      untilDate = addMonths(date, 2)
      break
    default:
      break
  }

  const startDate = hourStringToDate(startHour, date)
  const endDate = hourStringToDate(endHour, date)
  untilDate = hourStringToDate(startHour, untilDate)

  const availabilitySlotType = parseAvailabilityType(remote, onSite)

  const byweekday: Weekday[] = selectedDays.map((day: string) => {
    const selectedDayData = daysOfTheWeek.find((dayData) => day === dayData.id)
    return selectedDayData?.rrule
  })
  const rrule: string = generateRRule(startDate, byweekday, untilDate)

  return {
    startDate,
    endDate,
    availabilitySlotType,
    rrule
  }
}

export const getWeekDateRange = (date: Date) => {
  const startDate = startOfWeek(date, { weekStartsOn: 0 })
  startDate.setHours(0, 0, 0, 0)
  const endDate = addDays(endOfWeek(date, { weekStartsOn: 0 }), 1) 

  endDate.setHours(0, 0, 0, 0)

  return { startDate, endDate }
}

export const getMonthDateRange = (date: Date) => {
  const startDate = startOfMonth(date)
  startDate.setHours(0, 0, 0, 0)

  const lastDayOfMonth = endOfMonth(date)
  const endDate = addDays(lastDayOfMonth, 1)
  endDate.setHours(0, 0, 0, 0)

  return { startDate, endDate }
}
