import { Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { connect, Room } from 'twilio-video'
import { Layout } from '../../components/Layout'
import { VideoRoom } from './Room'
import { useLocation } from 'react-router-dom'
import { getTwilioToken } from '../../services/appointments'
import { useAlertStore } from '../../store/alertStore'
import Lottie from 'lottie-react'
import animation from '../../assets/lotties/lobby-animation.json'

interface AuthData {
  accessToken: string
  roomName: string
}

export const Lobby = () => {
  const [room, setRoom] = useState<Room | null>(null)
  const location = useLocation()
  const { state }: { state: any } = location
  const { setAlert } = useAlertStore()
  const [validToken, setValidToken] = useState<boolean>(false)
  const [data, setData] = useState<AuthData>({
    accessToken: '',
    roomName: ''
  })

  useEffect(() => {
    const apiCall = async () => {
      const response = await getTwilioToken(state.id)

      if (response.data && response.data.accessToken) {
        setAlert({
          isOpen: true,
          message:
            '¡Bienvenido! Recordá que la tolerancia de espera es de 10 minutos. Pasado ese tiempo podes dar por concluido el turno.',
          severity: 'success'
        })
        setData(response.data)
      } else {
        setAlert({
          isOpen: true,
          message: response.response.data.message,
          severity: 'error'
        })
      }
    }

    apiCall()

    return () => setRoom(null)
  }, [setAlert, state.id])

  const joinRoom = async (token: string) => {
    try {
      const room = await connect(token, {
        audio: true,
        video: { height: 640, frameRate: 24, width: 480 },
        bandwidthProfile: {
          video: {
            mode: 'collaboration',
            dominantSpeakerPriority: 'high'
          }
        },
        dominantSpeaker: true,
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: { local: 1, remote: 1 }
      })

      setRoom(room)
    } catch (err) {
      setAlert({
        isOpen: true,
        message: 'El acceso a la sala ha expirado.',
        severity: 'error'
      })
      setValidToken(false)
      console.log(err)
    }
  }

  const returnToLobby = () => {
    setRoom(null)
  }

  return (
    <Layout>
      <div className="w-full h-full">
        {room === null ? (
          <div className="lobby relative">
            <p className="absolute font-montserrat text-2xl font-bold leading-8 tracking-tighter text-left ml-6">
              Sala de espera
            </p>
            <div className="flex flex-col items-center  w-full space-y-3">
              <Lottie
                className="max-w-[500px]"
                animationData={animation}
                loop={true}
              />
              <p className="font-montserrat font-bold leading-7 text-xl tracking-tighter text-center w-[100px]">
                ¡Bienvenido!
              </p>
              <Typography className="font-montserrat text-base font-normal leading-5 tracking-tighter text-center max-w-[450px]">
                Preparate para tu consulta encontrando un espacio tranquilo con
                una conexión a internet estable.
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                className="mt-12"
                onClick={() => joinRoom(data.accessToken)}
                disabled={data.accessToken === '' && !validToken}
              >
                Ingresar a la sala
              </Button>
            </div>
          </div>
        ) : (
          <VideoRoom
            returnToLobby={returnToLobby}
            room={room}
            patient={state.patient}
          />
        )}
      </div>
    </Layout>
  )
}
