import { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import { Button, Typography } from '@mui/material'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import { GridColDef } from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import { getAdministrators, patchUserActivation } from '../../services/administrators'
import { NewAdminModal } from './NewAdminModal'
import CustomSearchBox from '../../components/CustomSearchBox'
import NothingToShow from '../../components/nothingToShow'
import CustomDataGrid from '../../components/CustomDataGrid'
import { MoreOptions } from '../../components/MoreOptions'

const Admins = () => {
  const [administrators, setAdministrators] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [searchText, setSearchText] = useState<string>('')

  const activateUser = async (id: number, isActive: boolean) => {
    const response = await patchUserActivation(id, isActive)
    if (response) {
      getData()
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Nombre',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row items-center'>
            <UserAvatar imageUrl={undefined} size={44} altText={'IMG'} />
            <div className='pl-8'>
              {cellValues.row.firstName} {cellValues.row.lastName}
            </div>
          </div>
        )
      },
      flex: 1,
      sortable: false,
    },
    {
      field: 'isActive',
      headerName: 'Estado',
      editable: false,
      flex: 1,
      sortable: false,
      renderCell: (cellValues): any => {
        return (
          <Typography fontWeight='bold' className={`capitalize bg-${cellValues.row.isActive ? 'green' : 'red'} bg-opacity-10 text-${cellValues.row.isActive ? 'green' : 'red'} px-4 py-2 rounded-3xl`}>
            {cellValues.row.isActive ?  'Activo' : 'Bloqueado'}
          </Typography>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (cellValues): any => {
        return null
      },
      flex: 1,
      sortable: false,
    },
    {
      field: '',
      headerName: '',
      renderCell: (cellValues): any => {
        return (
          <div className='flex flex-row w-full justify-end'>
            <MoreOptions
              optionsData={[
                {
                  label: cellValues.row.isActive ? 'Bloquear usuario' : 'Desbloquear usuario',
                  action: () => activateUser(cellValues.row.id, !(cellValues.row.isActive)),
                },
              ]}
            />
          </div>
        )
      },
      flex: 1,
      sortable: false,
    },
  ]

  const getData = async () => {
    const response = await getAdministrators()
    setAdministrators(response.items)
  }

  useEffect(() => {
    getData()
  }, [])

  const filterRow = (row: any) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())

  return (
    <Layout>
      <div className='flex flex-col w-full h-full px-14 mt-6'>
        <div className='flex w-full items-center mb-8'>
          <div className='flex w-1/5 justify-start'>
            <Typography
              className={`text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight='bold'
            >
              Administradores
            </Typography>
          </div>
          <div className='flex w-4/5 justify-end'>
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
            <Button
              color='secondary'
              variant='contained'
              onClick={() => setModalIsOpen(true)}
            >
              Nuevo administrador
            </Button>
          </div>
        </div>
        <div className='flex mt-4 h-[500px]'>
          {(searchText.length &&
            administrators.filter(filterRow).length === 0) ||
          administrators.length === 0 ? (
            <NothingToShow
              nothingImg={nothingToShowImg}
              altText={'sin data de paciente'}
              nothingText={'No tenés nada que ver hasta el momento...'}
            />
          ) : (
            <CustomDataGrid
              rows={
                searchText.length
                  ? administrators.filter(filterRow)
                  : administrators
              }
              rowHeight={72}
              columns={columns}
            />
          )}
        </div>
        <NewAdminModal
          handleClose={() => {
            setModalIsOpen(false)
            getData()
          }}
          open={modalIsOpen}
        />
      </div>
    </Layout>
  )
}

export default Admins
