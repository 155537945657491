import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { GridColDef } from "@mui/x-data-grid";
import UserAvatar from "../../components/UserAvatar";
import { useAlertStore } from "../../store/alertStore";
import CustomModal from "../../components/customModal";
import ModalCross from "../../assets/modal-cross.png";
import ModalTick from "../../assets/modal-tick.png";
import { TextField, Typography } from "@mui/material";
import { doctorStatuses as userStatus } from "../../utils/constants";
import nothingToShowImg from "../../assets/datagrid-nothing-to-show.png";
import NothingToShow from "../../components/nothingToShow";
import CustomSearchBox from "../../components/CustomSearchBox";
import CustomDataGrid from "../../components/CustomDataGrid";
import {
  enableSecretary,
  getSecretaries,
  rejectSecretary,
} from "../../services/secretary";
import AdminDashboardIconsSecretary from "./adminDashboardIconsSecretary";

const AdminSecretaryApprobal = () => {
  const location = useLocation();
  const { state }: { state: any } = location;
  const [tabClicked, setTabClicked] = useState<string>(
    (state !== null && state.tabClicked) || userStatus.APPROVAL_PENDING
  );
  const [rows, setRows] = useState<[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<string>("");
  const [secretaryId, setSecretaryId] = useState<number | null>(null);

  const [searchText, setSearchText] = useState<string>("");

  const [REJECTEDMessage, setREJECTEDMessage] = useState<string>("");

  const { setAlert } = useAlertStore();

  const handleChange = (str: string) => {
    setREJECTEDMessage(str);
  };

  const getSecretariesList = async () => {
    const response = await getSecretaries(tabClicked);
    if (response) {
      setRows(response.items);
    }
  };

  const handleClose = () => {
    setModalOpen("");
    setModalIsOpen(false);
  };

  useEffect(() => {
    getSecretariesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabClicked]);

  const enabledHandler = async (id: number) => {
    const resp = id !== null && (await enableSecretary(id));
    if (resp.error) {
      setAlert({
        isOpen: true,
        message: `No se ha podido aprobar el perfil`,
        severity: "error",
      });
    } else {
      setAlert({
        isOpen: true,
        message: `Se ha aprobado el perfil con éxito`,
        severity: "success",
      });
    }
  };

  const REJECTEDHandler = async (id: number, rejectedMessage: string) => {
    const resp = id !== null && (await rejectSecretary(id, rejectedMessage));
    if (resp.error) {
      setAlert({
        isOpen: true,
        message: `No se ha podido rechazar el perfil`,
        severity: "error",
      });
    } else {
      setAlert({
        isOpen: true,
        message: `Se ha rechazado el perfil con éxito`,
        severity: "success",
      });
    }
  };

  const acceptHandler = async (id: number) => {
    modalOpen === userStatus.ENABLED && (await enabledHandler(id));
    modalOpen === userStatus.REJECTED &&
      (await REJECTEDHandler(id, REJECTEDMessage));
    getSecretariesList();
    handleClose();
  };

  const openModalHandler = (
    modalType: string,
    docId: number,
    docLicence: number
  ) => {
    setModalIsOpen(true);
    setModalOpen(modalType);
    setSecretaryId(docId);
    // setInitialLicence(docLicence);
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "Nombre",
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <UserAvatar
              imageUrl={
                tabClicked !== userStatus.APPROVAL_PENDING
                  ? cellValues.row.profilePictureUrl
                  : undefined
              }
              size={44}
              altText={"IMG"}
            />
            <div className="pl-8">{cellValues.row.firstName}</div>
          </div>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: "phone",
      headerName: "Teléfono",
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: "icons",
      headerName: "",
      renderCell: (cellValues): any => {
        return (
          <AdminDashboardIconsSecretary
            secretaryData={cellValues.row}
            tabClicked={tabClicked}
            onOpenModal={openModalHandler}
            getterFunction={getSecretariesList}
          />
        );
      },
      flex: 1,
      sortable: false,
    },
  ];

  if (tabClicked === userStatus.ENABLED) {
    columns.splice(columns.length - 1, 0, {
      field: "status",
      headerName: "Estado",
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="w-full flex justify-start">
            {cellValues.row.isActive ? (
              <div className="bg-green  bg-opacity-10 px-4 py-1 rounded-full">
                <Typography className="text-green font-bold">Activo</Typography>
              </div>
            ) : (
              <div className="bg-red  bg-opacity-10 px-4 py-1 rounded-full">
                <Typography className="text-red font-bold">
                  Bloqueado
                </Typography>
              </div>
            )}
          </div>
        );
      },
      flex: 1,
      sortable: false,
    });
  }

  const filterRow = (row: any) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase());

  return (
    <Layout>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full items-center">
          <div className="flex w-1/5 justify-start pl-2 pr-2">
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight="bold"
            >
              Asistentes
            </Typography>
          </div>
          <div className="flex w-4/5 justify-end pl-2 pr-2">
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div className="flex mx-14 bg-white">
          <div className="flex my-4 ml-4 flex-row bg-soft-grey justify-center items-center p-2 rounded-lg">
            <div
              onClick={() => setTabClicked(userStatus.APPROVAL_PENDING)}
              className={`font-montserrat text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === userStatus.APPROVAL_PENDING && "bg-aqua-soft"
              }`}
            >
              Pendientes
            </div>
            <div
              onClick={() => setTabClicked(userStatus.ENABLED)}
              className={`font-montserrat  text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === userStatus.ENABLED && "bg-aqua-soft"
              }`}
            >
              Aprobados
            </div>
            <div
              onClick={() => setTabClicked(userStatus.REJECTED)}
              className={`font-montserrat text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === userStatus.REJECTED && "bg-aqua-soft"
              }`}
            >
              Rechazados
            </div>
          </div>
        </div>
        <div
          className={`flex mx-14 bg-white ${
            rows.length !== 0 ? "h-[500px]" : "pb-12"
          }`}
        >
          {(searchText.length && rows.filter(filterRow).length === 0) ||
          rows.length === 0 ? (
            <NothingToShow
              nothingImg={nothingToShowImg}
              altText="pendiente de aprobación"
              nothingText={"No tenés nada que ver hasta el momento..."}
            />
          ) : (
            <CustomDataGrid
              rows={searchText.length ? rows.filter(filterRow) : rows}
              rowHeight={64}
              columns={columns}
            />
          )}
        </div>
        {modalIsOpen && (
          <CustomModal
            openModal={modalIsOpen}
            handleClose={handleClose}
            imgSrc={modalOpen !== userStatus.ENABLED ? ModalCross : ModalTick}
            title={
              modalOpen !== userStatus.ENABLED
                ? "¿Estás seguro de rechazar este perfil?"
                : "¿Estás seguro de aprobar este perfil?"
            }
            description={
              modalOpen !== userStatus.ENABLED
                ? "A continuación podés enviarle un mensaje, de manera opcional"
                : "Una vez aprobado el asistente podrá comenzar a utilizar Medapp"
            }
            onSubmit={() => secretaryId && acceptHandler(secretaryId)}
            submitLabel="Aceptar"
            showCancelButton
            isSubmitDisable={false}
            child={
              modalOpen !== userStatus.ENABLED ? (
                <div className="w-full pb-4">
                  <TextField
                    variant="outlined"
                    multiline
                    rows={3}
                    id="rejMessage"
                    name="rejMessage"
                    placeholder="Mensaje"
                    className="w-full"
                    value={REJECTEDMessage}
                    onChange={(e) => {
                      if (REJECTEDMessage.length < 200) {
                        handleChange(e.target.value);
                      }
                    }}
                  />
                </div>
              ) : null
            }
          />
        )}
      </div>
    </Layout>
  );
};

export default AdminSecretaryApprobal;
