import { Typography } from '@mui/material'
import pendingApprovalImg from '../../../assets/pending_approval-img.png'

const AdminWelcome = () => {

  return (
    <div className="bg-silver h-full w-full pb-6">
      <Typography
        className={`p-10 pl-12 text-pitch-black tracking-wider`}
        fontSize={28}
        fontWeight='bold'
      >
        ¡Bienvenido!
      </Typography>
      <div className="full-width flex justify-center items-center pt-16 pb-16">
        <img src={pendingApprovalImg} alt="Approval pending"></img>
      </div>
      <p className="font-montserrat text-2xl font-bold text-light-grey text-center">
        Sin resultados
      </p>
      <p className="font-montserrat font-normal text-base text-center text-light-grey">
        No tienes nada para ver hasta el momento...
      </p>
    </div>
  )
}

export default AdminWelcome
