import { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Dialog (theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: '500px',
          backgroundColor: 'transparent'
        },
        paperFullWidth: {
          width: '100%'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2)
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1)
        }
      }
    }
  }
}
