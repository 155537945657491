import { Button, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const CalendarHeader = (props: {
  view: string
  setView: (view: string) => void
  currentDate: Date
  setCurrentDate: (date: Date) => void
}) => {
  const { view, currentDate, setView, setCurrentDate } = props

  const monthName = format(currentDate, 'MMMM', { locale: es })
  const monthLabel = monthName.charAt(0).toUpperCase() + monthName.slice(1)

  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate()
  )
  const previousMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    currentDate.getDate()
  )

  const nextWeekDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 7
  )

  const previousWeekDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  )

  return (
    <Box className="flex flex-row justify-between mt-4 px-6 items-center">
      <Box className="flex flex-row w-[300px] rounded-lg justify-between bg-silver p-3 space-x-3">
        <Button
          color="primary"
          variant={view === 'monthly' ? 'contained' : 'text'}
          type="submit"
          className="w-full"
          onClick={() => setView('monthly')}
        >
          Vista mensual
        </Button>
        <Button
          color="primary"
          variant={view === 'weekly' ? 'contained' : 'text'}
          type="submit"
          className="w-full"
          onClick={() => setView('weekly')}
        >
          Vista semanal
        </Button>
      </Box>
      <Box className="flex flex-row w-[250] items-end mx-8">
        <IconButton
          size="large"
          className="w-[25px]"
          onClick={() =>
            setCurrentDate(
              view === 'weekly' ? previousWeekDate : previousMonthDate
            )
          }
        >
          <p className="font-montserrat text-pitch-black font-bold text-xl tracking-wider">
            {'<'}
          </p>
        </IconButton>

        <Box className="w-[200px] flex flex-row items-center justify-center p-3">
          <p className="font-montserrat text-pitch-black font-bold text-xl tracking-wider mr-2">
            {monthLabel}
          </p>
          <p className="font-montserrat text-pitch-black font-bold text-xl tracking-wider">
            {format(currentDate, 'yyyy')}
          </p>
        </Box>

        <IconButton
          size="large"
          className="w-[25px]"
          onClick={() =>
            setCurrentDate(view === 'weekly' ? nextWeekDate : nextMonthDate)
          }
        >
          <p className="font-montserrat text-pitch-black font-bold text-xl tracking-wider">
            {'>'}
          </p>
        </IconButton>
      </Box>
    </Box>
  )
}

export default CalendarHeader
