import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, TextField, InputAdornment, Typography } from '@mui/material';
import MedappLogo from '../../assets/logo-completo.png';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/auth';
import { useStore } from 'zustand';
import { authStore } from '../../store/auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAlertStore } from '../../store/alertStore';
import packageJson from '../../../package.json';
import { useDoctorStore } from '../../store/doctor';
import { userState } from '../../utils/interfaces/stores';

const LoginScreen = () => {
  const [passwordIsHidden, setPasswordIsHidden] = useState<boolean>(true);
  const navigateTo = useNavigate();
  const { setAlert } = useAlertStore();
  const { setAvailability, setPaymentsFee } = useDoctorStore();

  const {
    setUser,
    setProfilePicture,
    user,
    setAccessToken,
    setRefreshToken,
    setVerifyToken,
    accessToken,
  } = useStore(authStore);

  useEffect(() => {
    if (user !== null && accessToken) {
      navigateTo('/welcome', { replace: true });
    }
  }, [navigateTo, user, accessToken]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'El email no tiene un formato válido')
      .required('Es obligatorio ingresar el email'),
    password: Yup.string()
      .min(8, 'La contraseña es muy corta')
      .max(16, 'La contraseña es demasiada larga')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&Ññ]{8,16}$/,
        'La contraseña no es válida',
      )
      .required('Es obligatorio ingresar contraseña'),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { email, password } = values;
      const response: any = await login(email, password);

      if (response.status !== 200) {
        setAlert({
          isOpen: true,
          message:
            'Error en la autenticación, por favor verifique los datos ingresados',
          severity: 'error',
        });
        return;
      }

      if (response.status === 200) {
        const {
          firstName,
          lastName,
          userId,
          email,
          type,
          phone,
          licence,
          profilePictureUrl,
        } =
          response.data.doctor ||
          response.data.admin ||
          response.data.secretary;

        if (response.data.doctor) {
          const {
            doctorStatus,
            hasMercadoPago,
            mercadoPagoUserName,
            mercadoPagoEmail,
          } = response.data.doctor;
          setUser({
            firstName,
            lastName,
            email,
            phone,
            id: userId,
            role: type,
            licence,
            doctorStatus,
            hasMercadoPago: hasMercadoPago || undefined,
            mercadoPagoUserName: mercadoPagoUserName || undefined,
            mercadoPagoEmail: mercadoPagoEmail || undefined,
          } as userState);

          const {
            videocallAppointments,
            presentialAppointments,
            homeAppointments,
          } = response.data.doctor;
          setAvailability({
            videocallAppointments,
            presentialAppointments,
            homeAppointments,
          });

          const { videocallFee, presentialFee } = response.data.doctor;
          setPaymentsFee({ videocallFee, presentialFee });
        } else {
          setUser({
            firstName,
            lastName,
            email,
            phone,
            id: userId,
            role: type,
            licence,
          } as userState);
        }

        if (profilePictureUrl) {
          setProfilePicture(profilePictureUrl);
        }

        setAlert({
          isOpen: true,
          message: 'Usuario logueado con éxito...',
          severity: 'success',
        });

        if (response.data.accessToken) {
          const { accessToken, refreshToken } = response.data;
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
        } else if (response.data.verificationToken) {
          setVerifyToken(response.data.verificationToken);
          navigateTo('/verify-email', { replace: true });
        }
      }
    },
  });

  useEffect(() => {
    validateForm();
    setVerifyToken(null);
  }, [validateForm, setVerifyToken]);

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:px-16 px-4 md:py-6 pb-4 mx-4 md:w-2/5 w-full rounded-xl md:max-h-[80vh] max-h-[90vh]'>
        <div className='flex md:pt-0 pt-6'>
          <img
            src={MedappLogo}
            alt='medapp login logo'
            className=' mb-8 w-34 h-24'
          ></img>
        </div>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col w-full items-center'
        >
          <div className='w-full'>
            <TextField
              label='Email'
              variant='outlined'
              id='email'
              size='small'
              className='w-full white-background'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email && touched.email}
              helperText={
                !!errors.email && touched.email !== undefined && errors.email
              }
            />
          </div>
          <div className='w-full mt-4'>
            <TextField
              label='Contraseña'
              variant='outlined'
              id='password'
              size='small'
              className='w-full white-background'
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.password && touched.password}
              helperText={
                !!errors.password &&
                touched.password !== undefined &&
                errors.password
              }
              type={passwordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {passwordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color='disabled'
                        onClick={() => setPasswordIsHidden((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color='disabled'
                        onClick={() => setPasswordIsHidden((prev) => !prev)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              onKeyPress={(e: any) => e.charCode === 13 && handleSubmit()}
            />
          </div>
          <div className='flex flex-row items-center w-full justify-between pt-2'>
            <div className='flex flex-row w-1/2'>
              {/* REMEMBER ME CHECKBOX */}
            </div>
            <p
              onClick={() =>
                navigateTo('/recovery-password', {
                  replace: true,
                })
              }
              className='font-montserrat font-bold text-sm text-pitch-black leading-8 text-center underline cursor-pointer'
            >
              ¿Olvidaste tu contraseña?
            </p>
          </div>
          {process.env.REACT_APP_ENV !== 'production' && (
            <div className='w-full flex mt-6 justify-center'>
              <Typography
                className='text-secondary-light-grey'
                fontSize='small'
              >
                Versión: {packageJson.version}
              </Typography>
            </div>
          )}
          <div className='md:mb-0 mb-4 mt-6 w-full'>
            <Button
              color='secondary'
              variant='contained'
              type='submit'
              className='w-full'
              disabled={!isValid}
            >
              Iniciar Sesión
            </Button>
          </div>
          <div className='mt-4 w-full'>
            <Button
              color='secondary'
              variant='outlined'
              className='w-full'
              onClick={() => navigateTo('/create-account', { replace: true })}
            >
              Registrarse
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
