import React from 'react';
import { Box } from '@mui/material';
import { CustomDrawer } from '../drawer/Drawer';
import AuthProvider from './authprovider';
import { useDrawerStore } from '../store/drawer';

export const Layout = ({ children }: { children: JSX.Element }) => {
  const { drawerIsOpen, setDrawerIsOpen } =
  useDrawerStore()

  const toogleDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };

  return (
    <AuthProvider>
      <Box
        className='flex h-full w-full bg-silver'
        sx={{
          height: 'fit-content',
          minHeight: '100%',
        }}
      >
        <div className={`flex ${drawerIsOpen ? 'w-[290px]' : 'w-18'}`}>
        <CustomDrawer open={drawerIsOpen} toogleDrawer={toogleDrawer} />
        </div>
        <Box
          component='main'
          className='flex w-full'
          sx={{
            // flexGrow: 1,
            height: 'fit-content',
            minHeight: '100%',
            paddingTop: '120px',
          }}
        >
          {children}
        </Box>
      </Box>
    </AuthProvider>
  );
};
