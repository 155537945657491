export const getDisplayRole = (role: string) => {
  switch (role) {
    case 'DOCTOR':
      return 'PROFESIONAL';
    case 'ADMIN':
      return 'ADMINISTRADOR';
    case 'SECRETARY':
      return 'ASISTENTE';
    default:
      return 'SUPER ADMINISTRADOR';
  }
};
