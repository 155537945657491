import { Typography } from '@mui/material'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import { useState } from 'react'
import EditableAvatar from '../../components/EditableAvatar'
import CustomModal from '../../components/customModal'
import ModalCross from '../../assets/modal-cross.png'
import { deleteProfilePicture } from '../../services/user'
import { userState } from '../../utils/interfaces/stores'

const AdminInternalLayout = () => {
  const { user, setProfilePicture } = useStore(authStore)
  const [modalDeletePicIsOpen, setDeletePicIsOpen] = useState(false)

  const { lastName, firstName, email, role, profilePictureUrl } =
    user as userState

  const confirmDeleteProfilePicture = async () => {
    const response = await deleteProfilePicture()

    if (response.status === 200) {
      setProfilePicture('')
    }
    setDeletePicIsOpen(false)
  }
  return (
    <div className='border-r-2 w-full h-full flex flex-col items-center justify-start p-6'>
      <div className='relative pr-4 pb-6'>
        <EditableAvatar
          imageUrl={profilePictureUrl}
          altText={`${firstName} ${lastName}`}
          size={170}
        />
      </div>
      {profilePictureUrl && profilePictureUrl.length > 0 && <div className='my-3'>
        <Typography 
          color='error'
          fontWeight='bold'
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setDeletePicIsOpen(true)}
        >Eliminar foto</Typography>
      </div>}
      <div className='flex w-full justify-center'>
        <Typography className='text-pitch-black p-1'>
          {email}
        </Typography>
      </div>
      <div className='flex w-full justify-center'>
        <Typography className='uppercase text-aqua-intense p-1' fontWeight='bold'>
          {role === 'SUPERADMIN' ? 'SUPER ADMINISTRADOR' : 'ADMINISTRADOR'}
        </Typography>
      </div>
      <CustomModal
        openModal={modalDeletePicIsOpen}
        handleClose={() => setDeletePicIsOpen(false)}
        imgSrc={ModalCross}
        title='¿Estás seguro de eliminar tu foto de perfil?'
        description='Una vez eliminada no vas a poder recuperarla'
        onSubmit={() => confirmDeleteProfilePicture()}
        submitLabel="Aceptar"
        showCancelButton
        child={null}
        isSubmitDisable={null}
      />
    </div>
  )
}

export default AdminInternalLayout
