import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '../../components/Layout'
import { GridColDef } from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import AdminDashboardIcons from './adminDashboardIcons'
import {
  enableDoctor,
  getWebDoctors,
  rejectDoctor
} from '../../services/doctor'
import { useAlertStore } from '../../store/alertStore'
import CustomModal from '../../components/customModal'
import ModalCross from '../../assets/modal-cross.png'
import ModalTick from '../../assets/modal-tick.png'
import { TextField, Typography } from '@mui/material'
import { doctorStatuses } from '../../utils/constants'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import NothingToShow from '../../components/nothingToShow'
import CustomSearchBox from '../../components/CustomSearchBox'
import CustomDataGrid from '../../components/CustomDataGrid'

const AdminProfileDashboard = () => {
  const location = useLocation()
  const { state }: { state: any } = location
  const [tabClicked, setTabClicked] = useState<string>(
    (state !== null && state.tabClicked) || doctorStatuses.APPROVAL_PENDING
  )
  const [rows, setRows] = useState<[]>([])
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState<string>('')
  const [doctorId, setDoctorId] = useState<number | null>(null)
  const [REJECTEDMessage, setREJECTEDMessage] = useState<string>('')
  const [licenceNumber, setLicenceNumber] = useState<any>(null)
  const [initialLicence, setInitialLicence] = useState<number | null>(null)

  const [searchText, setSearchText] = useState<string>('')

  const { setAlert } = useAlertStore()

  const handleChange = (str: string) => {
    setREJECTEDMessage(str)
  }

  const webDoctorGetter = async () => {
    const response = await getWebDoctors(tabClicked)
    if (response) {
      setRows(response.items)
    }
  }

  const handleClose = () => {
    setModalOpen('')
    setModalIsOpen(false)
    setLicenceNumber(null)
  }

  useEffect(() => {
    webDoctorGetter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabClicked])

  const enabledHandler = async (id: number) => {
    const resp =
      id !== null && tabClicked === doctorStatuses.REJECTED
        ? await enableDoctor(id, parseInt(licenceNumber))
        : await enableDoctor(id)
    if (resp.error) {
      setAlert({
        isOpen: true,
        message: `No se ha podido aprobar el perfil`,
        severity: 'error'
      })
    } else {
      setAlert({
        isOpen: true,
        message: `Se ha aprobado el perfil con éxito`,
        severity: 'success'
      })
    }
  }

  const REJECTEDHandler = async (id: number) => {
    const resp = id !== null && (await rejectDoctor(id, REJECTEDMessage))
    if (resp.error) {
      setAlert({
        isOpen: true,
        message: `No se ha podido rechazar el perfil`,
        severity: 'error'
      })
    } else {
      setAlert({
        isOpen: true,
        message: `Se ha rechazado el perfil con éxito`,
        severity: 'success'
      })
    }
  }

  const acceptHandler = async (id: number) => {
    modalOpen === doctorStatuses.ENABLED && (await enabledHandler(id))
    modalOpen === doctorStatuses.REJECTED && (await REJECTEDHandler(id))
    webDoctorGetter()
    handleClose()
  }

  const openModalHandler = (
    modalType: string,
    docId: number,
    docLicence: number
  ) => {
    setModalIsOpen(true)
    setModalOpen(modalType)
    setDoctorId(docId)
    setInitialLicence(docLicence)
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Nombre',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <UserAvatar
              imageUrl={
                tabClicked !== doctorStatuses.APPROVAL_PENDING
                  ? cellValues.row.profilePictureUrl
                  : undefined
              }
              size={44}
              altText={'IMG'}
            />
            <div className="pl-8">{cellValues.row.firstName}</div>
          </div>
        )
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'licence',
      headerName: 'Matrícula',
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'icons',
      headerName: '',
      renderCell: (cellValues): any => {
        return (
          <AdminDashboardIcons
            drData={cellValues.row}
            tabClicked={tabClicked}
            onOpenModal={openModalHandler}
            webDoctorGetter={webDoctorGetter}
          />
        )
      },
      flex: 1,
      sortable: false
    }
  ]

  if (tabClicked === doctorStatuses.ENABLED) {
    columns.splice(columns.length - 1, 0, {
      field: 'status',
      headerName: 'Estado',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="w-full flex justify-start">
            {cellValues.row.isActive ? (
              <div className="bg-green  bg-opacity-10 px-4 py-1 rounded-full">
                <Typography className="text-green font-bold">Activo</Typography>
              </div>
            ) : (
              <div className="bg-red  bg-opacity-10 px-4 py-1 rounded-full">
                <Typography className="text-red font-bold">
                  Bloqueado
                </Typography>
              </div>
            )}
          </div>
        )
      },
      flex: 1,
      sortable: false
    })
  }

  const filterRow = (row: any) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())

  return (
    <Layout>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full items-center">
          <div className="flex w-1/5 justify-start pl-2 pr-2">
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight="bold"
            >
              Profesionales
            </Typography>
          </div>
          <div className="flex w-4/5 justify-end pl-2 pr-2">
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div className="flex mx-14 bg-white">
          <div className="flex my-4 ml-4 flex-row bg-soft-grey justify-center items-center p-2 rounded-lg">
            <div
              onClick={() => setTabClicked(doctorStatuses.APPROVAL_PENDING)}
              className={`font-montserrat text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === doctorStatuses.APPROVAL_PENDING && 'bg-aqua-soft'
              }`}
            >
              Pendientes
            </div>
            <div
              onClick={() => setTabClicked(doctorStatuses.ENABLED)}
              className={`font-montserrat  text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === doctorStatuses.ENABLED && 'bg-aqua-soft'
              }`}
            >
              Aprobados
            </div>
            <div
              onClick={() => setTabClicked(doctorStatuses.REJECTED)}
              className={`font-montserrat text-center justify-center items-center text-sm font-bold tracking-wider py-1.5 px-6 cursor-pointer rounded-lg ${
                tabClicked === doctorStatuses.REJECTED && 'bg-aqua-soft'
              }`}
            >
              Rechazados
            </div>
          </div>
        </div>
        <div
          className={`flex mx-14 bg-white ${
            rows.length !== 0 ? 'h-[500px]' : 'pb-12'
          }`}
        >
          {(searchText.length && rows.filter(filterRow).length === 0) ||
          rows.length === 0 ? (
            <NothingToShow
              nothingImg={nothingToShowImg}
              altText="pendiente de aprobación"
              nothingText={'No tenés nada que ver hasta el momento...'}
            />
          ) : 
            <CustomDataGrid
              rows={searchText.length ? rows.filter(filterRow) : rows}
              rowHeight={64}
              columns={columns}
            />
          }
        </div>
        {modalIsOpen && (
          <CustomModal
            openModal={modalIsOpen}
            handleClose={handleClose}
            imgSrc={
              modalOpen !== doctorStatuses.ENABLED ? ModalCross : ModalTick
            }
            title={
              modalOpen !== doctorStatuses.ENABLED
                ? '¿Estás seguro de rechazar este perfil?'
                : '¿Estás seguro de aprobar este perfil?'
            }
            description={
              modalOpen !== doctorStatuses.ENABLED
                ? 'A continuación podés enviarle un mensaje, de manera opcional'
                : 'Una vez aprobado el profesional podrá comenzar a utilizar Medapp'
            }
            onSubmit={() => doctorId && acceptHandler(doctorId)}
            submitLabel="Aceptar"
            showCancelButton
            isSubmitDisable={
              tabClicked === doctorStatuses.REJECTED &&
              licenceNumber !== null &&
              licenceNumber.length < 3
            }
            child={
              modalOpen !== doctorStatuses.ENABLED ? (
                <div className="w-full pb-4">
                  <TextField
                    variant="outlined"
                    multiline
                    rows={3}
                    id="rejMessage"
                    name="rejMessage"
                    placeholder="Mensaje"
                    className="w-full"
                    value={REJECTEDMessage}
                    onChange={(e) => {
                      if (REJECTEDMessage.length < 200) {
                        handleChange(e.target.value)
                      }
                    }}
                  />
                </div>
              ) : modalOpen === doctorStatuses.ENABLED &&
                tabClicked === doctorStatuses.REJECTED ? (
                <>
                  <div className="font-montserrat not-italic font-normal text-sm text-pitch-black text-center pb-4">
                    {`La matrícula ingresada por el profesional es ${initialLicence}. Si
                    deseas cambiarla, escribí la nueva matrícula a continuación:`}
                  </div>
                  <div className="w-full pb-4">
                    <TextField
                      variant="outlined"
                      id="licence"
                      name="licence"
                      type="number"
                      placeholder="Nueva matrícula (opcional)"
                      className="w-full"
                      value={licenceNumber}
                      onChange={(e) => {
                        if (
                          e.target.value !== null &&
                          e.target.value.toString().length < 20
                        ) {
                          setLicenceNumber(e.target.value)
                        }
                      }}
                    />
                  </div>
                </>
              ) : null
            }
          />
        )}
      </div>
    </Layout>
  )
}

export default AdminProfileDashboard
