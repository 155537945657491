import React, { useEffect } from 'react'
import MedappLogo from '../../assets/logo-completo.png'
import { Button, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlertStore } from '../../store/alertStore'
import { recoverPassword } from '../../services/auth'

const PasswordRecovery = () => {
  const navigate = useNavigate()
  const { setAlert } = useAlertStore()

  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'El email no tiene un formato válido')
      .max(60, 'El email es demasiada largo')
      .required('Requerido'),
  })

  const submitToRecoveryPass = async (email: string) => {
    const response: any = await recoverPassword(values.email)
    if (response.status !== 200) {
      setAlert({
        isOpen: true,
        message: 'El email ingresado no es válido',
        severity: 'error',
      })
      return
    }
    if (response.status === 200) {
      navigate('/verify-email', {
        replace: true,
        state: {
          email: values.email,
        },
      })
    }
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      submitToRecoveryPass(values.email)
    },
  })

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:px-12 px-2 md:py-6 mx-4 md:w-2/5 max-w-lg rounded-xl'>
        <img
          src={MedappLogo}
          alt='medapp login logo'
          className='login-logo mb-8'
        ></img>
        <div className='flex justify-start w-full mb-4'>
          <Typography fontSize={18} fontWeight='bold'>
            Recuperar contraseña
          </Typography>
        </div>
        <div className='flex justify-start w-full mb-4'>
          <Typography fontSize={14}>
            Escribí el email con el que te registraste y te enviaremos las
            instrucciones para recuperar tu contraseña
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col w-full items-center mt-8'
        >
          <div className='w-full'>
            <TextField
              label='Email'
              variant='outlined'
              id='email'
              type='text'
              size='small'
              className='w-full white-background'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete='off'
              error={!!errors.email && touched.email}
              helperText={
                !!errors.email && touched.email !== undefined && errors.email
              }
            />
          </div>

          <div className='md:mb-0 mb-4 mt-12 w-full'>
            <Button
              color='secondary'
              variant='contained'
              className='w-full'
              type='submit'
              disabled={!isValid}
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PasswordRecovery
