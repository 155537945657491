import { Typography } from '@mui/material'
import React from 'react'
import NoNotificationsImg from '../../assets/no-notifications.png'

interface NoNotificationsProps {
  navigationFunction?: () => void
  imgClasses: string
  fontSize: number
  containerClasses?: string
}

const NoNotifications = (props: NoNotificationsProps) => {
  const { navigationFunction, imgClasses, fontSize, containerClasses } = props

  return (
    <div className={`flex flex-col ${containerClasses && containerClasses}`} onClick={navigationFunction && navigationFunction}>
      <img
        className={imgClasses}
        src={NoNotificationsImg}
        alt='no-notifications'
      ></img>
      <Typography
        className={`text-light-grey tracking-wider pt-4`}
        fontSize={fontSize}
        fontWeight='bold'
      >
        Todavía no tenés ninguna notificación
      </Typography>
    </div>
  )
}

export default NoNotifications
