import { useState } from 'react'
import { Track as TrackType, TrackPublication } from 'twilio-video'
import { Track } from './Track'
import { ParticipanProps } from '../../utils/interfaces/props'
import UserAvatar from '../../components/UserAvatar'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'
import { userState } from '../../utils/interfaces/stores'

export const LocalParticipant = (props: ParticipanProps) => {
  const { participant, cameraEnabled } = props
  const { user } = useStore(authStore)
  const { profilePictureUrl } = user as userState

  const existingPublications: TrackPublication[] = Array.from(
    participant.tracks.values()
  )
  const existingTracks = existingPublications.map((publication: any) => {
    return publication.track
  })
  const nonNullTracks = existingTracks.filter((track) => track !== null)
  const [tracks] = useState<TrackType[]>(nonNullTracks)

  return (
    <div
      className={`absolute w-[200px] h-[200px] overflow-hidden rounded-md right-12 bottom-12 ${
        !cameraEnabled ? 'bg-light-grey' : ''
      }`}
      id={participant.identity}
    >
      {tracks.map((track) => {
        if (track.kind === 'video' && !cameraEnabled) {
          return (
            <div className="flex w-full h-full items-center justify-center">
              <UserAvatar
                imageUrl={profilePictureUrl}
                altText={'doctor'}
                size={130}
              />
            </div>
          )
        }
        return <Track key={track.name} track={track} />
      })}
    </div>
  )
}
