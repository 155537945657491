import axios from 'axios';
import { AxiosResponse } from 'axios';
import { http } from '../utils/http';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/web/login`, {
      email,
      password,
    });
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const createDoctorAccount = async (data: Record<string, any>) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/doctor/register`, {
      ...data,
    });
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const createSecretaryAccount = async (data: Record<string, any>) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/secretary/register`,
      {
        ...data,
      },
    );
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const verifyEmail = async (
  verificationCode: string,
  verificationToken: string,
) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/verify-email`,
      {
        verificationCode,
      },
      {
        headers: {
          Authorization: `Bearer ${verificationToken}`,
        },
      },
    );
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const sendNewCodeVerifyEmail = async (verificationToken: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/verification-code/renew`,
      {},
      {
        headers: {
          Authorization: `Bearer ${verificationToken}`,
        },
      },
    );
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post('/api/auth/password/update', {
      oldPassword,
      newPassword,
      confirmPassword,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const recoverNewPassword = async (
  password: string,
  verificationPassword: string,
  recoveryToken: string | null,
) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/password/recovery/new-password`,
      {
        password,
        verificationPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${recoveryToken}`,
        },
      },
    );
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const recoverPassword = async (email: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/password/recover`, {
      email,
    });
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const recoverCheckCode = async (email: string, recoveryCode: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/auth/password/recovery/check`,
      {
        email,
        recoveryCode,
      },
    );
    return response;
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const logout = async () => {
  try {
    const response = await http.post(`${API_URL}/api/auth/logout`);
    return response;
  } catch (error) {
    return {
      error,
    };
  }
};
