import { DataGrid, esES, DataGridProps } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useDrawerStore } from '../../store/drawer'

const CustomDataGrid = (props: DataGridProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { drawerIsOpen } = useDrawerStore()

  useEffect(() => {
    if (isOpen !== drawerIsOpen) {
      setIsOpen(drawerIsOpen)
    }
  }, [drawerIsOpen, isOpen])

  // When isOpen is different to drawerIsOpen we need to re render the table to fit on the full screen
  return isOpen === drawerIsOpen ? <DataGrid
            autoPageSize
            localeText={
              esES.components.MuiDataGrid.defaultProps.localeText
            }
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            {...props}
          /> : null
}

export default CustomDataGrid