import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import VideocamIcon from '@mui/icons-material/Videocam';
import HomeIcon from '@mui/icons-material/Home';
import { useEffect } from 'react';
import { parseAvailabilityType } from '../utils';
import SelectBox from './SelectBox';
import { useAlertStore } from '../../../store/alertStore';
import { editAvailability } from '../../../services/availability';
import { useDoctorStore } from '../../../store/doctor';
import { useCalendarStore } from '../../../store/calendarStore';
import { AvailabilitySlotType } from '../../../utils/constants';
import FormatedDateBox from './FormatedDateBox';
import { EditAvailabilityModalProps } from '../../../utils/interfaces/props';
import { authStore } from '../../../store/auth';
import { useStore } from 'zustand';

type InitialValuesTypes = {
  remote: boolean;
  onSite: boolean;
};

const EditAvailabilityModal = (props: EditAvailabilityModalProps) => {
  const {
    open,
    handleClose,
    selected,
    availabilities,
    resetSelected,
    selectedType,
  } = props;
  const { updateWeeklyData } = useCalendarStore();
  const { availability } = useDoctorStore();
  const { setAlert } = useAlertStore();
  const { doctor: secretaryAsDoctor } = useStore(authStore);

  const initialValues: InitialValuesTypes = {
    remote: (availability.videocallAppointments ||
      secretaryAsDoctor?.videocallAppointments)!,
    onSite:
      !availability.videocallAppointments &&
      !secretaryAsDoctor?.videocallAppointments
        ? true
        : false,
  };

  const validationSchema = Yup.object({
    remote: Yup.boolean(),
    onSite: Yup.boolean(),
  });

  const { handleSubmit, values, isValid, validateForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values, { resetForm }) => {
        const availabilitySlotType = parseAvailabilityType(
          values.remote,
          values.onSite,
        );

        const availabilitySlotsIds = selected.map((a: any) => parseInt(a));

        const response = await editAvailability(
          {
            availabilitySlotsIds,
            availabilitySlotType,
          },
          secretaryAsDoctor?.id,
        );

        if (response.status !== 200) {
          setAlert({
            isOpen: true,
            message: 'Error al editar una nueva disponibilidad',
            severity: 'error',
          });
          return;
        }
        if (response.status === 200) {
          setAlert({
            isOpen: true,
            message: 'Se editó éxitosamente la disponibilidad seleccionada',
            severity: 'success',
          });
          updateWeeklyData(response.data);
          resetForm();
          resetSelected();
          handleClose();
        }
      },
    });

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    switch (selectedType) {
      case AvailabilitySlotType.videocall:
        setFieldValue('remote', true);
        setFieldValue('onSite', false);
        break;
      case AvailabilitySlotType.presential:
        setFieldValue('onSite', true);
        setFieldValue('remote', false);
        break;
      case AvailabilitySlotType.presentialVideocall:
        setFieldValue('onSite', true);
        setFieldValue('remote', true);
        break;
      default:
        break;
    }
  }, [selectedType, setFieldValue]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className='flex items-center justify-center'
    >
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col w-[600px] h-auto bg-white rounded'>
          <div className='flex flex-row w-full items-start justify-end'>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className='flex flex-col px-6'>
            <div className='font-montserrat text-xl font-bold leading-24 tracking-wider'>
              Editar disponibilidad
            </div>
            <div className='mt-4 font-montserrat text-[13px] font-normal leading-16 tracking-wider'>
              Seleccioná que tipo de atención vas a brindar. Podés seleccionar
              más de una.
            </div>
            <div className=' mt-4 mb-2'>
              <Box className='flex flex-row w-[300px] rounded-lg justify-between space-x-3'>
                <SelectBox
                  selected={values.remote}
                  icon={<VideocamIcon />}
                  title={'Videollamada'}
                  id='remote'
                  onClick={() => setFieldValue('remote', !values.remote)}
                  disabled={
                    !availability.videocallAppointments &&
                    !secretaryAsDoctor?.videocallAppointments
                  }
                />
                <SelectBox
                  selected={values.onSite}
                  icon={<HomeIcon />}
                  title={'Presencial'}
                  id='onSite'
                  onClick={() => setFieldValue('onSite', !values.onSite)}
                  disabled={
                    !availability.presentialAppointments &&
                    !secretaryAsDoctor?.presentialAppointments
                  }
                />
              </Box>
              {!values.onSite && !values.remote && (
                <div className='mt-2 font-montserrat text-red-600 text-[12px] font-normal leading-16 tracking-wider'>
                  Seleccioná al menos un tipo de atención.
                </div>
              )}
            </div>

            {selected.map((a) => {
              const selectedAvaliability = availabilities.find(
                (availability) => availability.id === a,
              );

              if (selectedAvaliability) {
                const { startDate, endDate, id } = selectedAvaliability;
                return (
                  <FormatedDateBox
                    key={id}
                    startDate={startDate}
                    endDate={endDate}
                  />
                );
              }
              return <></>;
            })}

            <div className='flex w-full h-full justify-end content-end mt-12 mb-6'>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                disabled={!isValid || (!values.onSite && !values.remote)}
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditAvailabilityModal;
