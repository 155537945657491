import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { InputData, InputDataState } from '../utils/interfaces/stores'

export const useInputDataStore = create<InputDataState>()(
  devtools((set) => ({
    specialties: [],
    setSpecialties: (data: InputData[]) => set(() => ({ specialties: data })),
    healthCoverages: [],
    setHealthCoverages: (data: InputData[]) =>
      set(() => ({ healthCoverages: data })),
    provinces: [],
    setProvinces: (data: InputData[]) => set(() => ({ provinces: data })),
    cities: [],
    setCities: (data: InputData[]) => set(() => ({ cities: data }))
  }))
)
