import { Box, Button } from '@mui/material'
import { ShortcutProps } from '../../../../utils/interfaces/props'

const Shortcut = (props: ShortcutProps) => {
  const { title, text, action, image } = props
  return (
    <Box className="w-[382px] h-[543px] bg-white px-4 mx-3 rounded-xl ">
      <p className="font-montserrat font-bold text-xl leading-6 my-8 ml-2">
        {title}
      </p>
      <div className="flex justify-center">
        <img src={image} alt={title}></img>
      </div>
      <p className="font-montserrat leading-6 text-center my-6">{text}</p>
      <div
        className={
          title === 'Turnos' ? 'mt-[40px] px-8 w-full' : 'mt-16 px-8 w-full'
        }
      >
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          className="w-full"
          onClick={action}
        >
          <span style={{textTransform: 'none'}}>Ir a {title}</span>
        </Button>
      </div>
    </Box>
  )
}

export default Shortcut
