import React, { useEffect, useState } from 'react'

const TermsAndConditionsMobile = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    fetch('/patient/terms-and-conditions.html')
      .then((response) => response.text())
      .then((html) => {
        setHtmlContent(html)
      })
  }, [])

  return (
    <div className='p-8' dangerouslySetInnerHTML={{ __html: htmlContent }} />
  )
}

export default TermsAndConditionsMobile
