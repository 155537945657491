import { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Modal
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlertStore } from '../../store/alertStore'
import { AxiosResponse } from 'axios'
import { createNewAdmin } from '../../services/administrators'
import { DefaultModalProps } from '../../utils/interfaces/props'
import { PasswordFormValues } from '../../utils/constants'

export const NewAdminModal = (props: DefaultModalProps) => {
  const { open, handleClose } = props
  const { setAlert } = useAlertStore()
  const [currentPasswordIsHidden, setCurrentPasswordIsHidden] =
    useState<boolean>(true)

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&Ññ]{8,16}$/

  const validationSchema: Yup.Schema<PasswordFormValues> = Yup.object().shape({
    firstName: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    email: Yup.string()
      .matches(/^\S+@\S+\.\S+$/, 'El email no tiene un formato válido')
      .max(60, 'El email es demasiada largo')
      .required('El email es requerido'),
    password: Yup.string()
      .matches(
        passwordRegex,
        'La contraseña no respeta el formato o la extensión necesaria'
      )
      .required('La contraseña actual es requerida'),
  })

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const {
        firstName,
        lastName,
        email,
        password,
      } = values

      const { status } = await createNewAdmin({firstName, lastName, email, password}) as AxiosResponse

      if (status !== 201) {
        setAlert({
          isOpen: true,
          message:
            'Ha ocurrido un error, por favor verifique los datos ingresados o intente más tarde',
          severity: 'error'
        })
        return
      }

      if (status === 201) {
        setAlert({
          isOpen: true,
          message: '¡Nuevo administrador creado con éxito!',
          severity: 'success'
        })
      }
      clearFormValues()
      handleClose()
    }
  })

  const clearFormValues = () => {
    setFieldValue('firstName', '')
    setFieldValue('lastName', '')
    setFieldValue('email', '')
    setFieldValue('password', '')
  }

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box className="bg-white rounded-xl p-4 w-[600px] top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] absolute flex flex-col w-full">
          <div className='flex justify-center mt-4 mb-4'>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Nuevo administrador
            </Typography>
          </div>
          <div className="flex flex-col justify-center px-6 space-y-4 my-6">
            <div className='flex flex-row w-full'>
              <div className='flex w-full mr-2'>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  className="w-full white-background"
                  autoComplete="off"
                  type={'text'}
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    !!errors.firstName &&
                    touched.firstName !== undefined
                  }
                  helperText={
                    !!errors.firstName &&
                    touched.firstName !== undefined &&
                    errors.firstName
                  }
                />
              </div>
              <div className='flex w-full ml-2'>
                <TextField
                  label="Apellido"
                  variant="outlined"
                  className="w-full white-background"
                  autoComplete="off"
                  type={'text'}
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    !!errors.lastName &&
                    touched.lastName !== undefined
                  }
                  helperText={
                    !!errors.lastName &&
                    touched.lastName !== undefined &&
                    errors.lastName
                  }
                />
              </div>
            </div>
            <TextField
              label='Email'
              variant='outlined'
              id='email'
              className='w-full white-background'
              autoComplete="off"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email && touched.email}
              helperText={
                !!errors.email && touched.email !== undefined && errors.email
              }
            />
            <TextField
              label="Contraseña"
              variant="outlined"
              className="w-full white-background"
              autoComplete="off"
              type={currentPasswordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {currentPasswordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setCurrentPasswordIsHidden((prev) => !prev)
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setCurrentPasswordIsHidden((prev) => !prev)
                        }
                      />
                    )}
                  </InputAdornment>
                )
              }}
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.password &&
                touched.password !== undefined
              }
              helperText={
                !!errors.password &&
                touched.password !== undefined &&
                errors.password
              }
            />
          </div>

          <div className="flex w-full flex-row justify-center text-center space-x-8 px-6 mt-6">
            <Button
              className="w-full"
              onClick={() => {
                clearFormValues()
                handleClose()
              }}
              variant="text"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              className="w-full"
              disabled={!isValid}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Guardar
            </Button>
          </div>
        </Box>
      </form>
    </Modal>
  )
}
