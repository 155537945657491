import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import UserAvatar from '../../components/UserAvatar';
import { OptionsMessage } from '../medicalProfile/medicalProfileScreen';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TextField } from '@mui/material';
import ArrowGoPreviousPage from '../../components/arrowGoPreviousPage';
import { getDoctorProfile } from '../../services/doctor';
import { DoctorProfile } from '../../utils/constants';

const AdminMedicalDetailsScreen = () => {
  const [keyboardArrowIconSense, setKeyboardArrowIconSense] = useState(true);
  const [doctorProfile, setDoctorProfile] = useState<DoctorProfile | null>(
    null,
  );
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    const getProfile = async () => {
      try {
        const resp = await getDoctorProfile(state.drData.id);
        setDoctorProfile(resp);
      } catch (error) {
        console.error('Error getDoctorProfile:', error);
      }
    };

    getProfile();
  }, [state.drData.id]);

  const commonTextFieldLabelColor = {
    '& .MuiInputLabel-root': {
      color: '#D3D3D3',
    },
  };

  if (!state || !doctorProfile) {
    return (
      <Layout>
        <></>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col w-full justify-start pl-2 pr-2'>
          <div className='ml-12'>
            <ArrowGoPreviousPage
              path={'/admin/doctors'}
              title={'Volver a médicos'}
              state={{
                tabClicked: state.tabClicked,
              }}
            />
          </div>
          <div
            className={`font-montserrat pt-6 pb-12 pl-12 text-pitch-black font-bold text-2xl tracking-wider`}
          >
            Médicos
          </div>
          <div className='flex flex-col w-full h-full px-12'>
            <div className='flex flex-row w-full pt-6 bg-white'>
              <div className='w-1/5 h-full flex flex-col items-center justify-start p-6'>
                <div className='pb-6'>
                  <UserAvatar
                    imageUrl={doctorProfile.profilePictureUrl}
                    altText={doctorProfile.lastName}
                    size={170}
                  />
                </div>
                <div className='font-montserrat font-normal text-xl text-pitch-black p-1'>
                  {doctorProfile.firstName + ' ' + doctorProfile.lastName}
                </div>
                <div className='font-montserrat font-bold text-xs text-aqua-intense p-1'>
                  MATRÍCULA
                  <span className='font-montserrat font-bold text-xs uppercase text-aqua-intense p-1'>
                    {doctorProfile.licence}
                  </span>
                </div>
              </div>
              <div className='w-3/4 flex flex-col pl-6'>
                <div className='w-full font-montserrat pt-10 text-pitch-black font-bold text-lg tracking-wider pb-4 pl-2'>
                  Perfil médico
                </div>
                <div className='flex flex-row w-full'>
                  <div className='w-1/2 flex flex-col border-b border-solid p-2 mr-6'>
                    <p className='font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Cobertura medica
                    </p>
                    <div className='flex flex-row flex-wrap'>
                      {doctorProfile.healthCoverages?.length > 0 ? (
                        doctorProfile.healthCoverages.map((h, index) => (
                          <p
                            key={h.title + index}
                            className='font-montserrat font-normal text-pitch-black text-xs bg-yellow-light p-2 rounded-xl mr-1'
                          >{`${h.title}`}</p>
                        ))
                      ) : (
                        <OptionsMessage message='-' />
                      )}
                    </div>
                  </div>
                  <div className='w-1/2 flex flex-col border-b border-solid py-2'>
                    <p className='text-start font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Especialidad
                    </p>
                    <div className='flex flex-wrap'>
                      {doctorProfile.specialties?.length > 0 ? (
                        doctorProfile.specialties.map((s, index) => (
                          <p
                            key={s.title + index}
                            className='font-montserrat font-normal text-pitch-black text-xs bg-yellow-light p-2 rounded-xl mr-1'
                          >{`${s.title}`}</p>
                        ))
                      ) : (
                        <OptionsMessage message='-' />
                      )}
                    </div>
                  </div>
                </div>

                <div className='flex flex-row w-full'>
                  <div className='w-1/2 flex flex-col  p-2'>
                    <p className='text-start font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Trayectoria
                    </p>
                    <OptionsMessage
                      message={doctorProfile.trajectory || ' - '}
                    />
                  </div>

                  <div className='w-1/2 flex flex-col p-2'>
                    <p className='text-start font-montserrat font-normal text-lighter-grey text-xs pt-3 pb-3'>
                      Teléfono
                    </p>
                    <OptionsMessage message={doctorProfile.phone || ' - '} />
                  </div>
                </div>

                <div className='flex flex-col pt-6'>
                  <div className='w-full font-montserrat p-10 pl-2 text-pitch-black font-bold text-lg tracking-wider pb-8'>
                    Disponibilidad
                  </div>
                  <div className='flex flex-col'>
                    <div className='flex flex-row'>
                      {doctorProfile.videocallAppointments ? (
                        <DoneIcon fontSize='small' className='p-1' />
                      ) : (
                        <CloseIcon fontSize='small' className='p-1' />
                      )}
                      <p className='font-montserrat pl-2 pb-8'>
                        Consultas por videollamada
                      </p>
                    </div>

                    <div className='flex flex-row'>
                      {doctorProfile.homeAppointments ? (
                        <DoneIcon fontSize='small' className='p-1' />
                      ) : (
                        <CloseIcon fontSize='small' className='p-1' />
                      )}
                      <p className='font-montserrat pl-2 pb-8'>
                        Consultas a domicilio
                      </p>
                    </div>

                    <div className='flex flex-row items-start'>
                      {doctorProfile.presentialAppointments ? (
                        <DoneIcon fontSize='small' className='p-1' />
                      ) : (
                        <CloseIcon fontSize='small' className='p-1' />
                      )}
                      <div className='font-montserrat pl-2 pr-2 pb-8'>
                        Consultas presenciales
                      </div>
                      <button
                        onClick={() =>
                          setKeyboardArrowIconSense(!keyboardArrowIconSense)
                        }
                      >
                        {!keyboardArrowIconSense ? (
                          <KeyboardArrowDownIcon fontSize='small' />
                        ) : (
                          <KeyboardArrowUpIcon fontSize='small' />
                        )}
                      </button>
                    </div>
                    {!keyboardArrowIconSense && doctorProfile.address && (
                      <div className='w-full flex flex-wrap'>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Calle'
                            variant='standard'
                            className='w-full'
                            value={doctorProfile.address.street}
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Altura'
                            variant='standard'
                            className='w-full'
                            value={doctorProfile.address.streetNumber}
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Piso/depto'
                            variant='standard'
                            className='w-full'
                            value={doctorProfile.address.floorApartment}
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Provincia'
                            variant='standard'
                            className='w-full'
                            value={doctorProfile.address.province?.title}
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                        <div className='w-96 pr-6 pb-4'>
                          <TextField
                            label='Ciudad'
                            variant='standard'
                            className='w-full'
                            value={doctorProfile.address.city?.title}
                            sx={commonTextFieldLabelColor}
                            inputProps={{ readOnly: true }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminMedicalDetailsScreen;
