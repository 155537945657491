import { createStore } from 'zustand/vanilla';
import { devtools, persist } from 'zustand/middleware';
import { AuthState, doctorState, userState } from '../utils/interfaces/stores';

const initAuthState: AuthState = {
  user: null,
  doctor: null,
  accessToken: null,
  refreshToken: null,
  verifyToken: null,
  recoveryToken: null,
  setUser: Function,
  setDoctor: Function,
  setLogout: Function,
  setAccessToken: Function,
  setRefreshToken: Function,
  setVerifyToken: Function,
  setRecoveryToken: Function,
  setProfilePicture: Function,
};

export const authStore = createStore<AuthState>()(
  devtools(
    persist(
      (set) => ({
        ...initAuthState,
        setUser: (u: userState) => set(() => ({ user: u })),
        setProfilePicture: (url: string) =>
          set((state: AuthState) => ({
            user: { ...state.user!, profilePictureUrl: url },
          })),
        setDoctor: (doctor: doctorState) => set(() => ({ doctor: doctor })),
        setLogout: () =>
          set(() => ({
            user: null,
            doctor: null,
            accessToken: null,
            refreshToken: null,
            verifyToken: null,
            recoveryToken: null,
          })),
        setAccessToken: (accessToken: string) =>
          set(() => ({ accessToken: accessToken })),
        setRefreshToken: (refreshToken: string) =>
          set(() => ({ refreshToken: refreshToken })),
        setVerifyToken: (verifyToken: string) =>
          set(() => ({ verifyToken: verifyToken })),
        setRecoveryToken: (recoveryToken: string) =>
          set(() => ({ recoveryToken: recoveryToken })),
      }),
      { name: 'AuthStoreVanilla' },
    ),
  ),
);
