import { TextField } from '@mui/material'
import CustomModal from '../../../components/customModal'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormEvent, useEffect } from 'react'
import { editNote, newNote } from '../../../services/rapports'
import { useRapportsStore } from '../../../store/rapportsStore'
import { useAlertStore } from '../../../store/alertStore'

const NoteModal = ({
  openModal,
  setOpenModal,
  previousNote,
  setPreviousNote,
  rapportId
}: {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  previousNote: { note: string; date: Date | null; id?: number }
  setPreviousNote: (data: { note: string; date: Date | null }) => void
  rapportId: number
}) => {
  const { setNotes } = useRapportsStore()
  const { setAlert } = useAlertStore()
  const initialValues = {
    note: ''
  }

  const validationSchema = yup.object({
    note: yup
      .string()
      .max(700, 'El texto es demasiado largo')
      .required('Ingrese una nota')
  })

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    resetForm,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (previousNote.id) {
        const request = await editNote(rapportId, previousNote.id, values.note)
        const { response } = request

        if (response && response.status === 400) {
          setAlert({
            isOpen: true,
            message: response.data.message,
            severity: 'error'
          })
          handleCloseModal()
        }

        if (request.status === 200) {
          setAlert({
            isOpen: true,
            message: 'Se editó exitosamente la nota',
            severity: 'success'
          })
          setNotes(request.data)
          handleCloseModal()
        }
      } else {
        const response = await newNote(rapportId, values.note)

        if (response.status === 201) {
          setAlert({
            isOpen: true,
            message: 'Se creó exitosamente la nota',
            severity: 'success'
          })
          setNotes(response.data)
          handleCloseModal()
        } else {
          setAlert({
            isOpen: true,
            message: response.data.message,
            severity: 'error'
          })
          handleCloseModal()
        }
      }
    }
  })

  useEffect(() => {
    setFieldValue('note', previousNote?.note)
  }, [previousNote, setFieldValue])

  const parseTitle = (): string => {
    if (previousNote.date) {
      const { date } = previousNote
      return `Editar nota - ${format(date, 'dd/MM/yy')}`
    } else {
      return `Nueva nota - ${format(new Date(), 'dd/MM/yy')}`
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    const data = { note: '', date: null }
    setPreviousNote(data)
    resetForm()
  }

  return (
    <CustomModal
      openModal={openModal}
      title={parseTitle()}
      description=""
      submitLabel="Guardar"
      onSubmit={(e: FormEvent<HTMLFormElement> | undefined) => handleSubmit(e)}
      showCancelButton
      handleClose={() => handleCloseModal()}
      imgSrc={null}
      child={
        <div className="w-full pb-4">
          <TextField
            variant="outlined"
            multiline
            rows={6}
            id="note"
            className="w-full"
            value={values.note}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.note && touched.note}
            helperText={
              !!errors.note && touched.note !== undefined && errors.note
            }
          />
        </div>
      }
      isSubmitDisable={null}
    />
  )
}

export default NoteModal
