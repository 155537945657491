import { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Modal
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { updatePassword } from '../../services/auth'
import { useAlertStore } from '../../store/alertStore'
import { AxiosResponse } from 'axios'
import { DefaultModalProps } from '../../utils/interfaces/props'

export const ChangePassModal = (props: DefaultModalProps) => {
  const { open, handleClose } = props
  const { setAlert } = useAlertStore()
  const [currentPasswordIsHidden, setCurrentPasswordIsHidden] =
    useState<boolean>(true)
  const [newPasswordIsHidden, setNewPasswordIsHidden] = useState<boolean>(true)
  const [repeatPasswordIsHidden, setRepeatPasswordIsHidden] =
    useState<boolean>(true)

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&Ññ]{8,16}$/

  interface PasswordFormValues {
    currentPassword: string
    newPassword: string
    repeatPassword: string
  }

  const validationSchema: Yup.Schema<PasswordFormValues> = Yup.object().shape({
    currentPassword: Yup.string()
      .matches(
        passwordRegex,
        'La contraseña no respeta el formato o la extensión necesaria'
      )
      .required('La contraseña actual es requerida'),

    newPassword: Yup.string()
      .matches(
        passwordRegex,
        'La contraseña no respeta el formato o la extensión necesaria'
      )
      .required('La nueva contraseña es requerida'),

    repeatPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Las contraseñas deben coincidir')
      .required('La confirmación de la nueva contraseña es requerida')
  })

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: ''
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { newPassword, currentPassword, repeatPassword } = values
      const { status } = await updatePassword(
        currentPassword,
        newPassword,
        repeatPassword
      ) as AxiosResponse

      if (status !== 200) {
        setAlert({
          isOpen: true,
          message:
            'Error en la actualización, por favor verifique los datos ingresados',
          severity: 'error'
        })
        return
      }

      if (status === 200) {
        setAlert({
          isOpen: true,
          message: 'Se actualizó la contraseña',
          severity: 'success'
        })
        handleClose()
      }
    }
  })

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      slotProps={{
        backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box className="bg-white rounded-xl p-4 w-[600px] top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] absolute">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cambiar contraseña
          </Typography>
          <div className="flex flex-col justify-center px-6 space-y-4 my-6">
            <TextField
              label="Contraseña actual"
              variant="outlined"
              className="w-full white-background"
              autoComplete="off"
              type={currentPasswordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {repeatPasswordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setCurrentPasswordIsHidden((prev) => !prev)
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setCurrentPasswordIsHidden((prev) => !prev)
                        }
                      />
                    )}
                  </InputAdornment>
                )
              }}
              id="currentPassword"
              name="currentPassword"
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.currentPassword &&
                touched.currentPassword !== undefined
              }
              helperText={
                !!errors.currentPassword &&
                touched.currentPassword !== undefined &&
                errors.currentPassword
              }
            />

            <TextField
              label="Nueva contraseña"
              variant="outlined"
              autoComplete="off"
              className="w-full white-background"
              type={newPasswordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {newPasswordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() => setNewPasswordIsHidden((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() => setNewPasswordIsHidden((prev) => !prev)}
                      />
                    )}
                  </InputAdornment>
                )
              }}
              id="newPassword"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.newPassword && touched.newPassword !== undefined}
              helperText={
                !!errors.newPassword &&
                touched.newPassword !== undefined &&
                errors.newPassword
              }
            />
            <TextField
              label="Repetir contraseña"
              variant="outlined"
              className="w-full white-background"
              autoComplete="off"
              type={repeatPasswordIsHidden ? 'password' : 'text'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {repeatPasswordIsHidden ? (
                      <VisibilityIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setRepeatPasswordIsHidden((prev) => !prev)
                        }
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={{ cursor: 'pointer' }}
                        color="disabled"
                        onClick={() =>
                          setRepeatPasswordIsHidden((prev) => !prev)
                        }
                      />
                    )}
                  </InputAdornment>
                )
              }}
              id="repeatPassword"
              name="repeatPassword"
              value={values.repeatPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.repeatPassword && touched.repeatPassword !== undefined
              }
              helperText={
                !!errors.repeatPassword &&
                touched.repeatPassword !== undefined &&
                errors.repeatPassword
              }
            />
          </div>

          <div className="flex w-full flex-row justify-center text-center space-x-8 px-6">
            <Button
              className="w-full"
              onClick={handleClose}
              variant="text"
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              className="w-full"
              disabled={!isValid}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Guardar
            </Button>
          </div>
        </Box>
      </form>
    </Modal>
  )
}
