const ElectronicPrescription = () => {
  return (
    <div className='flex flex-row h-full w-full pt-28 pl-10'>
      <div className='flex flex-col w-4/5 h-full space-y-4'>
        <div className='font-montserrat font-normal text-xl tracking-wider text-pitch-black'>
          En MedApp usamos RCTA
        </div>
        <p className='font-montserrat text-base leading-5 tracking-normal text-left'>
          Te permite:
        </p>
        <ul>
          <li>- Prescribir en 3 clicks desde tu teléfono</li>
          <li>
            - Guardar los datos de todos los pacientes con sus coberturas
            médicas.
          </li>
          <li>
            - Poder guardar las recetas y repetirlas las veces que desees.
          </li>
          <li>
            - Si hubiera errores, poder corregir las recetas en el momento, sin
            la necesidad de repetirla.
          </li>
          <li>
            - Poder grabar indicaciones médicas para cada medicamento recetado y
            repetirlas las veces necesarias.
          </li>
          <li>
            - Generar una receta con firma y sello digitalizado y personalizado.
          </li>
          <li>
            - Prescribir con un vademécum de libre prescripción con todas las
            interacciones medicamentosas asociadas.
          </li>
          <li>- Realizar recetas posdatas.</li>
        </ul>
        <p className='font-montserrat text-base leading-5 tracking-normal text-left'>
          Descargá la App desde el siguiente link:{' '}
          <a href='https://rcta.me' className='text-aqua-intense'>
            https://rcta.me
          </a>
        </p>
      </div>
    </div>
  );
};

export default ElectronicPrescription;
