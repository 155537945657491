import axios from 'axios'
import { http } from '../utils/http'

export const connectMercadoPagoApi = async () => {
  try {
    const response = await http.get(
      `/api/mercado-pago/auth-url`
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const deleteMercadoPagoApi = async () => {
  try {
    const response = await http.delete(
      `/api/mercado-pago/delete-user`
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const callback = async (code: string, state: string) => {
  try {
    const response = await axios.get(`/api/mercado-pago/create-user`, {
      params: {
        code,
        state
      },
      baseURL: process.env.REACT_APP_API_URL,
    })
    return response
  } catch (error) {
    return error
  }
}