import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { CustomSearchBoxProps } from '../../utils/interfaces/props'

const CustomSearchBox = ({ value, onChange}: CustomSearchBoxProps) => {
  return (
    <div className='flex w-96 pr-12'>
      <TextField
        label='Buscar por nombre'
        id='searchbox'
        name='searchbox'
        type='text'
        variant='outlined'
        autoComplete='off'
        className='w-full'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlinedIcon />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

export default CustomSearchBox
