import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { CustomAutocompleteProps } from '../utils/interfaces/props';
import { Checkbox, MenuItem, useTheme, Chip } from '@mui/material';
import { Options } from './CustomSelect';

interface SelectionChipProps {
  label: string;
  onDelete: () => void;
}

export const SelectionChip = (props: SelectionChipProps) => (
  <Chip
    label={props.label}
    onDelete={props.onDelete}
    color='primary'
    variant='filled'
    sx={{ backgroundColor: '#F3F5D9' }}
    size='small'
  />
);

function getStyles(name: string, selectValues: string[], theme: any) {
  return {
    fontWeight:
      selectValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CustomAutocomplete = (props: CustomAutocompleteProps) => {
  const {
    multiple,
    value,
    className,
    label,
    hideSelections,
    getOptionLabel,
    filterOptions,
  } = props;
  const theme = useTheme();

  return (
    <Stack spacing={3} flex={1}>
      <Autocomplete
        disableClearable
        className={className ? className : 'w-full'}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        {...(filterOptions ? { filterOptions: filterOptions } : {})}
        getOptionLabel={
          getOptionLabel ? getOptionLabel : (option: Options) => option.title
        }
        sx={{
          '& .MuiChip-root': {
            backgroundColor: '#F3F5D9',
          },
          '& .MuiInputBase-input': {
            minWidth: '30px !important',
          },
        }}
        renderOption={(props, option: Options, { selected }) => (
          <MenuItem
            {...props}
            key={option.id as number}
            value={option.id}
            style={getStyles(
              option.title,
              multiple ? (value as string[]) : ([value] as string[]),
              theme,
            )}
          >
            <Checkbox checked={selected} />
            <span className='text-pitch-black'>{option.title}</span>
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={props.message}
            label={label}
            error={props.error}
          />
        )}
        renderTags={hideSelections ? (value, getTagProps) => '' : undefined}
        {...(props as any)}
        error={props.message || ''}
      />
    </Stack>
  );
};
