import React, { useMemo } from 'react'
// @mui
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
//
import palette from './palette'
import typography from './typography'
import componentsOverride from './overrides'
import GlobalStyles from './globalStyles'

// ----------------------------------------------------------------------

interface Props {
  children: React.ReactNode
};

export default function ThemeProvider ({ children }: Props) {

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette('light'),
      typography,
      shape: { borderRadius: 8 },
      direction: 'ltr'
    }),
    []
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MUIThemeProvider>
  )
}
