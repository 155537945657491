import { RRule } from 'rrule'

export const hours = [
  { id: '1', title: '4:00' },
  { id: '2', title: '4:30' },
  { id: '3', title: '5:00' },
  { id: '4', title: '5:30' },
  { id: '5', title: '6:00' },
  { id: '6', title: '6:30' },
  { id: '7', title: '7:00' },
  { id: '8', title: '7:30' },
  { id: '9', title: '8:00' },
  { id: '10', title: '8:30' },
  { id: '11', title: '9:00' },
  { id: '12', title: '9:30' },
  { id: '13', title: '10:00' },
  { id: '14', title: '10:30' },
  { id: '15', title: '11:00' },
  { id: '16', title: '11:30' },
  { id: '17', title: '12:00' },
  { id: '18', title: '12:30' },
  { id: '19', title: '13:00' },
  { id: '20', title: '13:30' },
  { id: '21', title: '14:00' },
  { id: '22', title: '14:30' },
  { id: '23', title: '15:00' },
  { id: '24', title: '15:30' },
  { id: '25', title: '16:00' },
  { id: '26', title: '16:30' },
  { id: '27', title: '17:00' },
  { id: '28', title: '17:30' },
  { id: '29', title: '18:00' },
  { id: '30', title: '18:30' },
  { id: '31', title: '19:00' },
  { id: '32', title: '19:30' },
  { id: '33', title: '20:00' },
  { id: '34', title: '20:30' },
  { id: '35', title: '21:00' },
  { id: '36', title: '21:30' },
  { id: '37', title: '22:00' },
  { id: '38', title: '22:30' },
  { id: '39', title: '23:00' },
]

export const validity = [
  { id: '1', title: 'No repetir' },
  { id: '2', title: 'Una semana' },
  { id: '3', title: 'Dos semanas' },
  { id: '4', title: 'Un mes' },
  { id: '5', title: 'Dos meses' }
]

export const daysOfTheWeek = [
  {
    id: '1',
    title: 'Lunes',
    rrule: RRule.MO
  },
  {
    id: '2',
    title: 'Martes',
    rrule: RRule.TU
  },
  {
    id: '3',
    title: 'Miercoles',
    rrule: RRule.WE
  },
  {
    id: '4',
    title: 'Jueves',
    rrule: RRule.TH
  },
  {
    id: '5',
    title: 'Viernes',
    rrule: RRule.FR
  },
  {
    id: '6',
    title: 'Sabado',
    rrule: RRule.SA
  }
]
