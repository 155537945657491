import { AppointmentModel } from '@devexpress/dx-react-scheduler'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { CalendarState } from '../utils/interfaces/stores'

export const useCalendarStore = create<CalendarState>()(
  devtools((set) => ({
    weeklyData: [],
    setWeeklyData: (data: Array<AppointmentModel>) =>
      set(() => ({ weeklyData: data })),
    updateWeeklyData: (data: Array<AppointmentModel>) =>
      set((prevState) => {
        const { weeklyData } = prevState
        for (const appointment of data) {
          const index = weeklyData.findIndex(
            (existingAppointment) =>
              existingAppointment.id === appointment.id ||
              existingAppointment.startDate === appointment.startDate
          )

          if (index !== -1) {
            weeklyData[index] = appointment
          } else {
            weeklyData.push(appointment)
          }
        }
        return { weeklyData: [...weeklyData] }
      }),
    deleteAvailabilitySlots: (data: Array<number>) =>
      set((prevState) => {
        const { weeklyData } = prevState
        data.forEach((appointmentId) => {
          const index = weeklyData.findIndex(
            (availability) => availability.id === appointmentId
          )
          weeklyData.splice(index, 1)
        })
        return { weeklyData: [...weeklyData] }
      }),

    monthlyData: [],
    setMonthlyData: (data: Array<AppointmentModel>) =>
      set(() => ({ monthlyData: data })),

    resetStore: () =>
      set(() => ({
        weeklyData: [],
        monthlyData: []
      }))
  }))
)
