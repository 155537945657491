import { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import ArrowGoPreviousPage from '../../components/arrowGoPreviousPage'
import { useLocation } from 'react-router-dom'
import { Button, Typography } from '@mui/material'
import { CustomDatePicker } from '../../components/CustomDatePicker'
import { endOfMonth, startOfMonth } from 'date-fns'
import { CustomSelect } from '../../components/CustomSelect'
import { useFormik } from 'formik'
import CustomDataGrid from '../../components/CustomDataGrid'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'
import { APPOINTMENT_STATE_LABELS } from '../../utils/constants'
import { CSVLink } from 'react-csv'
import { modalidades, estados } from '../../utils/constants'
import { getPatientReports, getDoctorReports } from '../../services/reports'
import { format } from 'date-fns'
import { useDoctorStore } from '../../store/doctor'
import { getMedicalProfile } from '../../services/doctor'
import { useInputDataStore } from '../../store/inputDataStore'
import { getHealthCoverage } from '../../services/inputsData'

const MedicalActivityScreen = () => {
  const location = useLocation()
  const { state }: { state: any } = location
  const userIsDoctor = state.pathToGoBack === '/reports'
  const [startDate, setStartDate] = useState(startOfMonth(new Date()))
  const [endDate, setEndDate] = useState(endOfMonth(new Date()))
  const [filteredRows, setFilteredRows] = useState([])
  const { medicalProfile, setMedicalProfile } = useDoctorStore()
  const { setHealthCoverages, healthCoverages } = useInputDataStore()
  const [healthCoverageFilterOptions, setHealthCoveragesFilterOptions] =
    useState<any[]>([])

  useEffect(() => {
    const apiCall = async () => {
      const data = await getMedicalProfile()
      setMedicalProfile(data)
    }

    if (userIsDoctor) apiCall()
  }, [setMedicalProfile, userIsDoctor])

  useEffect(() => {
    const apiCall = async () => {
      if (!healthCoverages || healthCoverages.length === 0) {
        const data = await getHealthCoverage()
        setHealthCoverages(data)
      }
    }

    if (healthCoverages && healthCoverages?.length > 0) {
      const aux: any[] = healthCoverages?.filter((hc) =>
        medicalProfile.healthCoveragesIds.includes(
          typeof hc.id === 'string' ? parseInt(hc.id) : hc.id
        )
      )
      aux.unshift({ id: 0, title: 'Sin cobertura', tag: 'sin-cobertura' })
      setHealthCoveragesFilterOptions(aux)
    }

    if (userIsDoctor) {
      apiCall()
    } else {
      setHealthCoveragesFilterOptions([
        { id: 0, title: 'Sin cobertura', tag: 'sin-cobertura' },
        { id: 1, title: 'Con cobertura', tag: 'con-cobertura' }
      ])
    }
  }, [
    healthCoverages,
    medicalProfile.healthCoveragesIds,
    setHealthCoverages,
    userIsDoctor
  ])

  const initialValues = {
    includeNoHealthCoverage: '',
    healthCoverage: [],
    appointmentStatus: [],
    appointmentType: []
  }

  const { handleChange, handleBlur, values } = useFormik({
    initialValues,
    onSubmit: async (values: any) => console.log('submit')
  })

  const fullName =
    state.medicalState.firstName + ' ' + state.medicalState.lastName

  const bgAndTextColourSetter = (stateName: string) => {
    return stateName === APPOINTMENT_STATE_LABELS.REALIZADO
      ? 'bg-green text-green'
      : stateName === APPOINTMENT_STATE_LABELS.CANCELADO
      ? 'bg-red text-red'
      : 'bg-orange text-orange'
  }

  const columns: GridColDef[] = [
    {
      field: 'appointmentDate',
      headerName: 'Fecha',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Fecha
          </Typography>
        )
      },
      editable: false,
      renderCell: (cellValues): any => {
        const date = new Date(cellValues.row.appointmentDate)
        return (
          <div className="flex flex-row items-center">
            <Typography className="pl-4">
              {format(date, 'dd/MM/yy')} - {cellValues.row.appointmentDateText}
              hs
            </Typography>
          </div>
        )
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'healthCoverage',
      headerName: 'Cobertura médica',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Cobertura médica
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        const { row } = cellValues
        return (
          <div className="flex flex-row items-center">
            <Typography className="pl-4">
              {row.healthCoverage ? row.healthCoverage.title : 'Sin cobertura'}
            </Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: userIsDoctor ? 'affiliateNumber' : 'patient',
      headerName: userIsDoctor ? 'N° afiliado' : 'Paciente',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            {userIsDoctor ? 'N° afiliado' : 'Paciente'}
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        const { row } = cellValues
        let label = '-'
        if (userIsDoctor && row.affiliateNumber) {
          label = row.affiliateNumber
        } else if (row.patient) {
          label = row.patient.firstName + ' ' + row.patient.lastName
        }

        return (
          <div className="flex flex-row items-center pl-4">
            <Typography>{label}</Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'specialty',
      headerName: 'Especialidad',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Especialidad
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <Typography className="pl-4">
              {cellValues.row.specialty.title}
            </Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'appointmentType',
      headerName: 'Modalidad',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Modalidad
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        const { row } = cellValues

        const label =
          row.appointmentType === 'VIDEOCALL' ? 'Videollamada' : 'Presencial'
        return (
          <div className="flex flex-row items-center">
            <Typography className="pl-4">{label}</Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'status',
      headerName: 'Estado',
      renderHeader: (params: GridColumnHeaderParams): any => {
        return (
          <Typography className="pl-4" fontWeight="bold">
            Estado
          </Typography>
        )
      },
      renderCell: (cellValues): any => {
        const { row } = cellValues
        let label = ''
        switch (row.status) {
          case 'COMPLETED':
            label = APPOINTMENT_STATE_LABELS.REALIZADO
            break
          case 'CANCELLED':
            label = APPOINTMENT_STATE_LABELS.CANCELADO
            break
          case 'BOOKED':
            label = APPOINTMENT_STATE_LABELS.RESERVADO
            break
          default:
            break
        }
        return (
          <div className="flex flex-row items-center">
            <Typography
              fontWeight="bold"
              className={`capitalize ${bgAndTextColourSetter(
                label
              )} bg-opacity-10 px-4 py-2 rounded-3xl`}
            >
              {label.toUpperCase()}
            </Typography>
          </div>
        )
      },
      editable: false,
      flex: 1,
      sortable: false
    }
  ]

  useEffect(() => {
    const getReportsById = async () => {
      let response
      const appointmentType = values.appointmentType.map((el) => {
        return el === 1 ? 'PRESENTIAL' : 'VIDEOCALL'
      })
      const appointmentStatus = values.appointmentStatus.map((el) => {
        return el === 1 ? 'BOOKED' : el === 2 ? 'CANCELLED' : 'COMPLETED'
      })

      const includeNoHealthCoverage = values.healthCoverage.some(
        (e: number) => e === 0
      )

      const includeHealthCoverage = values.healthCoverage.some(
        (e: number) => e === 1
      )

      const healthCoverageIds = values.healthCoverage.filter(
        (e: number) => e !== 0 && e !== 1
      )

      if (userIsDoctor) {
        response = await getPatientReports(
          state.medicalState.id,
          startDate,
          endDate,
          includeNoHealthCoverage,
          appointmentStatus,
          appointmentType,
          healthCoverageIds
        )
      } else {
        response = await getDoctorReports(
          state.medicalState.id,
          startDate,
          endDate,
          includeNoHealthCoverage,
          includeHealthCoverage,
          appointmentStatus,
          appointmentType
        )
      }
      response !== undefined && setFilteredRows(response)
    }

    getReportsById()
  }, [
    state.medicalState.id,
    state.pathToGoBack,
    startDate,
    endDate,
    values,
    userIsDoctor
  ])

  interface ReportData {
    Fecha: string
    Especialidad: string
    Modalidad: string
    Estado: string
    Paciente?: string
    'Nº de afiliado'?: string
  }

  const transformDataToCSV = (data: any[]) => {
    return data.map((element) => {
      const fullName = element.patient
        ? element.patient.firstName + ' ' + element.patient.lastName
        : ''
      const appointmentType =
        element.appointmentType === 'PRESENTIAL' ? 'Presencial' : 'Videollamada'
      let appointmentStatus = ''
      switch (element.status) {
        case 'COMPLETED':
          appointmentStatus = APPOINTMENT_STATE_LABELS.REALIZADO
          break
        case 'CANCELLED':
          appointmentStatus = APPOINTMENT_STATE_LABELS.CANCELADO
          break
        case 'BOOKED':
          appointmentStatus = APPOINTMENT_STATE_LABELS.RESERVADO
          break
        default:
          break
      }
      const data: ReportData = {
        Fecha: format(new Date(element.appointmentDate), 'dd/MM/yyyy'),

        Especialidad: element.specialty.title,
        Modalidad: appointmentType,
        Estado: appointmentStatus
      }
      if (userIsDoctor) {
        data['Nº de afiliado'] = element.affiliateNumber
          ? element.affiliateNumber
          : '-'
        return data
      } else {
        data.Paciente = fullName
        return data
      }
    })
  }

  const parseFilters = () => {
    const aux: string[] = []
    const { appointmentType, healthCoverage, appointmentStatus } = values

    healthCoverage.forEach((el) => {
      if (el === 0) {
        aux.push('Sin cobertura')
      }
      if (el === 1) {
        aux.push('Con cobertura')
      }
      const hc = healthCoverages?.find((hc) => hc.id === el)?.title
      if (hc) {
        aux.push(hc)
      }
    })

    appointmentType.forEach((el) => {
      el === 1 ? aux.push('Presencial') : aux.push('Videollamada')
    })

    appointmentStatus.forEach((el) => {
      if (el === 1) {
        aux.push('Reservado')
      } else if (el === 2) {
        aux.push('Cancelado')
      } else {
        aux.push('Realizado')
      }
    })

    return aux.length > 0 ? aux.join(', ') + '.' : ''
  }

  return (
    <Layout>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full h-full px-12">
          <div className="flex flex-col w-full justify-start pl-2 pr-2">
            <div className="mb-4">
              <ArrowGoPreviousPage
                path={state.pathToGoBack}
                title={'Volver a reportes'}
              />
            </div>
            <div className="font-montserrat flex flex-row text-pitch-black font-bold text-2xl tracking-wider">
              {fullName}
            </div>
          </div>
          {filteredRows.length > 0 ? (
            <CSVLink
              data={transformDataToCSV(filteredRows)}
              filename={`Reporte Medapp - ${fullName}`}
            >
              <Button
                color="secondary"
                variant="contained"
                className="w-[238px] h-[48px]"
              >
                Descargar reporte
              </Button>
            </CSVLink>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              className="w-[238px] h-[48px]"
              disabled={true}
            >
              Descargar reporte
            </Button>
          )}
        </div>
        <div className="flex flex-row w-[95%] mx-14 justify-around pt-8">
          <div className="flex w-1/5 mr-4">
            <CustomSelect
              label="Cobertura médica"
              placeholder="Cobertura médica"
              id="healthCoverage"
              name="healthCoverage"
              size="small"
              options={healthCoverageFilterOptions}
              value={values.healthCoverage}
              error={undefined}
              helperText={undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              multiple
            />
          </div>

          <div className="flex w-1/5 mr-4">
            <CustomSelect
              label="Modalidad"
              placeholder="Modalidad"
              id="appointmentType"
              name="appointmentType"
              size="small"
              options={modalidades}
              value={values.appointmentType}
              error={undefined}
              helperText={undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              multiple
            />
          </div>
          <div className="flex w-1/5 mr-4">
            <CustomSelect
              label="Estado"
              placeholder="Estado"
              id="appointmentStatus"
              name="appointmentStatus"
              size="small"
              options={estados}
              value={values.appointmentStatus}
              error={undefined}
              helperText={undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              multiple
            />
          </div>

          <div className="flex mr-4">
            <CustomDatePicker
              label="Desde"
              value={startDate}
              onChange={setStartDate}
              className="w-42"
            />
          </div>
          <div className="flex mr-4">
            <CustomDatePicker
              label="Hasta"
              value={endDate}
              onChange={setEndDate}
              className="w-42"
            />
          </div>
        </div>
        <div className="bg-white mt-8 mx-14">
          {parseFilters() !== '' && (
            <Typography className="p-3">
              Resultados para: {parseFilters()}
            </Typography>
          )}
          <div className="flex h-[495px]">
            <CustomDataGrid
              rows={filteredRows}
              rowHeight={64}
              columns={columns}
              getRowId={(row) => row.appointmentId}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MedicalActivityScreen
