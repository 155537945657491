import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { DrawerState } from '../utils/interfaces/stores'

export const useDrawerStore = create<DrawerState>()(
  devtools((set) => ({
    drawerIsOpen: false,
    setDrawerIsOpen: (isOpen: boolean) =>
      set(() => ({ drawerIsOpen: isOpen })),
  }))
)