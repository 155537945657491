import { TextField, Button, Divider } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useStore } from 'zustand'
import { useAlertStore } from '../../store/alertStore'
import { useDoctorStore } from '../../store/doctor'
import { authStore } from '../../store/auth'
import { setDoctorFee, getDoctorFee } from '../../services/doctor'
import { connectMercadoPagoApi, deleteMercadoPagoApi } from '../../services/mercadoPago'
import CustomModal from '../../components/customModal'
import ModalCross from '../../assets/modal-cross.png'
import { refreshDoctorData } from '../../services/user'
import { userState } from '../../utils/interfaces/stores'

const PaymentsScreen = () => {
  const { user, setUser } = useStore(authStore)
  const { setPaymentsFee, availability } = useDoctorStore()
  const { setAlert } = useAlertStore()

  const {videocallAppointments, presentialAppointments} = availability
  const { hasMercadoPago, mercadoPagoEmail, mercadoPagoUserName } = user as userState;


  const [modalStatusIsOpen, setStatusIsOpen] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    videocall: Yup.number().nullable().notRequired(),
    onsite: Yup.number().nullable().notRequired()
  })

  const initialValues = {
    videocall: '',
    onsite: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
    setFieldValue
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const {
        videocall,
        onsite
      } = values

      if (videocall === '' && onsite === '') {
        setAlert({
          isOpen: true,
          message:
            'Debe ingresar algún valor',
          severity: 'error'
        })
        return
      }

      const data: any = {
        videocallFee: videocallAppointments ? videocall : null,
        presentialFee: presentialAppointments ? onsite : null
      }


      const response = await setDoctorFee(data)

      if (response.status !== 201) {
        setAlert({
          isOpen: true,
          message:
            'Error en el guardado de los datos, por favor inténtelo más tarde',
          severity: 'error'
        })
        return
      }

      if (response.status === 201) {
        setPaymentsFee({
          ...data
        })
        setAlert({
          isOpen: true,
          message: 'Datos actualizados',
          severity: 'success'
        })
      }
    }
  })

  const connectMercadoPago = async () => {
    const response = await connectMercadoPagoApi();
    if (response.url) {
      window.open(response.url, '_self');
    }
  }

  const refreshData = async () => {
    const response = await refreshDoctorData()
    const { hasMercadoPago, mercadoPagoEmail, mercadoPagoUserName } = response;
    setUser({
      ...user,
      hasMercadoPago,
      mercadoPagoEmail,
      mercadoPagoUserName,
    } as userState);
  };

  const deleteMercadoPago = async () => {
    const response = await deleteMercadoPagoApi();
    if (!response.code) {
      setAlert({
        isOpen: true,
        message: 'Cuenta desvinculada',
        severity: 'success'
      });
      refreshData();
    }else {
      setAlert({
        isOpen: true,
        message: 'Error al desvincular la cuenta',
        severity: 'error'
      });
    }
  }

  useEffect(() => {
    validateForm()
  }, [validateForm])

  const getData = async () => {
    const response = await getDoctorFee()
    return response
  }
  
  useEffect(() => {
    getData().then(response => {
      if (response.videocallFee) {
        setFieldValue('videocall', response.videocallFee)
      }
  
      if (response.presentialFee) {
        setFieldValue('onsite', response.presentialFee)
      }
    })
  }, [setFieldValue])

  return (
    <form className='w-full pr-40' onSubmit={handleSubmit}>
      <div className="flex flex-row h-full w-full pt-28 pl-10">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col pb-12 h-[60vh]">
            <div className="font-montserrat font-normal text-xl tracking-wider text-pitch-black pb-4">
              Pagos
            </div>
            {!hasMercadoPago ? (
                <>
                  <p className="font-montserrat text-sm text-black font-normal">Para recibir pagos en Medapp debes vincular tu cuenta de Mercado Pago.</p>
                  <div className='flex w-44 mt-8'>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={connectMercadoPago}
                  >
                    Vincular mi cuenta
                  </Button>
                  </div>
                </>
            ) :
            (
              <>
                <div
                  className='flex flex-row w-full items-end rounded-xl border border-solid border-[#E3ED61] bg-[#F3F5D9] mb-6 p-5'
                >
                  <div className='flex-1'>
                    <div className='font-montserrat text-sm text-black font-semibold mb-4'>
                      Cuenta vinculada
                    </div>
                    <div className='font-montserrat text-sm text-black font-normal'>
                      {`Usuario: ${mercadoPagoUserName}`}
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='font-montserrat text-sm text-black font-normal'>
                      {`Email: ${mercadoPagoEmail}`}
                    </div>
                  </div>
                </div>
                <div className='w-full'>
                  <Button
                    color="error"
                    variant="text"
                    onClick={() => setStatusIsOpen(true)}
                  >
                    Desvincular cuenta
                  </Button>
                </div>
              </>
            )}
            <div className='my-12'><Divider /></div>
            <div className='flex flex-col'>
              <div className="font-montserrat font-normal text-xl tracking-wider text-pitch-black pb-4">
                Valor de las consultas
              </div>
              <div className="w-48 pr-6 pb-4">
                    <TextField
                      label="Videollamada"
                      variant="outlined"
                      className="w-full"
                      id="videocall"
                      name="videocall"
                      type="number"
                      autoComplete='off'
                      value={videocallAppointments ? values.videocall : ''}
                      error={
                        !!errors.videocall &&
                        touched.videocall !== undefined
                      }
                      helperText={
                        !!errors.videocall &&
                        touched.videocall &&
                        errors.videocall
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: <span className='mr-2'>$</span>,
                        inputProps: { min: 1 }
                      }}
                      disabled={!videocallAppointments}
                    />
                  </div>
            </div>
            <div className='flex flex-col'>
              <div className="w-48 pr-6 pb-4">
                    <TextField
                      label="Presencial"
                      variant="outlined"
                      className="w-full"
                      id="onsite"
                      name="onsite"
                      type="number"
                      autoComplete='off'
                      value={presentialAppointments ? values.onsite : ''}
                      error={
                        !!errors.onsite &&
                        touched.onsite !== undefined
                      }
                      helperText={
                        !!errors.onsite &&
                        touched.onsite &&
                        errors.onsite
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: <span className='mr-2'>$</span>,
                        inputProps: { min: 1 }
                      }}
                      disabled={!presentialAppointments}
                    />
                  </div>
            </div>
          </div>

          <div className="w-full flex flex-row justify-end">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!isValid}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </div>
      <CustomModal
        openModal={modalStatusIsOpen}
        handleClose={() => setStatusIsOpen(false)}
        imgSrc={ModalCross}
        title='¿Estás seguro de querer desvincular tu cuenta de Mercado Pago?'
        description='Al realizar esta acción, automáticamente tus próximos turnos sin cobertura por videollamada serán cancelados, y no te podrán reservar turnos con estas características.'
        onSubmit={() => {
          deleteMercadoPago();
          setStatusIsOpen(false);
        }}
        submitLabel="Aceptar"
        showCancelButton
        child={null}
        isSubmitDisable={null}
      />
    </form>
  )
}

export default PaymentsScreen
