import { WeekView } from '@devexpress/dx-react-scheduler-material-ui'
import { styled } from '@mui/system'
import { classes } from '../common'

const StyledScaleCell = styled(WeekView.DayScaleCell)(() => ({
  '.Cell-dayView': {
    color: '#242628',
    textTransform: 'capitalize',
    backgroundColor: '#FFFFFF'
  }
}))

export const WeekDayScaleCell = (props: WeekView.DayScaleCellProps) => {
  return <StyledScaleCell {...props} />
}

const StyledDayScaleRow = styled(WeekView.DayScaleRow)(() => ({
  [`&.${classes.layoutContainer}`]: {
    backgroundColor: '#FFFFFF'
  }
}))

export const DayScaleRow = (props: WeekView.RowProps) => {
  return <StyledDayScaleRow {...props} className={classes.layoutContainer} />
}

const StyledDayScaleEmptyCell = styled(WeekView.DayScaleEmptyCell)(() => ({
  [`&.${classes.layoutContainer}`]: {
    backgroundColor: '#FFFFFF'
  }
}))

export const DayScaleEmptyCell = (props: WeekView.DayScaleEmptyCellProps) => {
  return (
    <StyledDayScaleEmptyCell {...props} className={classes.layoutContainer} />
  )
}

const StyledTimeScaleLayout = styled(WeekView.TimeScaleLayout)(() => ({
  [`&.${classes.layoutContainer}`]: {
    backgroundColor: '#FFFFFF'
  }
}))

export const TimeScaleLayout = (props: WeekView.TimeScaleLayoutProps) => {
  return (
    <StyledTimeScaleLayout {...props} className={classes.layoutContainer} />
  )
}

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
  ({ theme }) => ({
    [`&.${classes.weekendCell}`]: {
      backgroundColor: '#E1E1E1',
      borderColor: '#D3D3D3',
      borderWidth: '0.32px',
      '&:hover': {
        backgroundColor: '#E1E1E1',
      },
      '&:focus': {
        backgroundColor: '#E1E1E1',
      }
    },
    [`&.${classes.weekdayCell}`]: {
      backgroundColor: '#FFFFFF',
      opacity: '30%',

      '&:hover': {
        backgroundColor: '#FFFFFF',
        opacity: '30%'
      },
      '&:focus': {
        backgroundColor: '#FFFFFF',
        opacity: '30%'
      }
    }
  })
)

export const WeekTimeTableCell = (props: WeekView.TimeTableCellProps) => {
  const { startDate } = props

  return (
    <StyledWeekViewTimeTableCell
      {...props}
      className={
        startDate?.getDay() === 0 ? classes.weekendCell : classes.weekdayCell
      }
    />
  )
}