import { Layout } from '../../components/Layout';
import { useStore } from 'zustand';
import { authStore } from '../../store/auth';
import { UserTypes } from '../../utils/constants';
import DoctorWelcome from './components/DoctorWelcome/DoctorWelcome';
import AdminWelcome from './components/AdminWelcome';
import SecretaryWelcome from './components/SecretaryWelcome';

const WelcomeScreen = () => {
  const { user } = useStore(authStore);
  return (
    <Layout>
      {user?.role === UserTypes.doctor ? (
        <DoctorWelcome />
      ) : user?.role === UserTypes.secretary ? (
        <SecretaryWelcome />
      ) : (
        <AdminWelcome />
      )}
    </Layout>
  );
};

export default WelcomeScreen;
