import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Modal,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import VideocamIcon from '@mui/icons-material/Videocam';
import HomeIcon from '@mui/icons-material/Home';
import { useEffect, useState } from 'react';
import { parseAvailabilityType } from '../utils';
import SelectBox from './SelectBox';
import { useAlertStore } from '../../../store/alertStore';
import FormatedDateBox from './FormatedDateBox';
import {
  EditAvailabilityModalProps,
  Patient,
} from '../../../utils/interfaces/props';
import { authStore } from '../../../store/auth';
import { useStore } from 'zustand';
import {
  createAppoinment,
  getDoctorHealthCoverage,
  getDoctorSpecialty,
  getPatient,
} from '../../../services/doctor';
import { InputData } from '../../../utils/interfaces/stores';
import { CustomSelect } from '../../../components/CustomSelect';
import { AvailabilitySlotType } from '../../../utils/constants';

type InitialValuesTypes = {
  remote: boolean;
  onSite: boolean;
  specialityId: string;
  healdCoverageId: string;
};

const CreateAppointmenModal = (props: EditAvailabilityModalProps) => {
  const [healthCoverageData, setHealthCoverageData] = useState<InputData[]>([]);
  const [specialtyData, setSpecialtyData] = useState<InputData[]>([]);
  const [patientSelected, setPatientSelected] = useState('');
  const [patientList, setPatientList] = useState<Patient[]>([]);
  const [patient, setPatient] = useState<Patient>();

  const { open, handleClose, selected, availabilities, resetSelected } = props;
  const { setAlert } = useAlertStore();
  const { doctor: secretaryAsDoctor } = useStore(authStore);

  const selectedAvaliability = availabilities.find(
    (availability) => availability.id === selected[0],
  )!;

  const { startDate, endDate, id, availabilitySlotType } = selectedAvaliability;

  const initialValues: InitialValuesTypes = {
    remote: false,
    onSite: false,
    specialityId: '',
    healdCoverageId: '',
  };

  const validationSchema = Yup.object({
    remote: Yup.boolean(),
    onSite: Yup.boolean(),
    specialityId: Yup.string().required('La especialidad es requerida'),
    healdCoverageId: Yup.string(),
  });

  const {
    handleSubmit,
    values,
    isValid,
    validateForm,
    setFieldValue,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const availabilitySlotType = parseAvailabilityType(
        values.remote,
        values.onSite,
      );

      if (!patient || !secretaryAsDoctor?.id) return;

      const availabilitySlotsIds = selected.map((a: any) => parseInt(a));

      const response = await createAppoinment(
        {
          availabilitySlotId: availabilitySlotsIds[0],
          timezone: 'America/Buenos_Aires',
          patientId: patient?.id,
          appointmentType: availabilitySlotType,
          specialtyId: parseInt(values.specialityId),
          ...(values.healdCoverageId && {
            healthCoverageId: parseInt(values.healdCoverageId),
          }),
        },
        secretaryAsDoctor?.id,
      );

      if (response.status === 400) {
        setAlert({
          isOpen: true,
          message: response.data.message,
          severity: 'error',
        });
        return;
      }

      if (response.status !== 201) {
        setAlert({
          isOpen: true,
          message: 'Error al intentar crear un nuevo Turno',
          severity: 'error',
        });
        return;
      }
      if (response.status === 201) {
        setAlert({
          isOpen: true,
          message: 'Se creó correctamente el Turno',
          severity: 'success',
        });

        resetForm();
        resetSelected();
        handleClose();
      }
    },
  });

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    const getData = async () => {
      if (!secretaryAsDoctor?.id) return;

      const specResponse = await getDoctorSpecialty(secretaryAsDoctor?.id);
      setSpecialtyData(specResponse);

      const hCovResponse = await getDoctorHealthCoverage(secretaryAsDoctor?.id);

      let hCovOutput: InputData[] = [];
      (hCovResponse as any).items.forEach((healthCovItem: any) => {
        hCovOutput.push(healthCovItem.healthCoverage);
      });

      setHealthCoverageData(hCovOutput);
    };

    getData();
  }, [secretaryAsDoctor?.id]);

  useEffect(() => {
    const getData = async () => {
      if (!secretaryAsDoctor?.id || patientSelected.length < 3) return;
      setPatient(undefined);
      const response = await getPatient(
        values.healdCoverageId,
        patientSelected,
        secretaryAsDoctor?.id,
      );

      if (response.length) {
        setPatientList(response as Patient[]);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientSelected, secretaryAsDoctor?.id]);

  useEffect(() => {
    // Remove Patient Input Text
    setPatientSelected('');

    // Remove Patient selected
    const element = document.querySelector(
      '[aria-label="Clear"]',
    ) as HTMLElement;
    if (element) {
      element.click();
    }

    // Remove Patient List
    setPatientList([]);
  }, [values.healdCoverageId]);

  if (!selected) {
    handleClose();
  }

  const videocallAppointmentsIsDisable = () =>
    availabilitySlotType !== AvailabilitySlotType.videocall &&
    availabilitySlotType !== AvailabilitySlotType.presentialVideocall;
  const presentialAppointmentsIsDisable = () =>
    availabilitySlotType !== AvailabilitySlotType.presential &&
    availabilitySlotType !== AvailabilitySlotType.presentialVideocall;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className='flex items-center justify-center'
    >
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col w-[600px] h-auto bg-white rounded'>
          <div className='flex flex-row w-full items-start justify-end'>
            <IconButton aria-label='close' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className='flex flex-col px-6'>
            <div className='font-montserrat text-xl font-bold leading-24 tracking-wider'>
              Asignar nuevo turno
            </div>
            <div className='mt-4 font-montserrat text-[13px] font-normal leading-16 tracking-wider'>
              Completá el siguiente formulario para crear un nuevo turno.
            </div>
            <div className=' mt-4 mb-2'>
              <Box className='flex flex-row w-[300px] rounded-lg justify-between space-x-3'>
                <SelectBox
                  selected={values.remote}
                  icon={<VideocamIcon />}
                  title={'Videollamada'}
                  id='remote'
                  onClick={() => {
                    setFieldValue('remote', !values.remote);
                    if (!presentialAppointmentsIsDisable() && values.onSite) {
                      setFieldValue('onSite', !values.onSite);
                    }
                  }}
                  disabled={videocallAppointmentsIsDisable()}
                />
                <SelectBox
                  selected={values.onSite}
                  icon={<HomeIcon />}
                  title={'Presencial'}
                  id='onSite'
                  onClick={() => {
                    setFieldValue('onSite', !values.onSite);

                    if (!videocallAppointmentsIsDisable() && values.remote) {
                      setFieldValue('remote', !values.remote);
                    }
                  }}
                  disabled={presentialAppointmentsIsDisable()}
                />
              </Box>
              {!values.onSite && !values.remote && (
                <div className='mt-2 font-montserrat text-red-600 text-[12px] font-normal leading-16 tracking-wider'>
                  Seleccioná al menos un tipo de atención.
                </div>
              )}
            </div>
            <FormatedDateBox key={id} startDate={startDate} endDate={endDate} />
            <div className='w-full ml-1 mt-6'>
              <CustomSelect
                label='Especialidad'
                placeholder='Especialidad'
                className='w-full white-background'
                id='specialityId'
                name='specialityId'
                options={specialtyData}
                value={values.specialityId as string}
                error={!!errors.specialityId && touched.specialityId}
                helperText={
                  !!errors.specialityId &&
                  touched.specialityId &&
                  errors.specialityId
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
                IconComponent={
                  values.specialityId
                    ? () => (
                        <IconButton
                          size='small'
                          className='z-10'
                          onClick={(e) => {
                            setFieldValue('specialityId', '');
                          }}
                        >
                          <CloseIcon fontSize='small' className='mr-1' />
                        </IconButton>
                      )
                    : undefined
                }
              />
            </div>
            <div className='w-full ml-1 mt-4'>
              <CustomSelect
                label='Cobertura Médica'
                placeholder='Cobertura Médica'
                className='w-full white-background'
                id='healdCoverageId'
                name='healdCoverageId'
                options={healthCoverageData}
                value={values.healdCoverageId as string}
                error={!!errors.healdCoverageId && touched.healdCoverageId}
                helperText={
                  !!errors.healdCoverageId &&
                  touched.healdCoverageId &&
                  errors.healdCoverageId
                }
                onChange={handleChange}
                onBlur={handleBlur}
                size='small'
                IconComponent={
                  values.healdCoverageId
                    ? () => (
                        <IconButton
                          size='small'
                          className='z-10'
                          onClick={(e) => {
                            setFieldValue('healdCoverageId', '');
                          }}
                        >
                          <CloseIcon fontSize='small' className='mr-1' />
                        </IconButton>
                      )
                    : undefined
                }
              />
            </div>
            <div className='w-full ml-1 mt-4'>
              <Autocomplete
                className={'w-full white-background'}
                options={patientList}
                id='patient'
                sx={{
                  '& .MuiInputBase-input': {
                    minWidth: '30px !important',
                  },
                  '& .MuiInputLabel-shrink': {
                    color: '#919EAB',
                    backgroundColor: '#FFF !important',
                    paddingRight: '8px',
                  },
                }}
                renderOption={(props, option: Patient, { selected }) => (
                  <MenuItem
                    {...props}
                    key={option.id as number}
                    value={option.id}
                  >
                    <Checkbox checked={selected} />
                    <span className='text-pitch-black'>
                      {option.firstName} {option.lastName} - {option.email}
                    </span>
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Pacientes'}
                    onChange={(e) => setPatientSelected(e.target.value)}
                  />
                )}
                getOptionLabel={(option: Patient) =>
                  `${option.firstName} ${option.lastName} - ${option.email}`
                }
                isOptionEqualToValue={(option: Patient, value: Patient) => {
                  return option.id === value.id;
                }}
                onChange={(_, value) =>
                  value ? setPatient(value) : setPatient(undefined)
                }
                noOptionsText={'Sin Resultados'}
              />
            </div>
            <div className='flex w-full h-full justify-end content-end mt-12 mb-6'>
              <Button
                type='submit'
                variant='contained'
                color='secondary'
                disabled={
                  !isValid || (!values.onSite && !values.remote) || !patient
                }
              >
                Asignar turno
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateAppointmenModal;
