import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { HistoryProsp, MedicalRecordsProps, NoteProps, RapportProps } from '../utils/constants'
import { RapportsStore } from '../utils/interfaces/stores'

export const useRapportsStore = create<RapportsStore>()(
  devtools((set) => ({
    rapports: [],
    setRapports: (data: RapportProps[]) => set(() => ({ rapports: data })),
    history: [],
    setHistory: (data: HistoryProsp[]) => set(() => ({ history: data })),
    notes: [],
    setNotes: (data: NoteProps[]) => set(() => ({ notes: data })),
    medicalRecords: [],
    setMedicalRecords: (data: MedicalRecordsProps[]) =>
      set(() => ({ medicalRecords: data })),
    resetStore: () =>
      set(() => ({
        rapports: [],
        history: [],
        notes: [],
        medicalRecords: [],
      })),
  }))
)
