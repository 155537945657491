import React from 'react';

const ReportsIcon = ({ fill } : {fill?: string}) => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_4504_55538)">
<path d="M15.3429 14.6972L26.0687 14.8436C25.9878 20.5571 21.172 25.215 15.3429 25.215C15.3236 25.215 15.3005 25.215 15.2812 25.215L15.3429 14.6934V14.6972Z" fill={fill ? fill : '#242628'}/>
<path d="M11.9175 12.3325C11.8713 15.6111 11.825 18.8936 11.775 22.1722C11.7673 22.6423 11.7596 23.1123 11.7557 23.5862L12.6226 22.7193C9.38245 22.6538 6.26564 21.1205 4.29307 18.5353C2.3205 15.9502 1.66555 12.4789 2.63257 9.33899C3.59959 6.19906 5.93431 3.81425 8.86619 2.68542C11.9522 1.49494 15.4427 1.76848 18.2821 3.47137C21.3912 5.33606 23.3677 8.7033 23.3869 12.3325L24.2538 11.4657H12.7805C11.6632 11.4657 11.6632 13.1994 12.7805 13.1994H24.2538C24.7199 13.1994 25.1245 12.8025 25.1206 12.3325C25.0975 8.54148 23.2636 5.01629 20.2624 2.7278C17.2381 0.420049 13.1889 -0.350486 9.51729 0.647357C5.96898 1.61438 2.90225 4.20723 1.44209 7.59373C-0.0180725 10.9802 0.170709 15.1758 2.15098 18.4621C4.35471 22.1183 8.36535 24.3721 12.6187 24.4569C13.0926 24.4646 13.4778 24.0523 13.4855 23.59C13.5318 20.3114 13.578 17.0289 13.6281 13.7503C13.6358 13.2803 13.6435 12.8102 13.6474 12.3364C13.6628 11.2191 11.9291 11.2191 11.9137 12.3364L11.9175 12.3325Z" fill={fill ? fill : '#242628'}/>
</g>
<defs>
<clipPath id="clip0_4504_55538">
<rect width="25.6049" height="25" fill="white" transform="translate(0.460938 0.219727)"/>
</clipPath>
</defs>
</svg>
  )
}

export default ReportsIcon;