import { AxiosResponse } from 'axios'
import { http } from '../utils/http'

export const getRapports = async () => {
  try {
    const response = await http.get('/api/web/rapport')
    return response.data.items
  } catch (error) {
    return error
  }
}

export const getNotes = async (id: number) => {
  try {
    const response = await http.get(`/api/web/rapport/${id}/note`)
    return response.data.items
  } catch (error) {
    return error
  }
}

export const getHistory = async (id: number) => {
  try {
    const response = await http.get(`/api/web/rapport/${id}/history`)
    return response.data.items
  } catch (error) {
    return error
  }
}

export const getAntecedent = async (id: number) => {
  try {
    const response = await http.get(`/api/web/rapport/${id}/antecedent`)
    return response.data.items
  } catch (error) {
    return error
  }
}

export const newNote = async (
  id: number,
  note: string
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.post(`/api/web/rapport/${id}/note`, {
      note
    })

    return response
  } catch (error) {
    return error
  }
}

export const editNote = async (
  rapportId: number,
  noteId: number,
  note: string
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.patch(
      `/api/web/rapport/${rapportId}/note/${noteId}`,
      {
        note
      }
    )

    return response
  } catch (error) {
    return error
  }
}

export const deleteNote = async (
  rapportId: number,
  noteId: number
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.delete(
      `/api/web/rapport/${rapportId}/note/${noteId}`
    )

    return response
  } catch (error) {
    return error
  }
}
