import React from 'react'
import { Box, Modal, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { CustomModalProps } from '../utils/interfaces/props'

const CustomModal = (props: CustomModalProps) => {
  const { openModal, handleClose, imgSrc, title, description, submitLabel, onSubmit, showCancelButton, child, isSubmitDisable, showCrossCancelButton } = props

  return (
    <Modal open={openModal} onClose={() => handleClose}>
      <Box className='bg-white rounded-xl p-4 w-[600px] top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] absolute'>
        {showCrossCancelButton && <div className='flex w-full justify-end'>
          <IconButton aria-label="Cerrar" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>}
        <div className={`w-full flex flex-col justify-around items-center pr-8 pl-8 ${showCrossCancelButton ? 'pt-4' : 'pt-8'}`}>
          <div className='pb-4'>
            {imgSrc && <img src={imgSrc} alt="Imagen de modal"></img>}
          </div>
          <div className='font-montserrat not-italic font-bold text-xl text-pitch-black pb-4'>
            {title}
          </div>
          <div className='font-montserrat not-italic font-normal text-sm text-pitch-black text-center pb-4'>
            {description}
          </div>
          {child}
          <div className={`flex flex-row w-full ${showCancelButton ? 'justify-between' : 'justify-center'} items-center pt-6`}>
            {showCancelButton && <Button
              className='w-1/3'
              variant='outlined'
              color='secondary'
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>}
            <Button
              className='w-1/3'
              variant='contained'
              color='secondary'
              onClick={onSubmit}
              disabled={isSubmitDisable? isSubmitDisable : undefined}
            >
              {submitLabel}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default CustomModal
