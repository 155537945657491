import { alpha, Theme } from '@mui/material/styles'
import { TextFieldProps } from '@mui/material'
// ----------------------------------------------------------------------

const Input = (theme: Theme) => {
  const rootTextFieldStyle = (ownerState: TextFieldProps) => {
    const outlinedVariant = ownerState.variant === 'outlined'

    const defaultStyle = {
      fontFamily: '"Montserrat", sans-serif',
      '&:focus': {
        outline: 'none'
      },
      // OUTLINED
      ...(outlinedVariant && {
        '& .MuiInputLabel-root.Mui-focused': {
          color: theme.palette.grey[500]
        },
        '& .MuiInputLabel-shrink': {
          color: theme.palette.grey[500],
          backgroundColor: '#FAFAFA',
          paddingRight: '8px'
        },
        '& .MuiSelect-select': {
          color: '#FFF'
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #f5f5f5 inset',
            WebkitTextFillColor: '#011328'
          }
        },
        '&.white-background': {
          '& .MuiInputLabel-shrink': {
            color: theme.palette.grey[500],
            backgroundColor: '#FFF',
            paddingRight: '8px'
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px #FFF inset',
              WebkitTextFillColor: '#011328'
            }
          },
        },
      }),
    }

    const disabledState = {
      '&.Mui-disabled': {
        background: `${theme.palette.primary.darker}`,
        color: '#FFF'
      }
    }

    return [defaultStyle, disabledState]
  }

  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: '300',
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.01em',
          color: '#011328',
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.text.disabled
            }
          }
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56)
          },
          '&:after': {
            borderBottomColor: theme.palette.text.primary
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TextFieldProps }) =>
          rootTextFieldStyle(ownerState)
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16)
          },
          '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16)
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          '&:before, :after': {
            display: 'none'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32)
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: alpha(theme.palette.grey[500], 0.32)
            }
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        }
      }
    }
  }
}

export default Input
