import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';

export interface tab {
  label: string;
  id: string;
}
const TabsHeader = (props: {
  tabSelected: string;
  setTabSelected: Dispatch<SetStateAction<tab>>;
  tabs: tab[];
}) => {
  const { tabSelected, setTabSelected, tabs } = props;

  return (
    <Box className='flex flex-row w-[350px] rounded-lg justify-between bg-silver p-3'>
      {tabs.map((tab) => (
        <Button
          color='primary'
          variant={tabSelected === tab.id ? 'contained' : 'text'}
          type='submit'
          className='w-full'
          onClick={() => {
            console.log('HOLA');
            setTabSelected(tab);
          }}
          key={tab.id}
        >
          {tab.label}
        </Button>
      ))}
    </Box>
  );
};

export default TabsHeader;
