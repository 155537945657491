import axios, { AxiosResponse } from 'axios'
import jwt from 'jwt-decode'
import { authStore } from '../store/auth'

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL

const { accessToken } = authStore.getState()

if (accessToken) {
  axiosInstance.defaults.headers.common.accept = 'application/json'
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

authStore.subscribe((state) => {
  axiosInstance.defaults.headers.common.accept = 'application/json'
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`
})

axiosInstance.interceptors.request.use(async (request: any) => {
  // Get tokens from store
  const { accessToken, refreshToken } = authStore.getState()
  // Decode accessToken
  const token = accessToken && jwt(accessToken)
  const refresh_token = refreshToken && jwt(refreshToken)
  // Check if token is expired
  if (new Date() > new Date((token as any).exp * 1000) && new Date() < new Date((refresh_token as any).exp * 1000)) {
    try {
      // Make a request to get the new accessToken
      const newTokenResponse = await axios.post('/api/auth/refresh', {}, {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          Authorization: `Bearer ${refreshToken}`
        }
      })

      const newToken: string = newTokenResponse.data.accessToken
      // Update axios instance with the new token
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`
      // Update store with the new accessToken
      authStore.setState({ accessToken: newToken })
      // Update the current request with the new accessToken
      request.headers.Authorization = `Bearer ${newToken}`
      return request
    } catch (error) {
      return await Promise.reject(error)
    }
  } else {
    if (new Date() > new Date((refresh_token as any).exp * 1000)) {
      const { setLogout } = authStore.getState()
      setLogout()
      return await Promise.reject('Refresh token expired!')
    }
    return request
  }
})

axiosInstance.interceptors.response.use(async (response: AxiosResponse) => {
  if (
    response.config.url === '/api/auth/refresh'
    && response.status === 401
  ) {
    const { setLogout } = authStore.getState()
    setLogout()
    return await Promise.reject('Refresh token expired!')
  }
  return response
})

export const http = axiosInstance