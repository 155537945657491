import { GridColDef } from '@mui/x-data-grid'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import NothingToShow from '../../components/nothingToShow'
import { format } from 'date-fns'
import { AppointmentSlotType, RapportDetails } from '../../utils/constants'
import CustomDataGrid from '../../components/CustomDataGrid'


const RapportsHistoric = ({
  rapportDetails
}: {
  rapportDetails: RapportDetails
}) => {

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Fecha',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        const { appointmentDate } = cellValues.row
        const date = new Date(appointmentDate)
        return (
          <div className="font-montserrat text-base font-normal leading-27 tracking-normal text-left">
            {format(date, 'dd/MM/yy')}
          </div>
        )
      },
      sortable: false
    },
    {
      field: 'appointmentDateText',
      headerName: 'Hora',
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'specialty',
      headerName: 'Especialidad',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        const { specialty } = cellValues.row

        return (
          <div className="font-montserrat text-base font-normal leading-27 tracking-normal text-left">
            {specialty.title}
          </div>
        )
      },
      sortable: false
    },
    {
      field: 'appointmentType',
      headerName: 'Tipo de consulta',
      editable: false,
      flex: 1,
      renderCell: (cellValues) => {
        const { appointmentType } = cellValues.row

        return (
          <div className="font-montserrat text-base font-normal leading-27 tracking-normal text-left">
            {appointmentType === AppointmentSlotType.videocall
              ? 'Videollamada'
              : 'Presencial'}
          </div>
        )
      },
      sortable: false
    }
  ]

  const rows = rapportDetails.history.map((history: any) => {
    return {
      ...history,
      id: history.appointmentId
    }
  })
  
  return (
    <div className="mt-3 h-[495px] w-full">
      {rows.length > 0 ? (
        <CustomDataGrid
          rows={rows}
          rowHeight={64}
          columns={columns}
        />
      ) : (
        <NothingToShow
          nothingImg={nothingToShowImg}
          altText={'sin historial clínico de paciente'}
          nothingText={'No tenés nada que ver hasta el momento...'}
        />
      )}
    </div>
  )
}

export default RapportsHistoric
