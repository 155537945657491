import { alpha, Theme } from '@mui/material/styles'
import { ButtonProps } from '@mui/material'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// NEW VARIANT
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true
  }
}

export default function Button(theme: Theme) {

  const rootStyle = (ownerState: ButtonProps) => {
    const inheritColor = ownerState.color === 'inherit'

    const containedVariant = ownerState.variant === 'contained'

    const outlinedVariant = ownerState.variant === 'outlined'

    const softVariant = ownerState.variant === 'soft'

    const smallSize = ownerState.size === 'small'

    const largeSize = ownerState.size === 'large'

    const defaultStyle = {
      fontFamily: '"Montserrat", sans-serif',
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.grey[800],
          '&:hover': {
            color: theme.palette.primary.dark
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          borderColor: alpha(theme.palette.grey[500], 0.32),
          '&:hover': {
            borderColor: theme.palette.text.primary,
            backgroundColor: theme.palette.action.hover
          }
        }),
      })
    }

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          backgroundColor: `${theme.palette[color].main}`,
          '&:hover': {
            background: `${theme.palette[color].main}`,
          }
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette.text.primary,
          borderWidth: 2,
          borderColor: theme.palette[color].main,
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            borderWidth: 3,
            // borderColor: theme.palette[color].main,
            backgroundColor: theme.palette.common.white
          }
        }),
      })
    }))

    const disabledState = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        '&.Mui-disabled': {
          background: '#D3D3D3',
          color: theme.palette.common.black,
          // OUTLINED
          ...(outlinedVariant && {
            color: alpha(theme.palette.grey[500], 0.32),
            background: theme.palette.common.white,
            borderWidth: 3,
            borderColor: alpha(theme.palette.grey[500], 0.32),
          }),
        }
      })
    }))


    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
        ...(softVariant && {
          padding: '4px 10px'
        })
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 15,
        ...(softVariant && {
          padding: '8px 22px'
        })
      })
    }

    return [...colorStyle, defaultStyle, disabledState, size]
  }

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => rootStyle(ownerState)
      }
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[700],
          '& :hover': {
            borderRadius: '10px',
          },
          '& .Mui-selected': {
            borderRadius: '10px',
            backgroundColor: `${theme.palette.secondary.main} !important`,
          },
          '& .Mui-disabled': {
            color: theme.palette.grey[100],
            fontWeight: 'lighter'
          }
        }
      }
    }
  }
}
