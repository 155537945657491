import { useStore } from 'zustand'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../store/auth'
import { doctorStatuses } from '../../../../utils/constants'
import disponibilidad from '../../../../assets/disponibilidad.png'
import turnos from '../../../../assets/turnos.png'
import rapport from '../../../../assets/rapport.png'
import Shortcut from './Shortcut'
import NothingToShow from '../../../../components/nothingToShow'
import pendingApprovalImg from '../../../../assets/pending_approval-img.png'
import { Typography } from '@mui/material'
import { userState } from '../../../../utils/interfaces/stores'

const DoctorWelcome = () => {
  const navigate = useNavigate()
  const { user } = useStore(authStore)
  const { doctorStatus } = user as userState
  const { APPROVAL_PENDING } = doctorStatuses

  const shortcuts = [
    {
      title: 'Turnos',
      text: 'En esta sección encontrarás toda la información relacionada a los turnos con tus pacientes, fecha, hora y tipo de consulta.',
      action: () => navigate('/turnos', { replace: true }),
      image: turnos
    },
    {
      title: 'Rapport',
      text: 'En esta sección podrás acceder a la información detallada de cada uno de tus pacientes.',
      action: () => navigate('/rapports', { replace: true }),
      image: rapport
    },
    {
      title: 'Disponibilidad',
      text: 'En esta sección podrás cargar en detalle tu disponibilidad para consultas, editarla y consultar turnos ya agendados.',
      action: () => navigate('/availability', { replace: true }),
      image: disponibilidad
    }
  ]

  return (
    <div className="bg-silver h-full w-full pb-6">
      <Typography
        className={`p-10 pl-12 text-pitch-black tracking-wider`}
        fontSize={28}
        fontWeight='bold'
      >
        ¡Bienvenido!
      </Typography>
      {doctorStatus === APPROVAL_PENDING ? (
        <NothingToShow
          nothingImg={pendingApprovalImg}
          altText={'pendiente de aprobación'}
          nothingText={'Cuenta pendiente de aprobación'}
          nothingSubText={
            'Actualmente tu cuenta esta en proceso de aprobación. En cuanto sea aprobada, podrás acceder a todas las funcionalidades del sitio.'
          }
        />
      ) : (
        <div className="flex flex-row w-full justify-between px-24 mt-6">
          {shortcuts.map((shortcutData) => {
            const { title, text, action, image } = shortcutData
            return (
              <Shortcut
                key={title}
                title={title}
                text={text}
                action={action}
                image={image}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default DoctorWelcome
