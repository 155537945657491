import { Menu, Typography } from '@mui/material'
import React from 'react'
import { format } from 'date-fns'
import { PopUpProps } from '../../utils/interfaces/props'
import NoNotifications from './noNotifications'

const NotificationsPopUp = ({
  options,
  open,
  handleClose,
  anchorEl,
  navigate,
}: PopUpProps) => {
  const now = new Date()

  return (
    <Menu
      id='basic-menu'
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      sx={{
        marginTop: 5,
        '& .MuiList-root': {
          backgroundColor: 'white',
          border: 'transparent 1px black',
          paddingTop: 'unset',
          paddingBottom: 'unset',
        },
        '& .MuiMenu-paper': {
          backgroundColor: 'white',
          border: 'transparent 1px black',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {options.length > 0 ? (
        options.map((option: any) => {
          const hoursSinceNotificationWasCreated = Math.floor(
            (now.getTime() - option.createdAt) / 3600000
          )
          const dateOfNotification = new Date(option.createdAt)
          const dateString =
            hoursSinceNotificationWasCreated <= 24
              ? hoursSinceNotificationWasCreated.toString() + ' hs'
              : format(dateOfNotification, 'dd/MM')
          return (
            <div
              key={option.id}
              className={`flex w-[450px] px-6 py-3 ${option.read ? 'bg-white' : 'bg-aqua-transparent'
                } border-b border-solid border-secondary-light-grey cursor-pointer`}
              onClick={() => {
                handleClose()
                navigate('/notifications', { replace: true })
              }}
            >
              <div className='flex flex-col w-4/5'>
                <Typography
                  className='text-pitch-black'
                  fontWeight='bold'
                  fontSize={14}
                >
                  {option.title}
                </Typography>
                <div className='mt-2'>
                  <Typography
                    className='line-clamp-2 text-light-grey'
                    fontSize={14}
                  >
                    {option.description}
                  </Typography>
                </div>
              </div>
              <div className='flex w-1/5 justify-end'>
                <Typography className='text-light-grey' fontSize={14}>
                  {dateString}
                </Typography>
              </div>
            </div>
          )
        })
      ) : (
        <NoNotifications
          navigationFunction={() =>
            navigate('/notifications', { replace: true })
          }
          containerClasses={'p-6 items-center justify-center cursor-pointer'}
          fontSize={13}
          imgClasses={'w-[400px] h-[400px]'}
        />
      )}
    </Menu>
  )
}

export default NotificationsPopUp
