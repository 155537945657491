// ----------------------------------------------------------------------

export default function Drawer() {
  return {
    MuiDrawer: {
      styleOverrides: {
        root: () => ({
          '& .MuiDrawer-paper': {
            backgroundColor: 'white'
          },
        }),
      },
    },
  }
}
