import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Button, TextField, InputAdornment } from '@mui/material'
import MedappLogo from '../../assets/logo-completo.png'
import { useNavigate } from 'react-router-dom'
import { recoverNewPassword } from '../../services/auth'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlertStore } from '../../store/alertStore'
import { useStore } from 'zustand'
import { authStore } from '../../store/auth'

const ChangePassword = () => {
  const navigate = useNavigate()
  const { setAlert } = useAlertStore()
  const [newPasswordIsHidden, setNewPasswordIsHidden] = useState<boolean>(true)
  const [repeatPasswordIsHidden, setRepeatPasswordIsHidden] =
    useState<boolean>(true)

  const { recoveryToken } = useStore(authStore)

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&Ññ]{8,16}$/

  interface PasswordFormValues {
    newPassword: string
    repeatPassword: string
  }

  const validationSchema: Yup.Schema<PasswordFormValues> = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        passwordRegex,
        'La contraseña no respeta el formato o la extensión necesaria'
      )
      .required('Requerido'),

    repeatPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Las contraseñas deben coincidir')
      .required('Requerido'),
  })

  const initialValues = {
    newPassword: '',
    repeatPassword: '',
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { newPassword, repeatPassword } = values
      const response: any = await recoverNewPassword(
        newPassword,
        repeatPassword,
        recoveryToken
      )
      if (response.status !== 200) {
        setAlert({
          isOpen: true,
          message:
            'Error en la restauración, por favor verifique los datos ingresados',
          severity: 'error',
        })
        return
      }

      if (response.status === 200) {
        setAlert({
          isOpen: true,
          message: 'Se restauró la contraseña, por favor inicia sesión',
          severity: 'success',
        })
        navigate('/login', { replace: true })
      }
    },
  })

  useEffect(() => {
    validateForm()
  }, [validateForm])

  return (
    <div className='flex flex-row justify-center items-center w-full h-screen log-cont bg-login-first bg-no-repeat bg-cover'>
      <div className='flex flex-col justify-around items-center bg-white md:px-16 px-2 md:py-12 mx-4 md:w-2/5 max-w-lg rounded-xl'>
        <img
          src={MedappLogo}
          alt='medapp login logo'
          className='login-logo mb-8'
        ></img>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col w-full items-center'
        >
          <div className='w-full mt-4'>
            <div className='pb-4'>
              <TextField
                label='Nueva contraseña'
                variant='outlined'
                autoComplete='off'
                className='w-full white-background'
                type={newPasswordIsHidden ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {newPasswordIsHidden ? (
                        <VisibilityIcon
                          sx={{ cursor: 'pointer' }}
                          color='disabled'
                          onClick={() =>
                            setNewPasswordIsHidden((prev) => !prev)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{ cursor: 'pointer' }}
                          color='disabled'
                          onClick={() =>
                            setNewPasswordIsHidden((prev) => !prev)
                          }
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                id='newPassword'
                name='newPassword'
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  !!errors.newPassword && touched.newPassword !== undefined
                }
                helperText={
                  !!errors.newPassword &&
                  touched.newPassword !== undefined &&
                  errors.newPassword
                }
              />
            </div>
            <div>
              <TextField
                label='Repetir contraseña'
                variant='outlined'
                className='w-full white-background'
                autoComplete='off'
                type={repeatPasswordIsHidden ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {repeatPasswordIsHidden ? (
                        <VisibilityIcon
                          sx={{ cursor: 'pointer' }}
                          color='disabled'
                          onClick={() =>
                            setRepeatPasswordIsHidden((prev) => !prev)
                          }
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{ cursor: 'pointer' }}
                          color='disabled'
                          onClick={() =>
                            setRepeatPasswordIsHidden((prev) => !prev)
                          }
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                id='repeatPassword'
                name='repeatPassword'
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  !!errors.repeatPassword &&
                  touched.repeatPassword !== undefined
                }
                helperText={
                  !!errors.repeatPassword &&
                  touched.repeatPassword !== undefined &&
                  errors.repeatPassword
                }
              />
            </div>
          </div>
          <div className='md:mb-0 mb-4 mt-12 w-full'>
            <Button
              color='secondary'
              variant='contained'
              type='submit'
              className='w-full'
              disabled={!isValid}
            >
              Confirmar
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
