import { TextField, Button, Switch } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { CustomSelect } from '../../components/CustomSelect'
import { updateAvailability, getAvailability } from '../../services/doctor'
import { getCities, getProvinces } from '../../services/inputsData'
import { useAlertStore } from '../../store/alertStore'
import { useDoctorStore } from '../../store/doctor'
import { useInputDataStore } from '../../store/inputDataStore'

const commonSwitchStyles = {
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
  },
  '& .MuiSwitch-switchBase.Mui-checked+': {
    backgroundColor: '#E3ED61',
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#000000',
    opacity: 0.38,
  },
}

const AvailabilityScreen = () => {
  const { provinces, setProvinces, cities, setCities } = useInputDataStore()
  const [isLoading, setIsloading] = useState<boolean>(false)
  const { setAvailability } = useDoctorStore()
  const { setAlert } = useAlertStore()

  useEffect(() => {
    const apiCall = async () => {
      if (!provinces || provinces.length === 0) {
        const data = await getProvinces()
        setProvinces(data)
      }
    }

    setIsloading(true)
    apiCall().then(() => setIsloading(false))
  }, [setProvinces, provinces])

  const validationSchema = Yup.object().shape({
    presentialAppointments: Yup.boolean(),
    homeAppointments: Yup.boolean(),
    street: Yup.string().when('presentialAppointments', {
      is: true,
      then: (schema) => schema.required('Campo requerido'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    streetNumber: Yup.number().when('presentialAppointments', {
      is: true,
      then: (schema) => schema.positive('Ingrese un número válido'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    floorApartment: Yup.string().nullable().notRequired(),
    provinceId: Yup.string().when('presentialAppointments', {
      is: true,
      then: (schema) => schema.not(['']).required('Seleccione una provincia'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    cityId: Yup.string().when('presentialAppointments', {
      is: true,
      then: (schema) => schema.not(['']).required('Seleccione una ciudad'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
    videocallAppointments: Yup.boolean(),
  })

  const initialValues = {
    presentialAppointments: false,
    homeAppointments: false,
    street: '',
    streetNumber: '',
    floorApartment: '',
    provinceId: '',
    cityId: '',
    videocallAppointments: false,
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      setFieldTouched('provinceId', false)
      const {
        presentialAppointments,
        homeAppointments,
        street,
        streetNumber,
        floorApartment,
        provinceId,
        cityId,
        videocallAppointments,
      } = values

      const data: any = {
        presentialAppointments,
        homeAppointments,
        videocallAppointments,
      }

      if (values.street) {
        data.doctorAddress = {
          street,
          streetNumber: parseInt(streetNumber),
          floorApartment,
          provinceId,
          cityId,
        }
      }

      const response = await updateAvailability(data)

      if (response.status !== 201) {
        setAlert({
          isOpen: true,
          message:
            'Error en la actualización, por favor verifique los datos ingresados',
          severity: 'error',
        })
        return
      }

      if (response.status === 201) {
        setAvailability(response.data)
        setAlert({
          isOpen: true,
          message: 'Datos actualizados',
          severity: 'success',
        })
      }
    },
  })

  useEffect(() => {
    const apiCall = async () => {
      const data = await getAvailability()
      setAvailability(data)
      setFieldValue('presentialAppointments', data.presentialAppointments)
      setFieldValue('homeAppointments', data.homeAppointments)
      setFieldValue('videocallAppointments', data.videocallAppointments)
      if (data.doctorAddress) {
        setFieldValue('street', data.doctorAddress.street)
        setFieldValue('streetNumber', data.doctorAddress.streetNumber)
        setFieldValue('floorApartment', data.doctorAddress.floorApartment)
        setFieldValue('provinceId', data.doctorAddress.province.id)
        setFieldValue('cityId', data.doctorAddress.city.id)
      }
    }

    setIsloading(true)
    apiCall().then(() => {
      setIsloading(false)
    })
  }, [setAvailability, setFieldValue])

  useEffect(() => {
    validateForm()
  }, [validateForm])

  useEffect(() => {
    const apiCall = async () => {
      const data = await getCities(values.provinceId)
      setCities(data)
      if (touched.provinceId) setFieldValue('cityId', '')
      setFieldTouched('cityId', false)
    }

    if (values.provinceId && !isSubmitting) {
      apiCall()
    }
  }, [
    setCities,
    values.provinceId,
    setFieldValue,
    setFieldTouched,
    touched.provinceId,
    isSubmitting,
  ])

  if (isLoading)
    return (
      <div className='flex flex-row h-full w-full pt-28 pl-10'>
        <p>Is Loading...</p>
      </div>
    )

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex flex-row h-full w-full pt-28 pl-10'>
        <div className='flex flex-col w-4/5 h-full'>
          <div className='flex flex-col mb-6'>
            <div className='font-montserrat font-normal text-xl tracking-wider text-pitch-black pb-4'>
              Disponibilidad
            </div>
            <p className='font-montserrat text-sm text-black font-normal'>
              Debajo podras indicar tu disponibilidad y tarifas las cuales serán
              transparentes frente a los pacientes al momento de reservar un
              turno.
            </p>
          </div>
          <div className='flex flex-col pb-4'>
            <div className='flex flex-row'>
              <div className='font-montserrat text-lg text-black font-medium pb-4 pr-8'>
                Consultas por videollamada
              </div>
              <Switch
                sx={commonSwitchStyles}
                color='secondary'
                id='videocallAppointments'
                name='videocallAppointments'
                checked={values.videocallAppointments}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className='flex flex-col pb-4'>
            <div className='flex flex-row'>
              <div className='font-montserrat text-lg text-black font-medium pb-4 pr-8'>
                Consultas a domicilio
              </div>
              <Switch
                sx={commonSwitchStyles}
                color='secondary'
                id='homeAppointments'
                name='homeAppointments'
                checked={values.homeAppointments}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className='flex flex-col  pb-10'>
            <div className='flex flex-row'>
              <div className='font-montserrat text-lg text-black font-medium pb-4 pr-8'>
                Consultas presenciales
              </div>
              <Switch
                sx={commonSwitchStyles}
                color='secondary'
                id='presentialAppointments'
                name='presentialAppointments'
                checked={values.presentialAppointments}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {values.presentialAppointments && (
              <div className='flex w-full flex-col'>
                <ul className='flex flex-col pl-3 pb-4'>
                  <li className='font-montserrat font-normal text-black text-xs pb-4 list-disc'>
                    Para activar esta opción, debes completar los campos a
                    continuación.
                  </li>
                  <li className='font-montserrat font-normal text-black text-xs pb-4 list-disc'>
                    Para atender de forma particular, debes completar en la
                    sección de pagos el valor de la consulta. De manera
                    contrario no aparecerás en las búsquedas.
                  </li>
                  <li className='font-montserrat font-normal text-black text-xs pb-4 list-disc'>
                    Al modificar alguno de los siguientes campos, los pacientes
                    con reservas presenciales serán notificados y podrían actuar
                    en consecuencia.
                  </li>
                </ul>
                <div className='flex flex-col w-full'>
                  <div className='flex flex-row w-full justify-between'>
                    <div className='pr-6 pb-4'>
                      <TextField
                        label='Calle'
                        variant='outlined'
                        className='w-full'
                        id='street'
                        name='street'
                        type='text'
                        value={values.street}
                        error={!!errors.street && touched.street !== undefined}
                        helperText={
                          !!errors.street && touched.street && errors.street
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className=' pr-6 pb-4'>
                      <TextField
                        label='Altura'
                        variant='outlined'
                        className='w-full'
                        id='streetNumber'
                        name='streetNumber'
                        type='number'
                        value={values.streetNumber}
                        error={
                          !!errors.streetNumber &&
                          touched.streetNumber !== undefined
                        }
                        helperText={
                          !!errors.streetNumber &&
                          touched.streetNumber &&
                          errors.streetNumber
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className=' pr-6 pb-4'>
                      <TextField
                        label='Piso/Depto'
                        variant='outlined'
                        className='w-full'
                        id='floorApartment'
                        name='floorApartment'
                        type='text'
                        value={values.floorApartment}
                        error={
                          !!errors.floorApartment &&
                          touched.floorApartment !== undefined
                        }
                        helperText={
                          !!errors.floorApartment &&
                          touched.floorApartment &&
                          errors.floorApartment
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className='flex flex-row w-full'>
                    {provinces && (
                      <div className='w-full pr-6 pb-4'>
                        <CustomSelect
                          label='Provincia'
                          placeholder='Provincia'
                          id='provinceId'
                          name='provinceId'
                          options={provinces}
                          value={values.provinceId as string}
                          error={!!errors.provinceId && touched.provinceId}
                          helperText={
                            errors.provinceId &&
                            touched.provinceId !== undefined &&
                            (errors.provinceId as string)
                          }
                          onChange={(event) => {
                            handleChange(event)
                            handleBlur(event)
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                    {cities && cities.length > 0 && (
                      <div className='w-full pr-6 pb-4'>
                        <CustomSelect
                          disabled={!values.provinceId}
                          label='Ciudad'
                          placeholder='Ciudad'
                          id='cityId'
                          name='cityId'
                          options={cities}
                          value={values.cityId as string}
                          error={!!errors.cityId && touched.cityId}
                          helperText={
                            !!errors.cityId && touched.cityId && errors.cityId
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className='w-full flex flex-row justify-end'>
            <Button
              type='submit'
              color='secondary'
              variant='contained'
              disabled={!isValid}
            >
              Guardar cambios
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AvailabilityScreen
