import { alpha } from '@mui/system'
import { styled } from '@mui/material/styles'
import { MonthView } from '@devexpress/dx-react-scheduler-material-ui'
import { classes } from '../common'

const StyledScaleCell = styled(MonthView.DayScaleCell)(({ theme }) => ({
  '.Cell-dayOfWeek': {
    color: '#242628',
    textTransform: 'capitalize',
    backgroundColor: '#FFFFFF'
  }
}))

export const DayScaleCell = (props: MonthView.DayScaleCellProps) => {
  return <StyledScaleCell {...props} />
}

export const StyledMonthViewTimeTableCell = styled(MonthView.TimeTableCell)(
  ({ theme }) => ({
    [`&.${classes.weekdayCell}`]: {
      backgroundColor: '#FFFFFF',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: alpha('#FFFFFF', 0.14)
      },
      '&:focus': {
        backgroundColor: alpha('#FFFFFF', 0.16)
      }
    },
    [`&.${classes.weekendCell}`]: {
      backgroundColor: '#D3D3D3',
      borderColor: '#D3D3D3',
      '&:hover': {
        backgroundColor: '#D3D3D3'
      },
      '&:focus': {
        backgroundColor: '#D3D3D3'
      }
    }
  })
)


