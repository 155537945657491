import { Box, IconButton } from '@mui/material'
import { MouseEvent, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreOptionsModal from './MoreOptionsModal'
import { MoreOptionsProps } from '../../utils/interfaces/props'

export const MoreOptions = ({ optionsData }: MoreOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className="flex flex-col items-end">
      <IconButton
        onClick={(event) => {
          handleClick(event)
        }}
        aria-label="more"
      >
        <MoreVertIcon />
      </IconButton>
      <MoreOptionsModal
        open={open}
        handleClose={() => handleClose()}
        options={optionsData}
        anchorEl={anchorEl}
      />
    </Box>
  )
}
