import { useLocation, Navigate } from 'react-router-dom';
import { useStore } from 'zustand';
import { authStore } from '../store/auth';
import { UserTypes } from '../utils/constants';
import { userState } from '../utils/interfaces/stores';

const isAuthorized = (authRoles: string[], userRole: string) => {
  const isValid = authRoles.some((role) => role === userRole);
  if (!isValid) {
    return false;
  }
  return true;
};

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const { user, doctor: secretaryAsDoctor } = useStore(authStore);
  const { pathname: location } = useLocation();

  if (user === null) {
    return <Navigate to='/login' replace />;
  }

  const { role } = user as userState;
  const { admin, doctor, superAdmin, secretary } = UserTypes;
  const secondParameter = location.split('/')[2];
  const thirdParameter = location.split('/')[3];

  switch (location) {
    case '/logout':
    case '/create-account':
    case '/verify-email':
    case '/recovery-password':
    case '/change-password':
    case '/mercado-pago/callback':
      break;
    case '/profile':
    case '/welcome':
    case '/notifications':
      if (!isAuthorized([admin, superAdmin, doctor, secretary], role)) {
        return <Navigate to='/logout' replace />;
      }
      break;
    case '/availability':
    case '/turnos':
    case '/rapports':
    case '/link-secretaries':
    case `/rapports/${secondParameter}`:
    case '/video-room':
      if (!isAuthorized([doctor], role) && !secretaryAsDoctor) {
        return <Navigate to='/welcome' replace />;
      }
      break;
    case '/admin/reports':
    case '/admin/doctors':
    case `/admin/doctors/${thirdParameter}`:
      if (!isAuthorized([admin, superAdmin], role)) {
        return <Navigate to='/welcome' replace />;
      }
      break;
    case '/admin/admins':
      if (!isAuthorized([superAdmin], role)) {
        return <Navigate to='/welcome' replace />;
      }
      break;
    default:
      break;
  }

  return children;
};

export default AuthProvider;
