import { http } from '../utils/http'
import { setHours, setMinutes, addDays } from 'date-fns'

export const getPatientReports = async (
  patientId: number,
  startDate?: Date,
  endDate?: Date,
  includeNoHealthCoverage?: boolean,
  appointmentStatus?: string[],
  appointmentType?: string[],
  healthCoverageIds?: number[]
) => {
  const newStartDate =
    startDate !== undefined ? setHours(setMinutes(startDate, 0), 0) : startDate
  const newEndDate =
    endDate !== undefined
      ? addDays(setHours(setMinutes(endDate, 0), 0), 1)
      : endDate

  try {
    const response = await http.get(
      `/api/web/appointment/patient/${patientId}`,
      {
        params: {
          startDate: newStartDate,
          endDate: newEndDate,
          appointmentStatus,
          includeNoHealthCoverage,
          appointmentType,
          healthCoverageIds
        }
      }
    )
    return response.data
  } catch (error) {
    return error
  }
}

export const getDoctorReports = async (
  drId: number,
  startDate?: Date,
  endDate?: Date,
  includeNoHealthCoverage?: boolean,
  includeHealthCoverage?: boolean,
  appointmentStatus?: string[],
  appointmentType?: string[],
  healthCoverageIds?: number[]
) => {
  const newStartDate =
    startDate !== undefined ? setHours(setMinutes(startDate, 0), 0) : startDate
  const newEndDate =
    endDate !== undefined ? setHours(setMinutes(endDate, 0), 0) : endDate

  try {
    const response = await http.get(`/api/web/appointment/doctor/${drId}`, {
      params: {
        startDate: newStartDate,
        endDate: newEndDate,
        appointmentStatus,
        includeNoHealthCoverage,
        includeHealthCoverage,
        appointmentType,
        healthCoverageIds
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}
