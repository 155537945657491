import { Theme } from '@mui/material/styles'

export default function DatePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        inputFormat: 'dd/MM/yyyy'
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          color: theme.palette.grey[700]
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          "&.Mui-selected": {
            backgroundColor: `${theme.palette.secondary.main} !important`,
          },
        }
      }
    }
  }
}
