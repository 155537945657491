import React from 'react';
import PersonalDataScreen from '../../medicalProfile/personalDataScreen';
import { Layout } from '../../../components/Layout';

const SecretaryProfileScreen = () => {
  return (
    <Layout>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col w-full items-start justify-start pl-2 pr-2'>
          <div
            className={`font-montserrat p-10 pl-12 text-pitch-black font-bold text-2xl tracking-wider`}
          >
            Perfil
          </div>
        </div>
        <div className='flex w-full'>
          <PersonalDataScreen smallPadding />
        </div>
      </div>
    </Layout>
  );
};

export default SecretaryProfileScreen;
