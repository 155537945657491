import { useEffect } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import MedappLogo from '../../assets/logo-completo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  verifyEmail,
  sendNewCodeVerifyEmail,
  recoverCheckCode,
} from '../../services/auth';
import { useStore } from 'zustand';
import { authStore } from '../../store/auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAlertStore } from '../../store/alertStore';
import { userState } from '../../utils/interfaces/stores';

const VerifyEmailScreen = () => {
  const { setAlert } = useAlertStore();
  const location = useLocation();
  const { state }: { state: any } = location;
  const navigate = useNavigate();

  const {
    verifyToken,
    setRecoveryToken,
    setUser,
    setAccessToken,
    setRefreshToken,
    setVerifyToken,
  } = useStore(authStore);

  const initialValues = {
    code: '',
  };

  const validationSchema = Yup.object({
    code: Yup.string()
      .matches(/^[0-9]+$/, 'Debe ingresar únicamente dígitos')
      .length(5, 'La extensión debe ser de cinco dígitos')
      .required('Es obligatorio ingresar el código'),
  });

  const submitRecoverCheckCode = async (code: string) => {
    const response: any = await recoverCheckCode(state?.email, code);
    if (response.status !== 200) {
      setAlert({
        isOpen: true,
        message: 'El código ingresado no es válido',
        severity: 'error',
      });
      return;
    }
    if (response.status === 200) {
      setRecoveryToken(response.data.recoveryToken);
      navigate('/change-password', {
        replace: true,
      });
    }
  };

  const submitVerifyEmail = async (code: string) => {
    const response: any = await verifyEmail(code, verifyToken as string);
    if (response.status !== 200) {
      setAlert({
        isOpen: true,
        message: 'El código ingresado no es valido',
        severity: 'error',
      });
      return;
    }
    if (response.status === 200) {
      const {
        firstName,
        lastName,
        userId,
        email,
        type,
        phone,
        licence,
        doctorStatus,
      } =
        response.data.doctor || response.data.admin || response.data.secretary;

      setUser({
        firstName,
        lastName,
        email,
        phone,
        id: userId,
        role: type,
        licence,
        doctorStatus,
      } as userState);

      if (response.data.accessToken) {
        const { accessToken, refreshToken } = response.data;
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
      }
      setVerifyToken(null);
      navigate('/welcome', { replace: true });
      setAlert({
        isOpen: true,
        message: '¡Verificación exitosa!',
        severity: 'success',
      });
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      state?.email
        ? submitRecoverCheckCode(values.code.toString())
        : submitVerifyEmail(values.code.toString());
    },
  });

  useEffect(() => {
    if (!verifyToken && !state?.email) {
      navigate('/login', { replace: true });
    }

    validateForm();
  }, [validateForm, verifyToken, navigate, state]);

  return (
    <div className='flex flex-row justify-center items-center w-full min-h-screen h-auto log-cont bg-login-first bg-no-repeat bg-cover py-4'>
      <div className='flex flex-col justify-around items-center bg-white md:px-12 px-2 md:py-6 mx-4 md:w-2/5 max-w-lg rounded-xl'>
        <img
          src={MedappLogo}
          alt='medapp login logo'
          className='login-logo mb-8'
        ></img>
        <div className='flex justify-start w-full mb-4'>
          <Typography fontSize={18} fontWeight='bold'>
            {state?.email
              ? 'Código de recuperación de contraseña'
              : 'Ingresá el código'}
          </Typography>
        </div>
        <div className='flex justify-start w-full mb-4'>
          <Typography fontSize={14}>
            {state?.email
              ? 'Ingresá el código de recuperación de 5 dígitos que recibiste por email'
              : 'Te enviamos al email un código de activación que tendrás que ingresar para comenzar a usar la app'}
          </Typography>
        </div>
        <form
          onSubmit={handleSubmit}
          className='flex flex-col w-full items-center mt-8'
        >
          <div className='w-full'>
            <TextField
              label='Código'
              variant='outlined'
              id='code'
              type='text'
              size='small'
              className='w-full white-background'
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete='off'
              error={!!errors.code && touched.code}
              helperText={
                !!errors.code && touched.code !== undefined && errors.code
              }
            />
          </div>
          {!state?.email && (
            <div className='flex w-full justify-start mt-4'>
              <Typography fontSize={14}>¿No recibiste el email?</Typography>
              <Typography
                fontSize={14}
                fontWeight='bold'
                className='pl-1 cursor-pointer'
                onClick={() => sendNewCodeVerifyEmail(verifyToken as string)}
              >
                Reenviar
              </Typography>
            </div>
          )}
          <div className='md:mb-0 mb-4 mt-12 w-full'>
            <Button
              color='secondary'
              variant='contained'
              className='w-full'
              type='submit'
              disabled={!isValid}
            >
              Continuar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyEmailScreen;
