import { IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { RemoteParticipant } from 'twilio-video'
import { Participant } from './Participant'
import { RoomProps } from '../../utils/interfaces/props'
import VideoActive from '../../assets/video-active.png'
import VideoInactive from '../../assets/video-inactive.png'
import AudioActive from '../../assets/audio-active.png'
import AudioInactive from '../../assets/audio-inactive.png'
import IconCall from '../../assets/icon-call.png'
import { LocalParticipant } from './LocalParticipant'

export const VideoRoom = (props: RoomProps) => {
  const { room, returnToLobby, patient } = props
  const [videoEnabled, setVideoEnabled] = useState<boolean>(true)
  const [audioEnabled, setAudioEnabled] = useState<boolean>(true)

  const [remoteParticipant, setRemoteParticipant] = useState(
    Array.from(room.participants.values())
  )

  const leaveRoom = () => {
    room.disconnect()
    returnToLobby()
  }

  useEffect(() => {
    const addParticipant = (participant: RemoteParticipant) => {
      console.log(`${participant.identity} has joined the room.`)
      setRemoteParticipant([participant])
    }

    const removeParticipant = (participant: RemoteParticipant) => {
      console.log(`${participant.identity} has left the room`)
      setRemoteParticipant([])
    }

    room.on('participantConnected', (participant) => {
      addParticipant(participant)
    })

    room.on('participantDisconnected', (participant) => {
      removeParticipant(participant)
    })

    window.addEventListener('beforeunload', () => leaveRoom())
    return () => {
      room.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room])

  const mute = () => {
    room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.disable()
      setAudioEnabled(false)
    })
  }

  const unmute = () => {
    room.localParticipant.audioTracks.forEach((publication) => {
      publication.track.enable()
      setAudioEnabled(true)
    })
  }

  const disableVideo = () => {
    room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.disable()
      setVideoEnabled(false)
    })
  }

  const enableVideo = () => {
    room.localParticipant.videoTracks.forEach((publication) => {
      publication.track.enable()
      setVideoEnabled(true)
    })
  }

  return (
    <div className="flex items-center flex-col w-[100%] h-[100%]">
      <div className="relative flex flex-col w-[1280px] h-[720px] bg-videocall justify-center items-center overflow-hidden rounded-md shadow-lg">
        <div className="w-full h-[100px] absolute left-0 top-0 bg-gradient-to-b from-gray-600 to-transparent bg-opacity-70 pt-3 pl-6 z-10">
          <Typography className="font-montserrat font-medium text-18 leading-22 text-start text-white">
            {patient}
          </Typography>
        </div>

        {remoteParticipant.length > 0 ? (
          remoteParticipant.map((participant: RemoteParticipant) => (
            <Participant key={participant.identity} participant={participant} />
          ))
        ) : (
          <Typography className="font-montserrat text-base font-normal leading-5 tracking-tighter text-center">
            El paciente no esta en la sala. Aguardá a su ingreso para continuar
            con la videollamada.
          </Typography>
        )}

        <LocalParticipant
          key={room.localParticipant.identity}
          participant={room.localParticipant}
          cameraEnabled={videoEnabled}
        />

        <div className="flex flex-row absolute justify-center w-full space-x-12 bottom-3">
          {videoEnabled ? (
            <IconButton id="disable-video" onClick={() => disableVideo()}>
              <img alt="video-active" src={VideoActive}></img>
            </IconButton>
          ) : (
            <IconButton id="enable-video" onClick={() => enableVideo()}>
              <img alt="video-inactive" src={VideoInactive}></img>
            </IconButton>
          )}
          {audioEnabled ? (
            <IconButton id="disable-audio" onClick={() => mute()}>
              <img alt="audio-active" src={AudioActive}></img>
            </IconButton>
          ) : (
            <IconButton id="enable-audio" onClick={() => unmute()}>
              <img alt="audio-inactive" src={AudioInactive}></img>
            </IconButton>
          )}
          <IconButton id="leaveRoom" onClick={() => leaveRoom()}>
            <img alt="icon-call" src={IconCall}></img>
          </IconButton>
        </div>
      </div>
    </div>
  )
}
