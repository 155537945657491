export default function Accordion () {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#1b2130',
          '&.Mui-expanded': {
            backgroundColor: '#1b2130',
            margin: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#1b2130'
        },
        expandIconWrapper: {
          color: 'inherit'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#1b2130'
        }
      }
    }
  }
}
