import { Box } from '@mui/material'
import { createRef, RefObject, useEffect } from 'react'

interface TrackProps {
  track: any
}

export const Track = (props: TrackProps) => {
  const { track } = props

  const ref: RefObject<any> = createRef()

  useEffect(() => {
    if (track !== null) {
      const child = track.attach()
      ref.current.classList.add(track.kind)
      ref.current.replaceChildren(child)
    }
  }, [ref, track])

  return (
    <Box
      sx={{
        transform: 'scale(-1, 1)'
      }}
      ref={ref}
    ></Box>
  )
}
