import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'zustand';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppBar } from './AppBar';
import MedappLogo from '../assets/medapp-logo.png';
import UserAvatar from './UserAvatar';
import { authStore } from '../store/auth';
import { useState, MouseEvent } from 'react';
import NotificationsPopUp from '../pages/notifications/NotificationsPopUp';
import { userState } from '../utils/interfaces/stores';
import { useNotificationsStore } from '../store/notificationsStore';
import { getDisplayRole } from '../utils/getDisplayRole';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export const CustomToolbar = ({ open }: { open: boolean }) => {
  const navigate = useNavigate();
  const { user, doctor: secretaryAsDoctor, setDoctor } = useStore(authStore);
  const { firstName, lastName, role, profilePictureUrl } = user as userState;
  const { notifications, hasUnreadNotifications } = useNotificationsStore();
  const nameInitial: string = firstName[0];
  const lastNameInitial: string = lastName[0];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPopUp = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    // @ts-expect-error
    <AppBar open={open}>
      <div
        className={`flex flex-row h-24 bg-silver justify-between items-center`}
      >
        <div className='w-56 flex justify-center items-center'>
          <img
            className='cursor-pointer w-32 h-4'
            alt='logo'
            src={MedappLogo}
            onClick={() => navigate('/welcome', { replace: true })}
          ></img>
        </div>
        <div className='flex flex-row w-full justify-center items-center gap-4'>
          {secretaryAsDoctor && (
            <>
              <div className='flex flex-col items-center'>
                <p className='text-black text-sm'>Conectado como</p>
                <p className='capitalize pr-2 text-black text-sm'>
                  Profesional: {secretaryAsDoctor?.firstName}{' '}
                  {secretaryAsDoctor?.lastName} - {secretaryAsDoctor.licence}
                </p>
              </div>
              <div>
                <LogoutOutlinedIcon
                  className='text-black cursor-pointer'
                  onClick={() => setDoctor(null)}
                />
              </div>
            </>
          )}
        </div>
        <div className='flex flex-row justify-end items-center pr-6'>
          <div className='flex flex-col w-full'>
            <div className='flex flex-row w-full'>
              <div className='capitalize pr-2 text-black'>{firstName}</div>
              <div className='capitalize text-black'>{lastName}</div>
            </div>
            <div className='flex w-full justify-end text-sm text-aqua-intense'>
              {getDisplayRole(role)}
            </div>
          </div>
          <div className='pt-6 pb-6 pr-2 pl-2'>
            <IconButton
              onClick={() => navigate('/profile', { replace: true })}
              aria-label='more'
            >
              <UserAvatar
                imageUrl={profilePictureUrl}
                altText={`${nameInitial}${lastNameInitial}`}
                size={33}
              />
            </IconButton>
          </div>
          <IconButton onClick={handleClick}>
            <NotificationsIcon className='text-black cursor-pointer relative' />
            {hasUnreadNotifications && (
              <i className='absolute h-2 w-2 text-center rounded-full bg-red-error top-2 right-5'></i>
            )}
          </IconButton>
          <NotificationsPopUp
            open={openPopUp}
            options={
              notifications && notifications.length > 0
                ? notifications.slice(0, 3)
                : []
            }
            handleClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
            navigate={navigate}
          />
        </div>
      </div>
    </AppBar>
  );
};
