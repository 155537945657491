import React from 'react';

const RapportIcon = ({ fill } : {fill?: string}) => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3795_40767)">
        <path d="M26.4725 7.554C26.4725 3.50139 23.1867 0.219727 19.1382 0.219727C16.7347 0.219727 14.6074 1.38233 13.2716 3.16745C11.9276 1.38233 9.8003 0.219727 7.39677 0.219727C3.34829 0.219727 0.0625 3.50551 0.0625 7.554C0.0625 8.27134 0.16969 8.96396 0.363457 9.61534C0.573714 10.5512 0.957124 11.4953 1.46009 12.4353H1.93832C2.34647 12.8888 2.81234 13.2887 3.32355 13.6308L2.1692 13.6391C5.66112 19.0728 12.5584 24.8281 12.9459 25.1002C13.0613 25.1785 13.1933 25.2197 13.3252 25.2197C13.4571 25.2197 13.5932 25.1785 13.7045 25.1002C14.0961 24.8239 21.1088 18.9903 24.5719 13.503H23.4134C23.8958 13.1526 24.3452 12.7527 24.7286 12.2992H25.2645C26.0107 10.8686 26.4725 9.4257 26.4725 8.04047C26.4725 7.94565 26.4642 7.85495 26.4642 7.76013C26.4642 7.69417 26.4725 7.62408 26.4725 7.55812V7.554Z" fill={fill ? fill : '#242628'}/>
        <path d="M25.2637 12.1394H17.2904C17.1131 11.3643 16.9317 10.5893 16.7545 9.8142C16.6844 9.50912 16.6514 9.15045 16.3958 8.93607C16.0165 8.61862 15.4352 8.74642 15.2208 9.18755C14.9034 9.83893 14.8003 10.6717 14.6107 11.3685C14.4128 12.0858 14.219 12.8031 14.0211 13.5205C13.8067 14.3121 13.5882 15.0995 13.3739 15.891C13.2131 15.1036 13.0564 14.3162 12.8956 13.5287C12.4174 11.1706 11.9392 8.81238 11.4609 6.45421C11.4568 6.42535 11.4444 6.40473 11.428 6.38824C11.127 5.65853 10.187 5.74922 9.9644 6.50368C9.78712 7.10559 9.6552 7.71987 9.49853 8.32591C9.16459 9.64517 8.82653 10.9603 8.4926 12.2796H1.45928C1.3356 12.2796 1.26552 12.4115 1.32324 12.5146C1.46341 12.7743 1.61182 13.034 1.76436 13.2896C1.83857 13.4133 1.94576 13.7019 2.08593 13.772C2.14365 13.805 2.19725 13.7844 2.25909 13.7926C2.56004 13.8256 2.88161 13.7926 3.18257 13.7885C4.30806 13.7802 5.43356 13.7761 6.55493 13.7679C7.41657 13.7637 8.31944 13.8338 9.17696 13.7514C9.85308 13.6895 9.90256 12.8732 10.0386 12.3414C10.2365 11.5705 10.4303 10.7954 10.6281 10.0245C10.8714 11.2242 11.1146 12.4239 11.3579 13.6236C11.7289 15.4499 12.1 17.2804 12.4669 19.1067C12.5452 19.486 12.6895 19.8488 13.1306 19.923C13.6171 20.0055 13.9139 19.6468 14.0294 19.2263C14.3015 18.2327 14.5736 17.2433 14.8415 16.2497C15.0848 15.3675 15.3239 14.4811 15.5671 13.5988C15.6537 13.2855 15.7362 12.9722 15.8227 12.663C15.9299 13.1536 16.1113 13.6112 16.6803 13.6607C17.2162 13.706 17.781 13.6607 18.3211 13.6607H24.2248C24.3402 13.6607 24.5299 13.6978 24.6412 13.6401C24.7855 13.5658 24.8844 13.2773 24.9586 13.1536C25.107 12.898 25.2513 12.6382 25.3915 12.3785C25.4451 12.2754 25.3791 12.1435 25.2555 12.1435L25.2637 12.1394Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_3795_40767">
          <rect width="26.41" height="25" fill="white" transform="translate(0.0625 0.219727)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default RapportIcon;