import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { NotificationsStoreProps } from '../utils/constants'
import { NotificationsStore } from '../utils/interfaces/stores'

export const useNotificationsStore = create<NotificationsStore>()(
  devtools((set) => ({
    notifications: [],
    hasUnreadNotifications: false,
    setNotifications: (data: NotificationsStoreProps[], hasUnread: boolean) =>
      set(() => ({
        notifications: data,
        hasUnreadNotifications: hasUnread
      })),
    resetStore: () =>
      set(() => ({
        notifications: [],
        hasUnreadNotifications: false,
      })),
  }))
)