import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { AlertState, AlertStoreState } from '../utils/interfaces/stores'

const initState: AlertState = {
  isOpen: false,
  message: '',
  severity: 'success',
}

export const useAlertStore = create<AlertStoreState>()(
  devtools((set) => ({
    ...initState,
    handleClose: () =>
      set(() => (initState)),
    setAlert: (alert: AlertState) => set(() => ({ ...alert })),
  }))
)
