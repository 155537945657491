import { useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { MoreOptions } from '../../components/MoreOptions';
import CustomModal from '../../components/customModal';
import { useNavigate } from 'react-router-dom';
import {
  SecretaryData,
  doctorStatuses as userStatus,
} from '../../utils/constants';
import ModalTick from '../../assets/modal-tick.png';
import { userActivation } from '../../services/user';

interface OptionsProps {
  label: string;
  action: () => void;
}

const AdminDashboardIconsSecretary = ({
  tabClicked,
  secretaryData,
  onOpenModal,
  getterFunction,
}: {
  tabClicked: string;
  secretaryData: SecretaryData;
  onOpenModal: Function;
  getterFunction: Function;
}) => {
  const navigate = useNavigate();
  const [modalStatusIsOpen, setStatusIsOpen] = useState<boolean>(false);

  const switchDoctorStatus = async (id: number) => {
    const response: any = await userActivation(id, !secretaryData.isActive);
    if (response.status === 200) {
      getterFunction();
    }
  };

  const notPendingOptionsData: OptionsProps[] = [
    {
      label:
        tabClicked === userStatus.ENABLED ? 'Ver perfil' : 'Aprobar usuario',
      action:
        tabClicked === userStatus.ENABLED
          ? () =>
              navigate(`/admin/secretary/${secretaryData.id}`, {
                replace: true,
                state: {
                  secretaryData,
                  tabClicked,
                },
              })
          : () => onOpenModal(userStatus.ENABLED, secretaryData.id),
    },
    {
      label: secretaryData.isActive
        ? 'Bloquear usuario'
        : 'Desbloquear usuario',
      action: () => setStatusIsOpen(true),
    },
  ];

  return (
    <div
      className={`flex flex-row w-full ${
        tabClicked === userStatus.APPROVAL_PENDING
          ? 'justify-between'
          : 'justify-end'
      }`}
    >
      {tabClicked === userStatus.APPROVAL_PENDING && (
        <>
          <div className='flex w-3/4 items-center'>
            <div>
              <button
                className='pr-1'
                onClick={() => {
                  onOpenModal(userStatus.ENABLED, secretaryData.id);
                }}
              >
                <DoneIcon
                  color='secondary'
                  className='bg-green-light rounded-full p-1 w-3 h-3'
                />
              </button>
              <button
                className='pl-1'
                onClick={() => {
                  onOpenModal(userStatus.REJECTED, secretaryData.id);
                }}
              >
                <CloseIcon
                  color='secondary'
                  className='bg-red-error rounded-full p-1 w-3 h-3'
                />
              </button>
            </div>
          </div>
          <div
            onClick={() =>
              navigate(`/admin/secretary/${secretaryData.id}`, {
                replace: true,
                state: {
                  secretaryData,
                  tabClicked,
                },
              })
            }
            className='underline text-aqua-intense font-bold pr-4 cursor-pointer'
          >
            Ver perfil
          </div>
        </>
      )}
      {tabClicked !== userStatus.APPROVAL_PENDING && (
        <MoreOptions optionsData={notPendingOptionsData} />
      )}
      <CustomModal
        openModal={modalStatusIsOpen}
        handleClose={() => setStatusIsOpen(false)}
        imgSrc={ModalTick}
        title={`¿Estás seguro de ${
          secretaryData.isActive ? 'bloquear' : 'desbloquear'
        } este perfil?`}
        description={
          secretaryData.isActive
            ? ''
            : 'Luego de esto, el usuario volverá a tener acceso al sistema.'
        }
        onSubmit={() => {
          switchDoctorStatus(secretaryData.userId);
          setStatusIsOpen(false);
        }}
        submitLabel='Aceptar'
        showCancelButton
        child={null}
        isSubmitDisable={null}
      />
    </div>
  );
};

export default AdminDashboardIconsSecretary;
