import { Typography } from '@mui/material';
import pendingApprovalImg from '../../../assets/pending_approval-img.png';
import { useEffect, useState } from 'react';
import { getDoctorsLinked } from '../../../services/secretary';
import { Doctor } from '../../../utils/constants';
import UserAvatar from '../../../components/UserAvatar';
import { authStore } from '../../../store/auth';
import { useStore } from 'zustand';

const SecretaryWelcome = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const { setDoctor } = useStore(authStore);

  useEffect(() => {
    const getData = async () => {
      const response = await getDoctorsLinked();

      setDoctors(response.items);
    };

    getData();
  }, []);

  return (
    <div className='bg-silver h-full w-full pb-6'>
      <Typography
        className={`pt-10 pb-4 pl-12 text-pitch-black tracking-wider`}
        fontSize={28}
        fontWeight='bold'
      >
        ¡Bienvenido Asistente!
      </Typography>
      {doctors.length === 0 && (
        <>
          <div className='full-width flex justify-center items-center pt-16 pb-16'>
            <img src={pendingApprovalImg} alt='Approval pending'></img>
          </div>
          <p className='font-montserrat text-2xl font-bold text-light-grey text-center'>
            Sin resultados
          </p>
          <p className='font-montserrat font-normal text-base text-center text-light-grey'>
            No tienes nada para ver hasta el momento...
          </p>
        </>
      )}
      {doctors.length > 0 && (
        <>
          <Typography
            className={`py-4 pl-12 text-pitch-black tracking-wider`}
            fontSize={22}
            fontWeight='bold'
          >
            Profesionales vinculados
          </Typography>
          <p className='font-montserrat font-normal text-base pl-12 text-light-grey'>
            Selecciona el profesional que deseas impersonificar
          </p>
        </>
      )}
      <div className='flex flex-wrap gap-4 justify-center pt-12 px-4'>
        {doctors.map((doctor) => (
          <div
            className='flex w-[450px] items-center rounded p-4 mb-2 shadow cursor-pointer bg-white'
            key={doctor.id}
            onClick={() => setDoctor(doctor)}
          >
            <div className='flex'>
              <UserAvatar
                imageUrl={doctor.profilePictureUrl}
                altText={`Profesional ${doctor.firstName} ${doctor.lastName}`}
                size={80}
              />
            </div>
            <div className='pl-8'>
              <Typography fontWeight={700}>
                {doctor.firstName} {doctor.lastName}
              </Typography>
              <Typography>
                <span className='font-bold text-sm'>Matricula:</span>{' '}
                {doctor.licence}
              </Typography>
              <Typography fontSize={12} className='pt-2'>
                {doctor.email}
              </Typography>
              <Typography fontSize={12}>{doctor.phone}</Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecretaryWelcome;
