import { AxiosResponse } from 'axios';
import { DoctorProfileData } from '../utils/constants';
import { http } from '../utils/http';

export const getSecretaries = async (
  status: string,
  linked: boolean | undefined = undefined,
) => {
  try {
    const response = await http.get(`/api/secretary`, {
      params: { status, ...(linked !== undefined && { linked }) },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const enableSecretary = async (id: number) => {
  try {
    const response = await http.patch(`/api/secretary/${id}/enable`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const rejectSecretary = async (id: number, message: string) => {
  try {
    const response = await http.patch(`/api/secretary/${id}/reject`, {
      message,
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSecretaryProfile = async (secretaryId: number) => {
  try {
    const response = await http.get(`/api/secretary/${secretaryId}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const linkSecretary = async (id: number) => {
  try {
    const response = await http.post(`/api/secretary/${id}/link`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const unlinkSecretary = async (id: number) => {
  try {
    const response = await http.delete(`/api/secretary/${id}/unlink`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateSecretaryProfile = async (
  data: DoctorProfileData,
): Promise<AxiosResponse | any> => {
  try {
    const response = await http.put('/api/secretary', {
      ...data,
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const getDoctorsLinked = async () => {
  try {
    const response = await http.get(`/api/secretary/doctor`);
    return response.data;
  } catch (error) {
    return error;
  }
};
