import CustomModal from '../../../components/customModal'
import ModalCross from '../../../assets/modal-cross.png'
import { useAlertStore } from '../../../store/alertStore'
import { deleteNote } from '../../../services/rapports'
import { useRapportsStore } from '../../../store/rapportsStore'

const DeleteNoteModal = ({
  openModal,
  setOpenModal,
  toDeleteNote,
  setToDeleteNote,
  rapportId
}: {
  openModal: boolean
  setOpenModal: (open: boolean) => void
  toDeleteNote: number
  setToDeleteNote: (id: number) => void
  rapportId: number
}) => {
  const { setAlert } = useAlertStore()
  const { setNotes } = useRapportsStore()

  const submit = async () => {
    const response = await deleteNote(rapportId, toDeleteNote)
    if (response.status === 200) {
      setAlert({
        isOpen: true,
        message: 'Se eliminó exitosamente la nota',
        severity: 'success'
      })
      setNotes(response.data)
      setOpenModal(false)
    } else {
      setAlert({
        isOpen: true,
        message: response.data.message,
        severity: 'error'
      })
      setOpenModal(false)
    }
  }

  return (
    <CustomModal
      openModal={openModal}
      title="¿Estás seguro de eliminar la nota?"
      description="Una vez eliminada no vas a poder recuperarla"
      submitLabel="Aceptar"
      onSubmit={() => submit()}
      showCancelButton
      handleClose={() => {
        setOpenModal(false)
        setToDeleteNote(0)
      }}
      imgSrc={ModalCross}
      child={null}
      isSubmitDisable={null}
    />
  )
}

export default DeleteNoteModal
