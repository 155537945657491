import { http } from '../utils/http'

export const getSpecialties = async () => {
  try {
    const response = await http.get('/api/specialty')
    return response.data
  } catch (error) {
    return error
  }
}

export const getHealthCoverage = async () => {
  try {
    const response = await http.get('/api/health-coverage')
    return response.data
  } catch (error) {
    return error
  }
}
export const getProvinces = async () => {
  try {
    const response = await http.get('/api/province')
    return response.data
  } catch (error) {
    return error
  }
}

export const getCities = async (provinceId: any) => {
  try {
    const response = await http.get(`/api/city/${provinceId}`)
    return response.data
  } catch (error) {
    return error
  }
}