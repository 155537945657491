import React, { useEffect, useState } from 'react'
import { Layout } from '../../components/Layout'
import { GridColDef } from '@mui/x-data-grid'
import UserAvatar from '../../components/UserAvatar'
import {
  getWebDoctors
} from '../../services/doctor'
import { Typography } from '@mui/material'
import { doctorStatuses } from '../../utils/constants'
import nothingToShowImg from '../../assets/datagrid-nothing-to-show.png'
import NothingToShow from '../../components/nothingToShow'
import CustomSearchBox from '../../components/CustomSearchBox'
import CustomDataGrid from '../../components/CustomDataGrid'
import { useLocation, useNavigate } from 'react-router-dom'

const ReportsAdmin = () => {
  const [rows, setRows] = useState<[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  const [searchText, setSearchText] = useState<string>('')

  const webDoctorGetter = async () => {
    const response = await getWebDoctors(doctorStatuses.ENABLED)
    if (response) {
      setRows(response.items)
    }
  }

  useEffect(() => {
    webDoctorGetter()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Profesionales',
      editable: false,
      renderCell: (cellValues): any => {
        return (
          <div className="flex flex-row items-center">
            <UserAvatar
              imageUrl={cellValues.row.profilePictureUrl}
              size={44}
              altText={'IMG'}
            />
            <div className="pl-8">{cellValues.row.firstName} {cellValues.row.lastName}</div>
          </div>
        )
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      sortable: false
    },
    {
      field: 'empty',
      headerName: '',
      renderCell: (cellValues): any => {
        return (
          <div className='flex w-full text-end justify-end pr-6' onClick={() => navigate(`/reports/${cellValues.row.id}`, { replace: true, state: {
            medicalState: cellValues.row,
            pathToGoBack: location.pathname,
          } })}>
            <Typography
              className='tracking-wider text-center text-sm text-aqua-intense underline cursor-pointer'
              fontWeight='bold'
            >
              Ver actividad
            </Typography>
          </div>
        )
      },
      flex: 1,
      sortable: false
    }
  ]

  const filterRow = (row: any) =>
    row.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
    row.email.toLowerCase().includes(searchText.toLowerCase())

  return (
    <Layout>
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full items-center">
          <div className="flex w-1/5 justify-start pl-2 pr-2">
            <Typography
              className={`p-10 pl-12 text-pitch-black tracking-wider`}
              fontSize={28}
              fontWeight="bold"
            >
              Reportes
            </Typography>
          </div>
          <div className="flex w-4/5 justify-end pl-2 pr-2">
            <CustomSearchBox
              onChange={(value) => setSearchText(value)}
              value={searchText}
            />
          </div>
        </div>
        <div
          className={`flex mx-14 bg-white ${
            rows.length !== 0 ? 'h-[500px]' : 'pb-12'
          }`}
        >
          {(searchText.length && rows.filter(filterRow).length === 0) ||
          rows.length === 0 ? (
            <NothingToShow
              nothingImg={nothingToShowImg}
              altText="Listado de reportes médicos"
              nothingText={'No tenés nada que ver hasta el momento...'}
            />
          ) : 
            <CustomDataGrid
              rows={searchText.length ? rows.filter(filterRow) : rows}
              rowHeight={64}
              columns={columns}
            />
          }
        </div>
      </div>
    </Layout>
  )
}

export default ReportsAdmin
